import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  order_id: any;
  iframe: any;

  constructor(
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private menuPro: MenuProService) {
    let or_id = localStorage.getItem('order_id');
    this.getIframe(or_id);
  }

  ngOnInit(): void {
  }

  getIframe(order_id) {
    this.spinner.show();
    this.menuPro.getIframe(order_id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        let ifr = this.sanitizer.bypassSecurityTrustResourceUrl(res.iframe);
        this.iframe = ifr;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
