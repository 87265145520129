import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-landing',
  templateUrl: 'landing-screen.component.html',
  styleUrls: ['landing-screen.component.scss'],
})
export class LandingScreenComponent implements OnInit {
  orderMode = '';

  constructor() {}

  ngOnInit(): void {}
}
