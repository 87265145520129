import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FoodCourtProService } from '../../dashboard/foodcourt/food-court-pro.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  menuId: string = localStorage.getItem('t_menu_id') || '';
  menu: any;

  constructor(
    private route: ActivatedRoute,
    private foodcourtPro: FoodCourtProService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.menuId = params.id ? params.id : localStorage.getItem('t_menu_id');
      this.getMenu(this.menuId);
    });
  }

  getMenu(id) {
    this.foodcourtPro.getUserDigitalMenu(id).subscribe(
      (res: any) => {
        this.menu = res.fc_menu;
        this.menuId = res.fc_menu.id;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
