import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-fc-order-items',
  templateUrl: './fc-order-items.component.html',
  styleUrls: ['./fc-order-items.component.scss']
})
export class FcOrderItemsComponent implements OnInit {

  id: any;
  order: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.id = params.or_id;
      if (this.id) {
        this.getRestaurantOrderItems(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurantOrderItems(id) {
    this.spinner.show();
    this.foodcourtPro.getRestaurantOrderItems(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.order = res.Order[0];
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
