import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-food-court-restaurant-admin',
  templateUrl: './add-food-court-restaurant-admin.component.html',
  styleUrls: ['./add-food-court-restaurant-admin.component.scss']
})
export class AddFoodCourtRestaurantAdminComponent implements OnInit {

  id: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.id = params.id;
    })
  }

  ngOnInit(): void {
  }

  addFoodCourtRestaurantAdmin(data) {
    data.fc_id = this.id;
    this.spinner.show();
    this.foodcourtPro.addFoodCourtRestaurantAdmin(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('FoodCourt restaurant admin added successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

}
