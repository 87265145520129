import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient) { }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }

  public isAdmin(): boolean {
    const role = this.getRole();
    if (role) {
      return true;
    }
    return false;
  }

  getToken() {
    return localStorage.getItem('touchless');
  }

  login(userCredentials): Observable<any> {
    let url = environment.url;
    return this.http.post<any>(`${url}api/auth/login/`, userCredentials )
      .pipe(
        map(res => {
            return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          return throwError(err);
        })
      );
    }

  getRole() {
    return localStorage.getItem('touchless_role');
  }

  addUser(user) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/user`, user);
    return res;
  }

  verify(token) {
    let url = environment.url;
    let res = this.http.post(`${url}api/verifytoken`, token);
    return res;
  }

  changePassword(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/changepassword`, data);
    return res;
  }

  getUserDetails() {
    let url = environment.url;
    let res = this.http.get(`${url}api/users/profile`);
    return res;
  }

  changeUserPassword(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/user/changepassword`, data);
    return res;
  }

  resendEmail(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/resend_email`, data);
    return res;
  }

  adminChangeUserPassword(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/change_user_password`, data);
    return res;
  }

  getUsers() {
    let url = environment.url;
    let res = this.http.post(`${url}api/get/users`, []);
    return res;
  }

  suspendUser(data, value) {
    let url = environment.url;
    let res = this.http.post(`${url}api/suspend/user`, {'user_id': data, 'suspend': value});
    return res;
  }

}
