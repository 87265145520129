<div class="cumulative">
  <div class="top" id="top" [style.background-image]="'url(' + background_path + ')'">

    <div class="buttons text-center">
      <!-- <h6 *ngIf="menu.text_color == 'black'" style="color:black;">{{ qrcodes_title || 'MENUS' }}</h6>
      <h6 *ngIf="menu.text_color == 'white'" style="color:white;">{{ qrcodes_title || 'MENUS' }}</h6> -->
      <img src="assets/imgs/sol/SOL-Beach-logo-White.png" style="width: 250px; height: 250px;"/>
      <div *ngFor="let b of qrButtons">
        <a href="{{ b.value }}?back=true">{{ b.key }}</a>
      </div>
    </div>
    <div class="social text-center">
      <div class="s">
        <a href="{{ getLink(survey) }}" *ngIf="survey">CLICK HERE AND GIVE US YOUR OPINION</a>
      </div>
      <div class="icons">
        <a href="{{ getLink(instagram) }}" target="_blank" *ngIf="instagram"><img src="assets/imgs/inst1.jpg" /></a>
        <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook"><img src="assets/imgs/face1.jpg" /></a>
        <a href="{{ getLink(website) }}" target="_blank" *ngIf="website"><img src="assets/imgs/site1.jpg" /></a>
      </div>
    </div>
    <div  style="position:absolute; bottom:0; right: 0;">
      <img *ngIf="menu.text_color == 'black'" src="assets/imgs/icons/logoblack.svg" width="75" height="auto"
        class="l" />
      <img *ngIf="menu.text_color == 'white'" src="assets/imgs/icons/logowhite.svg" width="75" height="auto"
        class="l" />

      <div class="cls"></div>
    </div>

  </div>
</div>