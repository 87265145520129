import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-menu',
  templateUrl: './order-menu.component.html',
  styleUrls: ['./order-menu.component.scss']
})
export class OrderMenuComponent implements OnInit {

  id: any;
  menu: any;
  offers: any;
  section: any = 'section0';

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private menuPro: MenuProService) {
  }

  ngOnInit(): void {
    this.id = localStorage.getItem('t_menu_id');
    if (this.id) {
      this.getMenu(this.id);
    } else {
      this.router.navigate(['/']);
    }
  }

  getMenu(id) {
    this.spinner.show();
    this.menuPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.menu = res.Menu.digital_menu_sections;
        this.offers = res.Menu.offers;
        if (this.offers && this.offers.length != 0) {
          this.section = 'sectionO';
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  showSection(val) {
    this.section = val;
  }

  itemDetailsPage(available, id) {
    if (available) {
      this.router.navigate(['/mori/menu/item', id]);
    } else {
      this.toastr.error('This item is not available now!', '', {
        timeOut: 3000
      })
    }
  }

}
