<app-navbar [page]="'ANALYTICS'"></app-navbar>
<div class="scans">
    <ng-container *ngIf="scans">
        <div class="container-fluid">
            <div class="text-right">
                <button class="btn btn-danger" (click)="clearQrIDs()">Clear</button>
            </div>
            <div class="in">
                <div class="row">
                    <div class="col-md-6">
                        <h4>Compare Qr Codes</h4>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-6">
                                <label>Start Date</label>
                                <input type="date" class="form-control" [(ngModel)]="from_date"
                                    (change)="getScans(from_date, to_date)">
                            </div>
                            <div class="col-6">
                                <label>End Date</label>
                                <input type="date" class="form-control" [(ngModel)]="to_date"
                                    (change)="getScans(from_date, to_date)">
                            </div>
                        </div>
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Enter a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="Start date" [(ngModel)]="from_date">
                                <input matEndDate placeholder="End date" [(ngModel)]="to_date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field> -->
                        <!-- <h6>Number Of Days</h6>
                        <div class="s">
                            <form #dForm="ngForm" (ngSubmit)="getScans(id,days)">
                                <button (click)="getScans(id,days)"><img src="assets/imgs/search.svg" width="20"></button>
                                <input type="text" placeholder="Number of Days" [(ngModel)]="days" name="days">
                            </form>
                        </div> -->
                    </div>
                </div>
            </div>


            <div class="unique_scans">

                <div class="ch">
                    <!-- <h6>Unique scans</h6> -->
                    <canvas *ngIf="ubarChartData.length != 0" baseChart width="800" height="300"
                        [datasets]="ubarChartData" [labels]="ubarChartLabels" [options]="ubarChartOptions"
                        [colors]="ubarChartColors" [legend]="ubarChartLegend" [chartType]="ubarChartType"
                        [plugins]="ubarChartPlugins">
                    </canvas>
                    <!-- <div class="text-right">
                        <span (click)="onDown(scans?.excel_path)"><img src="assets/imgs/download.svg" width="10">XLS</span>
                    </div> -->
                </div>
            </div>

            <div class="devices">
                <div class="ch">
                    <!-- <h6>Devices</h6> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <canvas *ngIf="pieChartData?.length != 0" height="300" [labels]="pieChartLabels"
                                [legend]="pieChartLegend" baseChart [data]="pieChartData"
                                [chartType]="pieChartType"></canvas>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="!scans">
        <div class="text-center">
            <h1>No Qr Codes Found!</h1>
            <p>Please add Qr codes to compare</p>
            <button class="btn btn-primary" routerLink="/base/qrcodes">Qr Codes</button>
        </div>
    </ng-container>
</div>