import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { RestaurantService } from '../restaurant.service';

@Component({
  selector: 'app-add-restaurant-admin',
  templateUrl: './add-restaurant-admin.component.html',
  styleUrls: ['./add-restaurant-admin.component.scss'],
})
export class AddRestaurantAdminComponent implements OnInit {
  restaurantAdminForm: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private restaurantService: RestaurantService
  ) {}

  ngOnInit(): void {
    this.restaurantAdminForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  addRestaurantAdmin() {
    this.restaurantService
      .createRestaurantAdmin(this.restaurantAdminForm.value)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.toastr.success('FoodCourt admin added successfully!', '', {
            timeOut: 3000,
          });
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.message, '', {
            timeOut: 3000,
          });
          console.log('gowa error');
          console.log(error);
        }
      );
  }
}
