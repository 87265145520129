import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-fc-restaurants',
  templateUrl: './fc-restaurants.component.html',
  styleUrls: ['./fc-restaurants.component.scss']
})
export class FcRestaurantsComponent implements OnInit {

  restaurants: any;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) { }

  ngOnInit(): void {
    this.getRestaurants();
  }

  getRestaurants() {
    this.spinner.show();
    this.foodcourtPro.getFoodCourtRestaurants()
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.restaurants = res;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  deleteRestaurant(id) {
    this.spinner.show();
    this.foodcourtPro.deleteFoodCourtRestaurant(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.getRestaurants();
        this.toastr.success(res.Message, '', {
          timeOut: 3000
        })
      }, err => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000
        })
      })
  }

}
