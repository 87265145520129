import { Component, OnInit, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodesProService } from '../qr-codes-pro.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rate-popup',
  templateUrl: './rate-popup.component.html',
  styleUrls: ['./rate-popup.component.scss']
})
export class RatePopupComponent implements OnInit {

  rating: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<any>,
    private qrCodesPro: QrCodesProService) { }

  ngOnInit(): void {
    this.getRating();
  }

  getRating() {
    this.qrCodesPro.getRating(this.data.id)
      .subscribe((res: any) => {
        console.log(res);
        this.rating = res.qrcode_ratings;
      }, err => {
        console.log(err);
      })
  }

  getAnswers(val) {
    let d_id = JSON.parse(localStorage.getItem('device_id'));
    let d = { rating: val, qrcode_id: this.data.id, device_id: d_id };
    this.qrCodesPro.addRating(d)
      .subscribe(res => {
        console.log(res);
        this.toastr.success('Thanks for your rating :)', '', {
          timeOut: 3000
        });
        this.close();
      }, err => {
        console.log(err);
      })
  }

  close() {
    this.dialogRef.close();
  }

}
