<app-navbar [page]="'ADD FOOD COURT ADMIN'"></app-navbar>

<div class="rests">
    <div class="container">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="rest">
                    <form #resForm="ngForm" (ngSubmit)="addFoodCourtRestaurantAdmin(resForm.value)">
                        <div class="txt">
                            <label>Name</label>
                            <input type="text" class="txt_in" ngModel name="name" required>
                        </div>
                        <div class="txt">
                            <label>Email</label>
                            <input type="email" class="txt_in" ngModel name="email" required>
                        </div>
                        <div class="txt">
                            <label>Password</label>
                            <input type="password" class="txt_in" ngModel name="password" required>
                        </div>
                        <button class="btn btn-primary" [disabled]="!resForm.valid">Add Food Court Admin</button>
                    </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</div>