import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AuthGuardService } from '../auth-guard.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';

import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OrderHomeComponent } from './order-home/order-home.component';
import { OrderingComponent } from './ordering/ordering.component';
import { OrderNotificationsComponent } from './order-notifications/order-notifications.component';
import { OrderMenuComponent } from './order-menu/order-menu.component';
import { OrderOrderComponent } from './order-order/order-order.component';
import { OrderConfirmedComponent } from './order-confirmed/order-confirmed.component';
import { OrderCheckComponent } from './order-check/order-check.component';
import { OrderCurrentComponent } from './order-current/order-current.component';
import { OrderMenuItemComponent } from './order-menu-item/order-menu-item.component';
import { OrderGenerateCodeComponent } from './order-generate-code/order-generate-code.component';
import { PaymentComponent } from './payment/payment.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { WaiterTipsPopupComponent } from './waiter-tips-popup/waiter-tips-popup.component';

@NgModule({
  declarations: [
    OrderHomeComponent,
    OrderingComponent,
    OrderNotificationsComponent,
    OrderMenuComponent,
    OrderOrderComponent,
    OrderConfirmedComponent,
    OrderCheckComponent,
    OrderCurrentComponent,
    OrderMenuItemComponent,
    OrderGenerateCodeComponent,
    PaymentComponent,
    ThankyouComponent,
    WaiterTipsPopupComponent,
  ],
  entryComponents: [WaiterTipsPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    PdfViewerModule,
    InfiniteScrollModule,
    GooglePlaceModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatInputModule,
    RouterModule.forChild([
      {
        path: '',
        component: OrderingComponent,
        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home/:id', component: OrderHomeComponent },
          { path: 'notifications', component: OrderNotificationsComponent },
          { path: 'menu', component: OrderMenuComponent },
          { path: 'menu/item/:id', component: OrderMenuItemComponent },
          { path: 'order', component: OrderOrderComponent },
          { path: 'confirmed', component: OrderConfirmedComponent },
          // { path: 'check', component: OrderCheckComponent },
          // { path: 'current', component: OrderCurrentComponent },
          // { path: 'generate-code', component: OrderGenerateCodeComponent },
          { path: 'payment/status', component: ThankyouComponent },
          { path: 'payment/:id', component: PaymentComponent },
        ],
      },
    ]),
  ],
  exports: [],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  ],
})
export class MoriOrderingModule {}
