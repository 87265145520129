import { Component, OnInit } from '@angular/core';
import { MenuProService } from '../menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-qr-analytics',
  templateUrl: './qr-analytics.component.html',
  styleUrls: ['./qr-analytics.component.scss']
})
export class QrAnalyticsComponent implements OnInit {

  days: any = 2;
  to_date: any = new Date().toISOString().split('T')[0];
  from_date: any = new Date().toISOString().split('T')[0];
  scans: any;
  scan_counts: any;
  scan_days: any;
  uscan_counts: any;
  uscan_days: any;
  scan_devices: any;
  scan_devices_counts: any;
  id: any;

  barChartData: ChartDataSets[] = [
    { data: [], label: 'Scans' },
  ];
  barChartLabels: Label[] = [];
  barChartOptions: (any & { annotation: any }) = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  barChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0, 141, 213, 0.7)',
    },
  ];
  barChartLegend = true;
  barChartType = 'bar';
  barChartPlugins = [];

  ubarChartData: ChartDataSets[] = [
    { data: [], label: 'Unique Scans' },
  ];
  ubarChartLabels: Label[] = [];
  ubarChartOptions: (any & { annotation: any }) = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  ubarChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0, 141, 213, 0.7)',
    },
  ];
  ubarChartLegend = true;
  ubarChartType = 'bar';
  ubarChartPlugins = [];

  // Pie Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabels: Array<any> = [];
  public pieChartData: any[] = [];

  public tpieChartData: any[] = [];
  public tpieChartLabels: Array<any> = [];
  // public lineChartColors: Array<any> = [{ backgroundColor: [] }];
  // public pieChartLabels = ['10%', '20%', '30%', '40%','50%'];
  public pieChartLegend = true;
  // public pieChartData = [30, 90, 120, 60, 40];
  public pieChartType = "pie";

  constructor(
    private menuPro: MenuProService,
    private route: ActivatedRoute,
    private _FileSaverService: FileSaverService,
    private _http: HttpClient,
    private spinner: NgxSpinnerService) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.getScans(this.id, this.from_date, this.to_date);
      }
    })
  }

  ngOnInit(): void {
  }

  getScans(id, from_date, to_date) {
    this.spinner.show();
    this.menuPro.getQrAnalytics(id, from_date, to_date)
      .subscribe((res: any) => {
        console.log(res);
        this.scans = res;
        this.scan_counts = res.scan_counts;
        this.scan_days = res.scan_days;
        this.barChartData[0].data = this.scan_counts;
        this.barChartLabels = this.scan_days;

        this.uscan_counts = res.scan_counts_unique;
        this.uscan_days = res.scan_days_unique;
        this.ubarChartData[0].data = this.uscan_counts;
        this.ubarChartLabels = this.uscan_days;

        this.scan_devices = res.scan_devices;
        this.scan_devices_counts = res.scan_devices_counts;
        this.pieChartData = this.scan_devices_counts;
        this.pieChartLabels = this.scan_devices;

        this.tpieChartData = res.customer_data;
        this.tpieChartLabels = res.customer_types;
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onDown(file) {
    // const fileName = `save.${type}`;
    this._http.get(`${file}`, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this._FileSaverService.save(res.body);
    });

  }

}
