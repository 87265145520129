<app-navbar [page]="'FOOD COURTS'"></app-navbar>

<div class="rests">
    <div class="container">
        <div class="text-right mb-3">
            <button class="btn btn-primary" routerLink="/base/foodcourts/create">Add Food Court</button>
        </div>

        <div class="items">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let r of foodcourts">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h5 class="card-title">{{r.name}}</h5>
                            <p class="card-text">{{r.location}}</p>
                            <div class="text-center">
                                <div class="row">
                                    <div class="col-6">
                                        <button class="btn btn-info"
                                            routerLink="/base/foodcourts/restaurants/admin/create/{{r.id}}">Admin</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-primary"
                                            routerLink="/base/foodcourts/edit/{{r.id}}">Edit</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-danger" (click)="deleteFoodCourt(r.id)">Delete</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>