import { Component, OnInit, ViewChild } from '@angular/core';
import { QrCodesProService } from '../qr-codes-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ChartPopupComponent } from '../chart-popup/chart-popup.component';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-survey-analrtics',
  templateUrl: './survey-analrtics.component.html',
  styleUrls: ['./survey-analrtics.component.scss']
})
export class SurveyAnalrticsComponent implements OnInit {

  id: any;
  type: any;
  qrDetails: any;
  excel_path: any;
  questions: any[] = [];
  pageIndex = 1;
  currentQRCode;
  answers: any[];
  next: any;
  resTotal;

  displayedColumns: string[] = ['position', 'user_email', 'user_phone', 'answer', 'updated_at'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private qrCodesPro: QrCodesProService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _FileSaverService: FileSaverService,
    private _http: HttpClient,
    public dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.getQrCodeDetails(this.id);
        this.getSurveySheet(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getQrCodeDetails(id) {
    this.spinner.show();
    this.qrCodesPro.getQrCodeDetails(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.qrDetails = res.qrcode;
        this.type = this.qrDetails.type;
        if (this.type == 'survey') {
          this.questions = this.qrDetails.survey_attributes;
          this.questions.forEach(element => {
            if (element.type == 'radio') {
              element.answers = element.answers.split("@,");
            }
          });
          console.log(this.questions);
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  getAnswers(survey_attribute_id) {
    this.currentQRCode = survey_attribute_id;
    let data = { qrcode_id: this.id, survey_attribute_id: survey_attribute_id };
    this.spinner.show();
    this.qrCodesPro.getSurveyAnswers(data, this.pageIndex)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.answers = res.results.data;
        this.resTotal = res.results.total;
        this.dataSource = new MatTableDataSource<any>(this.answers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  getNextData(currentSize) {
    let data = { qrcode_id: this.id, survey_attribute_id: this.currentQRCode };
    this.spinner.show();
    this.qrCodesPro.getSurveyAnswers(data, this.pageIndex)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.pageIndex = res.results.current_page;
        this.answers.length = currentSize;
        this.answers.push(...res.results.data);
        this.answers.length = res.results.total;
        this.dataSource = new MatTableDataSource<any>(this.answers);
        this.dataSource._updateChangeSubscription();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  pageChanged(event){
    this.spinner.show();
    this.pageIndex = event.pageIndex + 1;
    const previousSize = event.pageIndex * 20;


    this.getNextData(previousSize);
  }

  getSurveySheet(id) {
    this.qrCodesPro.getSurveySheet(id)
      .subscribe((res: any) => {
        console.log(res);
        this.excel_path = res.excel_path;
      }, err => {
        console.log(err);
      })
  }

  getAnswersChart(id) {
    let data = { question_ids: [id], qrcode_id: this.id };
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(ChartPopupComponent, {
        width: '400px',
        // height: '80vh',
        panelClass: 'sliP',
        data: data
      });
    } else {
      const dialogRef = this.dialog.open(ChartPopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: data
      });
    }
  }

  onScroll(event) {
    if (this.next) {
      this.getNextPage(this.next).subscribe((response: any) => {
        this.answers = this.answers.concat(response.results.data);
        this.next = response.results.next_page_url;
      })
    }
  }

  getNextPage(url) {
    return this.qrCodesPro.getNextPage(url);
  }


  onDown() {
    // const fileName = `save.${type}`;
    this._http.get(`${this.excel_path}`, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      const blob = new Blob([res.body], { type: 'application/vnd.ms-excel;charset=utf-8' });
      this._FileSaverService.save(blob, "Report.xls");
    });

  }

}
