<app-navbar [page]="'ADD RESTAURANT'"></app-navbar>

<div class="rests">
    <div class="container">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="rest">
                    <form #resForm="ngForm" (ngSubmit)="addRestaurantData(resForm.value)">
                        <div class="txt">
                            <label>Name</label>
                            <input type="text" class="txt_in" [(ngModel)]="name" name="name" required>
                        </div>
                        <div class="txt">
                            <label>Description</label>
                            <input type="text" class="txt_in" [(ngModel)]="description" name="description" required>
                        </div>
                        <div class="txt">
                            <label>Location</label>
                            <input type="text" class="txt_in" [(ngModel)]="location" name="location" required>
                        </div>
                        <div class="txt">
                            <label>Api Key</label>
                            <input type="number" class="txt_in" [(ngModel)]="api_key" name="api_key">
                        </div>
                        <div class="txt">
                            <label>Merchant Id</label>
                            <input type="number" class="txt_in" [(ngModel)]="merchant_id" name="merchant_id">
                        </div>
                        <div class="txt">
                            <label>Integration Id</label>
                            <input type="number" class="txt_in" [(ngModel)]="integration_id" name="integration_id">
                        </div>
                        <button class="btn btn-primary" [disabled]="!resForm.valid">Add Restaurant</button>
                    </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</div>