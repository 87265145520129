<div class="home">
    <div class="head">
        <h1>Order</h1>
    </div>
    <div class="body">
        <h2>Check your order</h2>
        <div class="item">
            <h6>Enter the code here</h6>
            <input type="text" placeholder="Code">
            <button routerLink="/order/current">Next</button>
        </div>
    </div>
</div>