import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.scss'],
})
export class AllOrdersComponent implements OnInit, OnDestroy {
  // id: any;
  orders: any;
  next: any;
  s_int: any;
  @ViewChild('audioplayer', { read: ElementRef, static: false })
  audioplayer: ElementRef;
  tables: any;
  type = 'dine_in';
  tabIndex = JSON.parse(localStorage.getItem('tab_index')) || 0;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private menuPro: MenuProService
  ) {

  }

  ngOnInit(): void {
    this.tabIndex = JSON.parse(localStorage.getItem('tab_index'));
    switch (this.tabIndex) {
      case 1:
        this.type = 'pick_up';
        break;
      case 2:
        this.type = 'delivery';
        break;
      default:
        this.type = 'dine_in';
    }
    this.getAllRestaurantOrders(this.type);
  }

  ngOnDestroy() {
    clearInterval(this.s_int);
  }

  timeConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(''); // return adjusted time or original string
  }

  handleTabChange({ index }): void {
    switch (index) {
      case 1:
        this.type = 'pick_up';
        localStorage.setItem('tab_index', JSON.stringify(1));
        this.getAllRestaurantOrders('pick_up');
        break;
      case 2:
        this.type = 'delivery';
        localStorage.setItem('tab_index', JSON.stringify(2));
        this.getAllRestaurantOrders('delivery');
        break;
      default:
        this.type = 'dine_in';
        localStorage.setItem('tab_index', JSON.stringify(0));
        this.getAllRestaurantOrders('dine_in');
    }
  }
  getAllRestaurantOrders(type) {
    this.spinner.show();
    this.menuPro.getAllRestaurantOrders(type).subscribe(
      (res: any) => {
        console.log('====>', { res });
        this.spinner.hide();
        this.orders = res.map((order) => {
          if (type === 'pick_up') {
            order.pickup_time = this.timeConvert(order.pickup_time);
          }
          return order;
        });
        this.next = res.next_page_url;
        for (let index = 0; index < this.orders.length; index++) {
          const element = this.orders[index];
          if (element.call_waiter) {
            this.play();
            break;
          }
          if (element.ask_for_check) {
            this.play();
            break;
          }
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  onScroll(event) {
    if (this.next) {
      this.getNextPage(this.next).subscribe((response: any) => {
        this.orders = this.orders.concat(response.data);
        this.next = response.next_page_url;
      });
    }
  }

  getNextPage(url) {
    return this.menuPro.getNextPage(url);
  }

  cancelOrder(id) {
    this.spinner.show();
    this.menuPro.deleteOrder(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Order Canceled!', '', {
          timeOut: 3000,
        });
        this.getAllRestaurantOrders(this.type);
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  callWaiterOff(or_ind, id) {
    if (this.orders[or_ind].call_waiter) {
      this.spinner.show();
      this.menuPro.callWaiterOff(id).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide();
          this.orders[or_ind].call_waiter = 0;
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
          if (err.error.Message) {
            this.toastr.error(err.error.Message, '', {
              timeOut: 3000,
            });
          } else if (err.error.message) {
            this.toastr.error(err.error.message, '', {
              timeOut: 3000,
            });
          } else {
            this.toastr.error('Something went wrong!', '', {
              timeOut: 3000,
            });
          }
        }
      );
    }
  }

  requestReceiptOff(or_ind, id) {
    if (this.orders[or_ind].ask_for_check) {
      this.spinner.show();
      this.menuPro.requestReceiptOff(id).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide();
          this.orders[or_ind].ask_for_check = 0;
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
          if (err.error.Message) {
            this.toastr.error(err.error.Message, '', {
              timeOut: 3000,
            });
          } else if (err.error.message) {
            this.toastr.error(err.error.message, '', {
              timeOut: 3000,
            });
          } else {
            this.toastr.error('Something went wrong!', '', {
              timeOut: 3000,
            });
          }
        }
      );
    }
  }

  changeOrderStatus(status, id) {
    this.spinner.show();
    this.menuPro.editOrderStatus(id, status).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        if (status == 'Paid') {
          this.getAllRestaurantOrders(this.type);
        } else {
          this.toastr.success(`Status Changed to ${status}`, '', {
            timeOut: 3000,
          });
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  play() {
    const audio: HTMLAudioElement = this.audioplayer.nativeElement;
    audio.play().catch((err) => {
      // alert('audio error ' + err);
    });
  }

  validate(orders) {
    if (orders[0]['table_number']) {
      return true;
    } else {
      return false;
    }
  }
}
