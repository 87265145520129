<div class="ratePop">
    <h6 class="text-center">Rate your experience</h6>
    <div class="rate">
        <fieldset class="rating">
            <input type="radio" (change)="getAnswers(5)" id="ratee5" name="starss" value="5" /><label class="full"
                for="ratee5" title="5 Starss"></label>
            <input type="radio" (change)="getAnswers(4)" id="ratee4" name="starss" value="4" /><label class="full"
                for="ratee4" title="4 Starss"></label>
            <input type="radio" (change)="getAnswers(3)" id="ratee3" name="starss" value="3" /><label class="full"
                for="ratee3" title="3 Starss"></label>
            <input type="radio" (change)="getAnswers(2)" id="ratee2" name="starss" value="2" /><label class="full"
                for="ratee2" title="2 Starss"></label>
            <input type="radio" (change)="getAnswers(1)" id="ratee1" name="starss" value="1" /><label class="full"
                for="ratee1" title="1 Star"></label>
        </fieldset>
        <div class="cls"></div>
        <hr>

        <span>Rating</span>
        <div class="star-ratings">
            <div class="fill-ratings" [style.width]="20 * rating?.avg_rating + '%'">
                <span>★★★★★</span>
            </div>
            <div class="empty-ratings">
                <span>★★★★★</span>
            </div>
        </div>
        ({{rating?.count_rating}})

    </div>
</div>