<div class="home">
    <div class="head">
        <h1>Order</h1>
    </div>
    <div class="body">
        <h2>Your Order</h2>
        <div class="or_head mb-2">
            <span [class.active]="c_order == 'cart'" (click)="showOrder('cart')">Cart</span>
            <span [class.active]="c_order == 'order'" (click)="showOrder('order')">Order</span>
        </div>

        <ng-container *ngIf="c_order == 'cart'">
            <ng-container *ngIf="items?.length != 0">

                <div class="item" *ngFor="let item of items; let i = index">
                    <div class="row">
                        <div class="col-6 nopadding">
                            <h6>{{item.digital_menu_section_item.name}}</h6>
                            <p *ngIf="!hasMod(i)">Would you like to add any modifiers?<br><span
                                    routerLink="/mori/menu/item/{{item.digital_menu_section_item.id}}">Click
                                    Here</span>
                            </p>
                            <p *ngIf="hasMod(i)">
                                <ng-container
                                    *ngFor="let mod of item.digital_menu_section_item.digital_menu_section_item_modifiers">
                                    <ng-container *ngIf="mod.quantity > 0">{{mod.name}} |</ng-container>
                                </ng-container>
                            </p>
                        </div>
                        <div class="col-4 text-center nopadding">
                            <h5>EGP {{item.digital_menu_section_item.price}} ({{item.quantity}})</h5>
                        </div>
                        <div class="col-2 text-center">
                            <span class="c" (click)="removeItem(i)">X</span>
                        </div>
                    </div>
                </div>

                <div class="total">
                    <div class="row">
                        <div class="col-6 nopadding">
                            <h6>Total</h6>
                        </div>
                        <div class="col-6 text-right nopadding">
                            <h4>EGP {{total}}</h4>
                        </div>
                    </div>
                </div>

                <div class="table" *ngIf="!order_id">
                    <ng-container *ngIf="status == 'check_table'">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" placeholder="Table Number" [(ngModel)]="table_number">
                            </div>
                            <div class="col-4">
                                <button (click)="checkTable(table_number)" [disabled]="!table_number">Check</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="status != 'check_table'">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" placeholder="Pass Code" [(ngModel)]="pass_code">
                            </div>
                            <div class="col-4">
                                <button (click)="checkPassCode(pass_code)" [disabled]="!pass_code"
                                    *ngIf="status == 'check_pass'">Check</button>
                                <button (click)="addOrder(pass_code)" [disabled]="!pass_code"
                                    *ngIf="status == 'empty_table'">Order</button>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="table" *ngIf="order_id">
                    <button (click)="cancelEdit()" class="mr-2">Cancel Edit</button>
                    <button (click)="editOrder()">Complete Order</button>
                    <!-- <button routerLink="/mori/payment">Pay Now</button> -->
                </div>
            </ng-container>

            <!-- <ng-container *ngIf="order_id">
                <div class="timerr">
                    <div class="row">
                        <div class="col-4 nopadding">
                            <h6>Timer</h6>
                            <h6 *ngIf="order_status">Status <br> <span>{{order_status}}</span></h6>
                        </div>
                        <div class="col-8 text-right nopadding">
                            <p><span>{{minutes}}</span> : <span>{{seconds}}</span></p>
                        </div>
                    </div>
                </div>
            </ng-container> -->

            <div class="no text-center" *ngIf="items && items?.length == 0">
                <p>Cart is empty!</p>
            </div>
        </ng-container>

        <ng-container *ngIf="c_order == 'order'">
            <ng-container *ngIf="cur_order && cur_order?.length != 0">
                <div class="item" *ngFor="let item of cur_order; let i = index">
                    <div class="row">
                        <div class="col-6 nopadding">
                            <h6>{{item.digital_menu_section_item.name}}</h6>
                            <!-- <p *ngIf="!hasMod(i)">Would you like to add any modifiers?<br><span
                                    routerLink="/mori/menu/item/{{item.digital_menu_section_item.id}}">Click
                                    Here</span>
                            </p> -->
                            <p *ngIf="ohasMod(i)">
                                <ng-container *ngFor="let mod of item.order_item_modifiers">
                                    <ng-container *ngIf="mod.quantity > 0">{{mod.name}} |</ng-container>
                                </ng-container>
                            </p>
                        </div>
                        <div class="col-6 text-right nopadding">
                            <h5>EGP {{item.digital_menu_section_item.price}} ({{item.quantity}})</h5>
                        </div>
                        <!-- <div class="col-2 text-center">
                            <span class="c" (click)="removeItem(i)">X</span>
                        </div> -->
                    </div>
                </div>

                <div class="total">
                    <div class="row">
                        <div class="col-6 nopadding">
                            <h6>Total</h6>
                        </div>
                        <div class="col-6 text-right nopadding">
                            <h4>EGP {{cur_total}}</h4>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="order_id">
                    <div class="timerr">
                        <div class="row">
                            <div class="col-12 nopadding">
                                <h6 *ngIf="order_status">Order Status <span>{{order_status}}</span></h6>
                            </div>
                            <!-- <div class="col-4 nopadding">
                                <h6>Timer</h6>
                                <h6 *ngIf="order_status">Status <br> <span>{{order_status}}</span></h6>
                            </div>
                            <div class="col-8 text-right nopadding">
                                <p><span>{{minutes}}</span> : <span>{{seconds}}</span></p>
                            </div> -->
                        </div>
                    </div>
                </ng-container>

                <div class="total text-right">
                    <div class="row">
                        <div class="col-12 nopadding">
                            <button (click)="backToEditOrder()" class="mr-2" *ngIf="order_status == 'Queued'">Edit
                                Order</button>
                            <button routerLink="/mori/payment/{{order_id}}">Pay Now</button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="no text-center table" *ngIf="!cur_order || cur_order?.length == 0">
                <ng-container *ngIf="status == 'check_table'">
                    <div class="row mb-3 mt-3">
                        <div class="col-8">
                            <input type="number" placeholder="Order Code" [(ngModel)]="order_code">
                        </div>
                        <div class="col-4">
                            <button (click)="retrieveOrder(order_code)" [disabled]="!order_code">Check</button>
                        </div>
                    </div>
                </ng-container>
                <p>No orders yet!</p>
            </div>
        </ng-container>

    </div>
</div>