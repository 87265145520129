<div class="home">
    <div class="head">
        <h1><i class="fa fa-chevron-left mr-2" (click)="back()"></i> Menu</h1>
    </div>
    <div class="body">

        <div class="items">

            <div class="item">
                <div class="pic" [style.background-image]="'url(' + item?.fc_digital_menu_section_item?.image_path + ')'">
                </div>
                <div class="row">
                    <div class="col-6">
                        <h6>{{item?.fc_digital_menu_section_item?.name}}</h6>
                        <p>{{item?.fc_digital_menu_section_item?.description}}</p>
                    </div>
                    <div class="col-6 text-right">
                        <div class="count">
                            <span class="fa fa-minus inc" (click)="removeItem()"></span>
                            <span class="num">{{item?.quantity || 0}}</span>
                            <span class="fa fa-plus inc" (click)="addItem()"></span>
                        </div>
                        <span class="p">EGP {{item?.fc_digital_menu_section_item?.price}}</span>
                    </div>
                </div>

                <div class="mod">
                    <h5>Add Ons</h5>
                    <div class="mod_item">
                        <ng-container
                            *ngFor="let mod of item?.fc_digital_menu_section_item?.fc_digital_menu_section_item_modifiers; let i = index">
                            <div class="row">
                                <div class="col-5 nopadding">
                                    <h6>{{mod.name}}</h6>
                                </div>
                                <div class="col-3 text-center nopadding">
                                    <p>EGP {{mod.price}}</p>
                                </div>
                                <div class="col-4 nopadding text-right">
                                    <div class="count">
                                        <span class="fa fa-minus inc" (click)="removeItemMod(i,mod.id)"></span>
                                        <span class="num">{{mod?.quantity || 0}}</span>
                                        <span class="fa fa-plus inc" (click)="addItemMod(i,mod.id)"></span>
                                    </div>
                                    <!-- <input type="checkbox" (change)="addItemMod(i)" [checked]="mod?.checked"
                                        [disabled]="isChecked()"> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>