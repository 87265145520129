import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';

@Component({
  selector: 'app-order-menu-item',
  templateUrl: './order-menu-item.component.html',
  styleUrls: ['./order-menu-item.component.scss']
})
export class OrderMenuItemComponent implements OnInit {

  id: any;
  item: any;
  local_item: any;
  half: any = '0';

  constructor(
    private spinner: NgxSpinnerService,
    private menuPro: MenuProService,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.getItem(this.id);
        let or = localStorage.getItem('t_order');
        if (or) {
          let or_arr = JSON.parse(or);
          for (let index = 0; index < or_arr.length; index++) {
            const element = or_arr[index];
            if (element.digital_menu_section_item.id == this.id) {
              this.local_item = element;
              return true;
            }
          }
        }
      }
    })
  }

  ngOnInit(): void {
  }

  getItem(id) {
    this.spinner.show();
    this.menuPro.getUserDigitalMenuItem(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.item = res['Menu Item'];
        this.item.quantity = this.local_item.quantity;
        this.half = this.local_item.half_portion;
        for (let index = 0; index < this.item.digital_menu_section_item.digital_menu_section_item_modifiers.length; index++) {
          const element = this.item.digital_menu_section_item.digital_menu_section_item_modifiers[index];
          for (let local_index = 0; local_index < this.local_item.digital_menu_section_item.digital_menu_section_item_modifiers.length; local_index++) {
            const local_element = this.local_item.digital_menu_section_item.digital_menu_section_item_modifiers[local_index];
            if (element.id == local_element.id) {
              element.quantity = local_element.quantity;
            }
          }
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  addItem() {
    let or = localStorage.getItem('t_order');
    if (or) {
      let or_arr = JSON.parse(or);
      for (let index = 0; index < or_arr.length; index++) {
        const element = or_arr[index];
        if (element.digital_menu_section_item.id == this.item.digital_menu_section_item.id) {
          element.quantity = element.quantity + 1;
          this.item.quantity = this.item.quantity + 1;
          element.half_portion = this.half;
          localStorage.setItem('t_order', JSON.stringify(or_arr));
          this.updateCart();
          return true;
        }
      }
      this.item.quantity = 1;
      this.item.half_portion = this.half;
      or_arr.push(this.item);
      localStorage.setItem('t_order', JSON.stringify(or_arr));
      this.updateCart();
    } else {
      let or_arr = [];
      this.item.quantity = 1;
      this.item.half_portion = this.half;
      or_arr.push(this.item);
      localStorage.setItem('t_order', JSON.stringify(or_arr));
      this.updateCart();
    }
  }

  removeItem() {
    let or = localStorage.getItem('t_order');
    if (or) {
      let or_arr = JSON.parse(or);
      for (let index = 0; index < or_arr.length; index++) {
        const element = or_arr[index];
        if (element.digital_menu_section_item.id == this.item.digital_menu_section_item.id) {
          element.quantity = element.quantity - 1;
          this.item.quantity = this.item.quantity - 1;
          if (element.quantity == 0) {
            or_arr.splice(index, 1);
          }
          localStorage.setItem('t_order', JSON.stringify(or_arr));
          this.updateCart();
          return true;
        }
      }
    }
  }

  addItemMod(mod_ind, mod_id) {
    if (!this.isChecked()) {
      let or = localStorage.getItem('t_order');
      if (or) {
        let or_arr = JSON.parse(or);
        for (let index = 0; index < or_arr.length; index++) {
          const element = or_arr[index];
          if (element.digital_menu_section_item.id == this.item.digital_menu_section_item.id) {
            for (let mod_index = 0; mod_index < element.digital_menu_section_item.digital_menu_section_item_modifiers.length; mod_index++) {
              const mod_element = element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index];
              if (mod_id == mod_element.id) {
                if (mod_element.quantity) {
                  mod_element.quantity = mod_element.quantity + 1;
                  this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind].quantity = this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind].quantity + 1;
                  localStorage.setItem('t_order', JSON.stringify(or_arr));
                  this.updateCart();
                  return true;
                } else {
                  mod_element.quantity = 1;
                  this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind].quantity = 1;
                  localStorage.setItem('t_order', JSON.stringify(or_arr));
                  this.updateCart();
                  return true;
                }
              }
            }

            element.digital_menu_section_item.digital_menu_section_item_modifiers.push(this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind]);
            element.digital_menu_section_item.digital_menu_section_item_modifiers[element.digital_menu_section_item.digital_menu_section_item_modifiers.length - 1].quantity = 1;
            this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind].quantity = 1;
            localStorage.setItem('t_order', JSON.stringify(or_arr));
            this.updateCart();
            return true;
          }
        }
        // this.item.digital_menu_section_item_modifiers[mod_ind].quantity = 1;
        // or_arr.push(this.item);
        // localStorage.setItem('t_order', JSON.stringify(or_arr));
        // this.updateCart();
      }
    }
  }

  removeItemMod(mod_ind, mod_id) {
    if (!this.isChecked()) {
      let or = localStorage.getItem('t_order');
      if (or) {
        let or_arr = JSON.parse(or);
        for (let index = 0; index < or_arr.length; index++) {
          const element = or_arr[index];
          if (element.digital_menu_section_item.id == this.item.digital_menu_section_item.id) {
            for (let mod_index = 0; mod_index < element.digital_menu_section_item.digital_menu_section_item_modifiers.length; mod_index++) {
              const mod_element = element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index];
              if (mod_id == mod_element.id) {
                if (mod_element.quantity && mod_element.quantity > 0) {
                  mod_element.quantity = mod_element.quantity - 1;
                  this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind].quantity = this.item.digital_menu_section_item.digital_menu_section_item_modifiers[mod_ind].quantity - 1;
                  localStorage.setItem('t_order', JSON.stringify(or_arr));
                  this.updateCart();
                  return true;
                }
              }
            }

          }
        }
      }
    }
  }

  updateCart() {
    let profile = document.querySelector('#foot_or_btn') as HTMLElement
    profile.click();
  }

  isChecked() {
    if (this.item.quantity > 0) {
      return false;
    } else {
      return true;
    }
  }

  getHalfValue(half) {    
    this.half = half;
    let or = localStorage.getItem('t_order');
    if (or) {
      let or_arr = JSON.parse(or);
      for (let index = 0; index < or_arr.length; index++) {
        const element = or_arr[index];
        if (element.digital_menu_section_item.id == this.item.digital_menu_section_item.id) {
          element.half_portion = this.half;
          localStorage.setItem('t_order', JSON.stringify(or_arr));
          this.updateCart();
          return true;
        }
      }
    }
  }

}
