import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodesProService } from '../qr-codes-pro.service';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.scss']
})
export class QrCodesComponent implements OnInit {

  qrs: any;
  surveys: any;
  next: any;
  nexts: any;
  search: any = '';
  s_search: any = '';
  type: any = 'qrs';
  groups: any;
  qrcode_group_id: any = '';

  rand: any = Math.random();

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private _FileSaverService: FileSaverService,
    private _http: HttpClient,
    private toastr: ToastrService,
    private qrCodesPro: QrCodesProService) {
    this.rand = Math.random();
  }

  ngOnInit(): void {
    this.getQrCodes('');
    this.getSurveys('');
    this.getQrGroups();
  }

  getQrGroups() {
    this.qrCodesPro.getQrGroups()
      .subscribe((res: any) => {
        console.log(res);
        this.groups = res.user_qrcode_groups;
      }, err => {
        console.log(err);
      })
  }

  getQrCodes(val) {
    this.search = val;
    this.spinner.show();
    this.qrCodesPro.getQrCodes(val)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.qrs = res.qrcodes.data;
        this.next = res.qrcodes.next_page_url;
        this.rand = Math.random();
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  getSurveys(val) {
    this.s_search = val;
    this.spinner.show();
    this.qrCodesPro.getSurveys(val)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.surveys = res.surveys;
        this.rand = Math.random();
        // this.nexts = res.qrcodes.next_page_url;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onScroll(event) {
    if (this.type == 'qrs') {
      if (this.next) {
        this.getNextPage(this.next).subscribe((response: any) => {
          this.qrs = this.qrs.concat(response.qrcodes.data);
          this.next = response.qrcodes.next_page_url;
        })
      }
    }
    //  else if (this.type == 'sur') {
    //   if (this.next) {
    //     this.getNextPage(this.next).subscribe((response: any) => {
    //       this.surveys = this.surveys.concat(response.qrcodes.data);
    //       this.nexts = response.qrcodes.next_page_url;
    //     })
    //   }
    // }
  }

  getNextPage(url) {
    return this.qrCodesPro.getNextPage(url);
  }

  deleteQrCode(id) {
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(DeletePopupComponent, {
        width: '500px',
        // height: '80vh',
        panelClass: 'sliP',
        data: null
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result == 'true') {
          this.spinner.show();
          this.qrCodesPro.deleteQrCode(id)
            .subscribe(res => {
              console.log(res);
              this.spinner.hide();
              this.getQrCodes(this.search);
              this.getSurveys(this.s_search);
            }, err => {
              console.log(err);
              this.spinner.hide();
            })
        }
      });
    } else {
      const dialogRef = this.dialog.open(DeletePopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: null
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result == 'true') {
          this.spinner.show();
          this.qrCodesPro.deleteQrCode(id)
            .subscribe(res => {
              console.log(res);
              this.spinner.hide();
              this.getQrCodes(this.search);
              this.getSurveys(this.s_search);
            }, err => {
              console.log(err);
              this.spinner.hide();
            })
        }
      });
    }
  }

  onDown(file) {
    // const fileName = `save.png`;
    this._http.get(`${file}`, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this._FileSaverService.save(res.body);
    });

  }

  chooseQrType(val) {
    this.type = val;
  }

  addToCompare(id) {
    let qrs: any[] = [];
    let q = JSON.parse(localStorage.getItem('touch_compare'));
    if (q) {
      qrs = q;
    }
    for (let index = 0; index < qrs.length; index++) {
      if (qrs[index] == id) {
        this.toastr.success('Qr already in compare list!', '', {
          timeOut: 3000
        })
        return false;
      }
    }

    qrs.push(id);
    localStorage.setItem('touch_compare', JSON.stringify(qrs));
    this.toastr.success('Qr added to compare list!', '', {
      timeOut: 3000
    })
  }

  changeGroup(qr_id, value){
    this.qrCodesPro.changeGroup(qr_id, value)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  suspend(qr_id, value) {
    this.qrCodesPro.suspendQrcode(qr_id, value)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Qrcode status changed successfully!', '', {
          timeOut: 3000
        })
        this.getQrCodes(this.search);
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
