import { Component, OnInit, Input } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-cumulative-theg',
  templateUrl: './cumulative-theg.component.html',
  styleUrls: ['./cumulative-theg.component.scss']
})
export class CumulativeThegComponent implements OnInit {

  menu: any;
  isPdf: any;
  facebook: any;
  instagram: any;
  website: any;
  survey: any;
  file_path: any;
  logo_path: any;
  background_path: any;
  qrButtons: any[] = [];
  show_f: boolean = false;

  @Input() res: any;

  constructor() {
  }

  ngOnInit(): void {
    this.menu = this.res.qrcode;
    this.logo_path = this.menu.logo_path;
    this.background_path = this.menu.background_image_path;
    for (let index = 0; index < this.menu.attributes.length; index++) {
      if (this.menu.attributes[index].key == 'Links') {
        let linksArr = JSON.parse(this.menu.attributes[index].value);
        linksArr.forEach(element => {
          if (element.key == 'facebook') {
            this.facebook = element.value;
          } else if (element.key == 'instagram') {
            this.instagram = element.value;
          } else if (element.key == 'website') {
            this.website = element.value;
          } else if (element.key == 'survey') {
            this.survey = element.value;
          }
        });
      } else if (this.menu.attributes[index].key == 'Qrcodes') {
        this.qrButtons = JSON.parse(this.menu.attributes[index].value);
      } else if (this.menu.attributes[index].key == 'file_path') {
        this.file_path = this.menu.attributes[index].value;
        this.isPdf = this.menu.attributes[index].ext;
        if (this.isPdf != 'pdf') {
          document.getElementById('spin').classList.add('hide_spin');
        }
      }
    }

    document.addEventListener('scroll', (event) => {
      let url = window.location.href;
      if (url.includes('/qrcode/')) {
        let div = document.getElementById("top");
        let rect = div.getBoundingClientRect();
        let t = rect.top;
        let h = rect.height;
        let s = h + t;
        if (s < -20) {
          this.show_f = true;
        } else {
          this.show_f = false;
        }
      }
    });
  }

  getLink(link) {
    if (link.includes('http')) {
      return link;
    } else {
      return `//${link}`;
    }
  }

  show(id, id2) {
    document.getElementById(id).classList.toggle('show');
    document.getElementById(id2).classList.toggle('show');
  }

  callBackFn(pdf: PDFDocumentProxy) {
    // this.spinner.hide();
    document.getElementById('spin').classList.add('hide_spin');
  }

}
