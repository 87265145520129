<div class="home">
    <div class="head">
        <h1>Order</h1>
    </div>
    <div class="body">
        <h2>Your Order</h2>
        <div class="item">
            <h5 *ngIf="success == 'true'">Your order is confimred</h5>
            <h5 *ngIf="success == 'false'">Payment Failed</h5>
            <h6>Order to be ready in</h6>
            <p><span>{{minutes}}</span> : <span>{{seconds}}</span></p>
        </div>

    </div>
</div>