<div class="tips">
    <div class="">
        <form #welForm="ngForm" (ngSubmit)="addTips(welForm.value)">

            <div class="row">
                <div class="col-6">
                    <p>Additional Tips</p>
                </div>
                <div class="col-6">
                    <input type="number" class="tx" ngModel name="tips">
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p>Waiter Name</p>
                </div>
                <div class="col-6">
                    <input type="text" class="tx" ngModel name="name">
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <p>Waiter Rate</p>
                </div>
                <div class="col-7 text-right">
                    <div class="rate">
                        <fieldset class="rating">
                            <input type="radio" id="rate5" name="stars" ngModel value="5" /><label class="full"
                                for="rate5" title="5 Stars"></label>
                            <input type="radio" id="rate4" name="stars" ngModel value="4" /><label class="full"
                                for="rate4" title="4 Stars"></label>
                            <input type="radio" id="rate3" name="stars" ngModel value="3" /><label class="full"
                                for="rate3" title="3 Stars"></label>
                            <input type="radio" id="rate2" name="stars" ngModel value="2" /><label class="full"
                                for="rate2" title="2 Stars"></label>
                            <input type="radio" id="rate1" name="stars" ngModel value="1" /><label class="full"
                                for="rate1" title="1 Star"></label>
                        </fieldset>
                        <div class="cls"></div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-2">
                <button>Submit</button>
            </div>

        </form>
    </div>
</div>