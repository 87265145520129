<app-navbar [page]="'QR CODES'"></app-navbar>
<div class="qrcodes" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="true"
    (scrolled)="onScroll($event)">
    <div class="container-fluid">
        <div class="buttons text-right">
            <button class="btn btn-primary" routerLink="/base/qrcodes/groups">Groups</button>
        </div>

        <div class="groups" *ngIf="groups?.length != 0">
            <h4 class="mb-3">Groups</h4>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let g of groups">
                    <div class="item" routerLink="/base/qrcodes/groups/{{g.id}}">
                        <h4>{{g.name}}</h4>
                    </div>
                </div>
            </div>
            <hr>
        </div>

        <div class="items">

            <div class="head">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <div class="con text-center">
                            <span [class.active]="type == 'qrs'" (click)="chooseQrType('qrs')">QR Codes</span>
                            <span [class.active]="type == 'sur'" (click)="chooseQrType('sur')">Surveys</span>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>

            <ng-container *ngIf="type == 'qrs'">
                <div class="in">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="s">
                                        <select (change)="getQrCodes($event.target.value)" class="form-control" name="suspended" id="suspended">
                                            <option value="">All</option>
                                            <option value="suspended">Suspended</option>
                                        </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="s">
                                <form #dForm="ngForm" (ngSubmit)="getQrCodes(search)">
                                    <button (click)="getQrCodes(search)"><img src="assets/imgs/search.svg"
                                            width="20"></button>
                                    <input type="text" placeholder="Search in Qr Codes" [(ngModel)]="search"
                                        name="search">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="qrs?.length != 0">
                    <div class="item" *ngFor="let qr of qrs">
                        <div class="row">

                            <!-- <div class="item text-center">

                            <h4 routerLink="/base/addqr/{{qr.id}}">{{qr.name}}</h4>
                            <button class="btn btn-danger" (click)="deleteQrCode(qr.id)">Delete</button>
                        </div> -->

                            <div class="col-md-2">
                                <img *ngIf="qr" src="{{qr.qrcode_path}}?rand={{rand}}" width="100%" height="auto">
                            </div>
                            <div class="col-md-4">
                                <div class="desc">
                                    <h6>{{qr.name}}</h6>
                                    <div class="links">
                                        <div class="l">
                                            <p>Link</p>
                                            <p>Org</p>
                                            <p>Created</p>
                                            <p>Updated</p>
                                            <p>Type</p>
                                            <p>Group</p>
                                            <p>Status</p>
                                        </div>
                                        <div class="r">
                                            <a>https://touch-less.co/qrcode/{{qr.id}}</a>
                                            <a>{{qr.name}}</a>
                                            <a>{{qr.created_at}}</a>
                                            <a>{{qr.updated_at}}</a>
                                            <a>{{qr.type}}</a>
                                            <div class="txt">
                                                <select
                                                  name="qrcode_group_id"
                                                  class="txt_in"
                                                  (change)="changeGroup(qr.id,$event.target.value)"
                                                >
                                                
                                                  <option value="">No Group</option>
                                                  <option *ngFor="let g of groups" id="{{g.id+qr.id}}" value="{{g.id}}" [attr.selected]="(g.id === qr.qrcode_group_id) ? true : null">
                                                    {{ g.name }}
                                                  </option>
                                                </select>
                                              </div>
                                              <a style="cursor: pointer;" (click)="suspend(qr.id, 0)" *ngIf="qr.suspended == 1">Suspended</a>
                                              <a style="cursor: pointer;" (click)="suspend(qr.id, 1)" *ngIf="qr.suspended != 1">Active</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="scans text-center">
                                    <p>{{qr.number_of_scans}}</p>
                                    <span>Scans</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="buttons">
                                    <button (click)="onDown(qr.qrcode_path)">Download</button>
                                    <button routerLink="/base/addqr/{{qr.id}}">EDIT</button>
                                    <button routerLink="/base/analytics/{{qr.id}}">Statistics</button>
                                    <button (click)="addToCompare(qr.id)">Add to compare</button>
                                    <button class="d" (click)="deleteQrCode(qr.id)">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="type == 'sur'">
                <div class="in">
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <div class="s">
                                <form #dForm="ngForm" (ngSubmit)="getSurveys(s_search)">
                                    <button (click)="getSurveys(s_search)"><img src="assets/imgs/search.svg"
                                            width="20"></button>
                                    <input type="text" placeholder="Search in Surveys" [(ngModel)]="s_search"
                                        name="search">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="surveys?.length != 0">
                    <div class="item" *ngFor="let qr of surveys">
                        <div class="row">

                            <!-- <div class="item text-center">

                            <h4 routerLink="/base/addqr/{{qr.id}}">{{qr.name}}</h4>
                            <button class="btn btn-danger" (click)="deleteQrCode(qr.id)">Delete</button>
                        </div> -->

                            <div class="col-md-2">
                                <img *ngIf="qr" src="{{qr.qrcode_path}}?rand={{rand}}" width="100%" height="auto">
                            </div>
                            <div class="col-md-4">
                                <div class="desc">
                                    <h6>{{qr.name}}</h6>
                                    <div class="links">
                                        <div class="l">
                                            <p>Link</p>
                                            <p>Org</p>
                                            <p>Created</p>
                                        </div>
                                        <div class="r">
                                            <a
                                                href="https://touch-less.co/qrcode/{{qr.id}}">https://touch-less.co/qrcode/{{qr.id}}</a>
                                            <a>{{qr.name}}</a>
                                            <a>{{qr.updated_at}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="scans text-center">
                                    <p>{{qr.number_of_scans}}</p>
                                    <span>Scans</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="buttons">
                                    <button (click)="onDown(qr.qrcode_path)">Download</button>
                                    <button routerLink="/base/addqr/{{qr.id}}">EDIT</button>
                                    <button routerLink="/base/survey-analytics/{{qr.id}}">Statistics</button>
                                    <button class="d" (click)="deleteQrCode(qr.id)">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </div>

        <div class="noitems text-center" *ngIf="qrs?.length == 0">
            <p>There are no QR codes!</p>
        </div>
    </div>
</div>