<app-navbar [page]="'Dashboard'"></app-navbar>
<div class="dashboard">
    <div class="container">
        <h1>Analytics Overview</h1>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="ch">
                    <h4 class="t">QR Codes</h4>
                    <p class="t">Total QR Codes <span class="t">{{data?.count_of_qrcodes | number}}</span></p>
                    <p>Total Scans <span class="t">{{data?.total_scans | number}}</span></p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="ch">
                    <h4>Most Popular</h4>
                    <h6 class="text-right">Scans</h6>
                    <p *ngFor="let q of data?.qrcodes_most_popular">{{q.name}}
                        <span>{{q.number_of_scans | number}}</span></p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="ch">
                    <h4>Least Popular</h4>
                    <h6 class="text-right">Scans</h6>
                    <p *ngFor="let q of data?.qrcodes_least_popular">{{q.name}}
                        <span>{{q.number_of_scans | number}}</span></p>
                </div>
            </div>
        </div>

        <h2>Latest QR Codes</h2>
        <div class="row">
            <div class="col-md-3 col-sm-6" *ngFor="let qr of data?.qrcodes_last_added">
                <div class="ch">
                    <img src="{{qr.qrcode_path}}?rand={{rand}}" width="100%">
                    <h4 class="mt-2">{{qr.name}}</h4>
                    <button (click)="onDown(qr.qrcode_path)">Download</button>
                    <button routerLink="/base/analytics/{{qr.id}}">Statistics</button>
                </div>
            </div>
        </div>
    </div>
</div>