import { Component, OnInit } from '@angular/core';
import { MenuProService } from '../menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  qr: any;
  rand: any;

  menu_content: any;
  name: any = '';
  color: any;
  background_color: any;
  size: any;
  facebook: any = '';
  instagram: any = '';
  style: any;
  eye_style: any;
  website: any = '';
  survey: any = '';
  gradient_start_color: any;
  gradient_end_color: any;
  gradient_type: any;

  menu: any;
  isPdf: any;
  url: any;

  constructor(
    private menuPro: MenuProService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService) {
    this.rand = Math.random();
  }

  ngOnInit(): void {
    this.getMenuInfo();
  }

  getMenuInfo() {
    this.spinner.show();
    this.menuPro.getMenuInfo()
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.menu_content = res.menu_content;
        this.getMenu(this.menu_content.user_id);
        if (this.menu_content.name) {
          this.name = this.menu_content.name;
        }
        this.color = this.menu_content.color;
        this.background_color = this.menu_content.background_color;
        this.size = this.menu_content.size;
        if (this.menu_content.facebook) {
          this.facebook = this.menu_content.facebook;
        }
        if (this.menu_content.instagram) {
          this.instagram = this.menu_content.instagram;
        }
        this.style = this.menu_content.style;
        this.eye_style = this.menu_content.eye_style;
        if (this.menu_content.survey) {
          this.survey = this.menu_content.survey;
        }
        if (this.menu_content.website) {
          this.website = this.menu_content.website;
        }
        this.gradient_start_color = this.menu_content.gradient_start_color;
        this.gradient_end_color = this.menu_content.gradient_end_color;
        this.gradient_type = this.menu_content.gradient_type;
        this.qr = this.menu_content.qrcode_path;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  getMenu(id) {
    // this.spinner.show();
    this.menuPro.getMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        this.menu = res.menu;
        this.isPdf = res.ext;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.menu.path);
        // this.spinner.hide();
      }, err => {
        console.log(err);
        // this.spinner.hide();
      })
  }

  addMenu(data, menu, logo) {
    this.spinner.show();
    this.qr = null;
    this.rand = Math.random();
    if (menu) {
      data.file = menu;
    }
    if (logo) {
      data.logo = logo;
    }
    console.log(data);

    this.menuPro.addMenu(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.qr = res.qrcode;
        this.toastr.success('QR Was Added Successfully!', '', {
          timeOut: 3000
        })
        this.getMenuInfo();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.errors.file) {
          this.toastr.error(err.error.errors.file[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.name) {
          this.toastr.error(err.error.errors.name[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.size) {
          this.toastr.error(err.error.errors.size[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

}
