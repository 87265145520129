import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { OrderingModule } from './ordering/ordering.module';
import { FoodcourtOrderingModule } from './foodcourtordering/foodcourtordering.module';
import { MoriOrderingModule } from './moriordering/moriordering.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorProvider } from './interceptor';
import { AuthService } from './dashboard/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuProService } from './dashboard/menu-pro.service';
import { RestaurantService } from './dashboard/restaurant.service';
import { UserMenuComponent } from './dashboard/user-menu/user-menu.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChartsModule } from 'ng2-charts';
import { QrCodesProService } from './dashboard/qr-codes-pro.service';
import { QrCodeDetailsComponent } from './dashboard/qr-code-details/qr-code-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RoleGuardService } from './dashboard/role-guard.service';
import { VerifyComponent } from './verify/verify.component';
import { StaticSurveryComponent } from './static-survery/static-survery.component';
import { StaticSurveyArabicComponent } from './static-survey-arabic/static-survey-arabic.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FileSaverModule } from 'ngx-filesaver';
import { LandingComponent } from './landing/landing.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSocialShareModule } from 'ngx-social-share';
import { AgmCoreModule } from '@agm/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { OrderLoginComponent } from './order-login/order-login.component';
import { OrderRegisterComponent } from './order-register/order-register.component';
import { FoodCourtProService } from './dashboard/foodcourt/food-court-pro.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserMenuComponent,
    QrCodeDetailsComponent,
    VerifyComponent,
    StaticSurveryComponent,
    StaticSurveyArabicComponent,
    LandingComponent,
    OrderLoginComponent,
    OrderRegisterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DashboardModule,
    OrderingModule,
    FoodcourtOrderingModule,
    MoriOrderingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    MatPaginatorModule,
    MatSortModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      // apiKey: "AIzaSyBAjVVN9IO-ZND91rj1uW2zHTJx59_nbGo"
      apiKey: 'AIzaSyC70PdcuaUP9ZZGWyKju9twuWmgKbTAWrw',
    }),
    PdfViewerModule,
    ChartsModule,
    DeviceDetectorModule,
    FileSaverModule,
    CarouselModule,
    NgxSocialShareModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true },
    AuthService,
    RoleGuardService,
    MenuProService,
    QrCodesProService,
    RestaurantService,
    FoodCourtProService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
