import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss']
})
export class OrderConfirmedComponent implements OnInit {

  id: any;
  timer: any;
  seconds: any = 0;
  minutes: any = 0;
  success: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private foodcourtPro: FoodCourtProService) {
    this.route.queryParams.subscribe(params => {
      this.success = params.success;
    })
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  ngOnInit(): void {
  }

  getMenu(id) {
    this.spinner.show();
    this.foodcourtPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.timer = res.timer;
        if (this.timer) {
          let ti = this.timer.split(":");
          let min = parseInt(ti[0]) * 60 * 1000;
          let sec = parseInt(ti[1]) * 1000;
          let x = min + sec;
          setInterval(() => {
            if (x > 0) {
              x = x - 1000;
              this.seconds = Math.floor((x % (1000 * 60)) / 1000);
              this.minutes = Math.floor((x % (1000 * 60 * 60)) / (1000 * 60));
            }
          }, 1000);
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
