import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserMenuComponent } from './dashboard/user-menu/user-menu.component';
import { QrCodeDetailsComponent } from './dashboard/qr-code-details/qr-code-details.component';
import { VerifyComponent } from './verify/verify.component';
import { StaticSurveryComponent } from './static-survery/static-survery.component';
import { StaticSurveyArabicComponent } from './static-survey-arabic/static-survey-arabic.component';
import { OrderLoginComponent } from './order-login/order-login.component';
import { OrderRegisterComponent } from './order-register/order-register.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
};

const routes: Routes = [
  // { path: '', redirectTo: '/index', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'order-login', component: OrderLoginComponent },
  { path: 'order-register', component: OrderRegisterComponent },
  { path: 'qrcode/:id', component: UserMenuComponent },
  { path: 'verify/:token', component: VerifyComponent },
  { path: 'static_survery', component: StaticSurveryComponent },
  { path: 'static_survery_ar', component: StaticSurveyArabicComponent },
  // { path: 'qrcode/:id', component: QrCodeDetailsComponent },
  {
    path: 'base',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  // {
  //   path: 'order',
  //   loadChildren: () =>
  //     import('./ordering/ordering.module').then((m) => m.OrderingModule),
  // },
  {
    path: 'order',
    loadChildren: () =>
      import('./new-ordering/new-ordering.module').then(
        (m) => m.NewOrderingModule
      ),
  },
  {
    path: 'restaurant_digital_menu',
    loadChildren: () =>
      import('./pdf-ordering/pdf-ordering.module').then(
        (m) => m.PdfOrderingModule
      ),
  },
  {
    path: 'ordering',
    loadChildren: () =>
      import('./ordering-no-payment/ordering-no-payment.module').then(
        (m) => m.OrderingNoPaymentModule
      ),
  },
  {
    path: 'old-order',
    loadChildren: () =>
      import('./ordering/ordering.module').then((m) => m.OrderingModule),
  },
  {
    path: 'dine-in',
    loadChildren: () =>
      import('./dine-in/dine-in.module').then((m) => m.DineInModule),
  },
  {
    path: 'mori',
    loadChildren: () =>
      import('./moriordering/moriordering.module').then(
        (m) => m.MoriOrderingModule
      ),
  },
  {
    path: 'donation',
    loadChildren: () =>
      import('./donation/donation.module').then((m) => m.DonationModule),
  },
  {
    path: 'foodcourt',
    loadChildren: () =>
      import('./foodcourtordering/foodcourtordering.module').then(
        (m) => m.FoodcourtOrderingModule
      ),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
