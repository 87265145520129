import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuProService {

  deviceInfo: any;
  device_id: any;
  url = environment.url;

  constructor(
    public http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {}

  addMenu(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    console.log(formData);
    return this.http.post(`${url}api/menu`, formData);
  }

  getMenuInfo() {
    let url = environment.url;
    let res = this.http.get(`${url}api/menu_info`);
    return res;
  }

  getMenu(id, device_info = null, device_id = null, scan = null) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/user_qrcode/${id}?device_info=${device_info}&device_id=${device_id}&scan=${scan}`
    );
    return res;
  }

  getAnalytics(days) {
    let url = environment.url;
    let res = this.http.get(`${url}api/qrcodeStats?days=${days}`);
    return res;
  }

  getQrAnalytics(id, from_date, to_date) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/qrcodeStats/${id}?from_date=${from_date}&to_date=${to_date}`
    );
    return res;
  }

  getCompareAnalytics(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/qrcode/compare`, data);
    return res;
  }

  getScanCompareAnalytics(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/qrcode/compare/scans`, data);
    return res;
  }

  contact(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/receiveCompanyEmail`, data);
    return res;
  }

  // add Digital Menu

  getAdminRestaurants() {
    let url = environment.url;
    let res = this.http.get(`${url}api/all/restaurants`);
    return res;
  }

  getRestaurants() {
    let url = environment.url;
    let res = this.http.get(`${url}api/my/restaurants`);
    return res;
  }

  getRestaurant(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/restaurant/${id}`);
    return res;
  }

  addRestaurant(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/restaurant`, data);
    return res;
  }

  editRestaurant(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/edit/restaurant`, data);
    return res;
  }

  deleteRestaurant(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/delete/restaurant/${id}`);
    return res;
  }

  getDigitalMenu(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/digitalmenu/${id}`);
    return res;
  }

  addDigitalMenu(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/digitalmenu`, data);
    return res;
  }

  editDigitalMenu(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/edit/digitalmenu`, data);
    return res;
  }

  deleteDigitalMenu(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/delete/digitalmenu/${id}`);
    return res;
  }

  addDigitalMenuSectionImage(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    return this.http.post(`${url}api/add/sectionimage`, formData);
  }

  addDigitalMenuLogo(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });
    return this.http.post(`${url}api/add/menulogo`, formData);
  }

  addDigitalMenuItemImage(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    return this.http.post(`${url}api/add/itemimage`, formData);
  }

  addDigitalMenuItemAlertImage(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    return this.http.post(`${url}api/add/itemalertimage`, formData);
  }

  /* User Digital Menu */

  getUserDigitalMenu(id) {
    let d_id = localStorage.getItem('device_id');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (d_id) {
      this.device_id = d_id;
    } else {
      let d_id = Math.random() * 10000000000000000;
      localStorage.setItem('device_id', JSON.stringify(d_id));
      this.device_id = JSON.stringify(d_id);
    }
    let url = environment.url;
    let res = this.http.post(`${url}api/get/digitalmenu`, {
      menu_id: id,
      device_id: this.device_id,
      device_info: JSON.stringify(this.deviceInfo),
    });
    return res;
  }
  getUserDefaultDigitalMenu(id) {
    let d_id = localStorage.getItem('device_id');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (d_id) {
      this.device_id = d_id;
    } else {
      let d_id = Math.random() * 10000000000000000;
      localStorage.setItem('device_id', JSON.stringify(d_id));
      this.device_id = JSON.stringify(d_id);
    }
    let url = environment.url;
    let res = this.http.post(`${url}api/get/digitalmenudefault`, {
      menu_id: id,
      device_id: this.device_id,
      device_info: JSON.stringify(this.deviceInfo),
    });
    return res;
  }

  // epicFunction(d_id) {
  //   this.deviceInfo = this.deviceService.getDeviceInfo();
  //   let d = JSON.stringify(this.deviceInfo);
  // }

  setItemNotAvailable(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/notavailable/item/${id}`);
    return res;
  }

  getUserDigitalMenuItem(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/get/item/${id}`);
    return res;
  }

  addOrder(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/order`, data);
    return res;
  }

  editOrder(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/edit/order`, data);
    return res;
  }

  cancelOrder(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/cancel/order/${id}`);
    return res;
  }

  deleteOrder(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/delete/order/${id}`);
    return res;
  }

  checkTable(res_id, table_num) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/check/emptytable/${res_id}/${table_num}`
    );
    return res;
  }

  confirmOrder(data) {
    const url = environment.url;
    const res = this.http.post(`${url}api/add/defaultorder`, {
      ...data,
    });

    return res;
  }

  checkPassCode(res_id, table_num, pass_code) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/check/passcode/${res_id}/${table_num}/${pass_code}`
    );
    return res;
  }

  getRestaurantOrders(res_id, type?) {
    const typeSlug = type ? `/${type}` : '';
    let url = environment.url;
    let res = this.http.get(
      `${url}api/activeorders/restaurant/${res_id}${typeSlug}`
    );
    return res;
  }

  getAllRestaurantOrders(type?) {
    const typeSlug = type ? `/${type}` : '';
    let url = environment.url;
    let res = this.http.get(
      `${url}api/activeorders/restaurants${typeSlug}`
    );
    return res;
  }

  getTableOrders(res_id, table_id): Observable<any> {
    return this.http
      .get<any>(
        `${this.url}api/activeorders/restaurant_table/${res_id}/${table_id}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  getTotalOrders(device_id): Observable<any> {
    return this.http
      .post<any>(`${this.url}api/get/getTotalOrders`, { device_id: device_id })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  payAll(device_id): Observable<any> {
    return this.http
      .post<any>(`${this.url}api/generate/payall`, { device_id: device_id })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  payAllResponse(paymentResponse): Observable<any> {
    return this.http
      .post<any>(`${this.url}api/accept/post_pay_all`, { paymentResponse })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  getRestaurantOrdersHistory(res_id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/ordershistory/restaurant/${res_id}`);
    return res;
  }

  getRestaurantOrderItems(or_id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/get/order/${or_id}`);
    return res;
  }

  getNextPage(url) {
    return this.http.get(url);
  }

  callWaiter(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/call/waiter/${id}`);
    return res;
  }

  requestReceipt(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/ask/check/${id}`);
    return res;
  }

  callWaiterOff(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/call/waiter/off/${id}`);
    return res;
  }

  requestReceiptOff(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/ask/check/off/${id}`);
    return res;
  }

  editOrderStatus(id, status) {
    let url = environment.url;
    let res = this.http.post(`${url}api/change/orderstatus`, {
      order_id: id,
      status: status,
    });
    return res;
  }

  getIframe(id, tips = '', waiter = '', review = '') {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/generate/payment/${id}?tips=${tips}&waiter=${waiter}&review=${review}`
    );
    return res;
  }

  retrieveOrder(code) {
    let url = environment.url;
    let res = this.http.get(`${url}api/retrieve/order/${code}`);
    return res;
  }

  deleteItemAlertImage(item_id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete/itemalertimage/${item_id}`, {});
    return res;
  }

  deleteItemImage(item_id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete/itemimage/${item_id}`, {});
    return res;
  }

  removeItemRestaurantLogo(id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete/restaurantlogoimage/${id}`, {});
    return res;
  }

  getUserDigitalMenuCategories(menuID: any) {
    let url = environment.url;
    let res = this.http.get(`${url}api/get/digitalmenucategories?digital_menu_id=${menuID}`);
    return res;
  }

  addCategory(menuID: any, category_name: any, category_subtitle: any, category_sort: any, category_selected) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/digitalmenucategory`, {'digital_menu_id': menuID, 'name': category_name, 'subtitle': category_subtitle, 'sort': category_sort, "selected": category_selected});
    return res;
  }

  removeCategory(menuID: any, category_id: any) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete/digitalmenucategory`, {'digital_menu_id': menuID, 'category_id': category_id});
    return res;
  }

  removeMenuLogo(menuID: any) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete/digitalmenulogo`, {'digital_menu_id': menuID});
    return res;
  }

  addDigitalMenuBackground(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });
    return this.http.post(`${url}api/add/menubackground`, formData);
  }

  removeMenuBackground(menuID: any) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete/digitalmenubackground`, {'digital_menu_id': menuID});
    return res;
  }

}
