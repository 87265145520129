<app-navbar [page]="'TABLE ORDERS'"></app-navbar>
<div class="orders">
  <div class="container">
    <h1>Table Orders</h1>
    <div class="order_table text-center">
      <div class="table_head">
        <div class="row">
          <div class="col cell">
            <h4>Order</h4>
          </div>
          <div class="col cell">
            <h4>Price</h4>
          </div>
          <div class="col cell">
            <h4>Waiter</h4>
          </div>
          <div class="col cell">
            <h4>Receipt</h4>
          </div>
          <div class="col cell">
            <h4>Status</h4>
          </div>
          <div class="col cell">
            <h4>Cancel</h4>
          </div>
        </div>
      </div>
      <div class="table_body">
        <div class="row" *ngFor="let order of orders; let i = index">
          <div class="col cell">
            <button
              class="btn btn-primary"
              type="button"
              data-toggle="collapse"
              [attr.data-target]="'#collapseExample' + i"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Order Details
            </button>
          </div>
          <div class="col cell">
            <p>{{ order.total }} EGP</p>
          </div>
          <div
            class="col cell"
            [class.active]="order.call_waiter"
            (click)="callWaiterOff(order.id, i)"
          ></div>
          <div
            class="col cell"
            [class.active]="order.ask_for_check"
            (click)="requestReceiptOff(order.id, i)"
          ></div>
          <div class="col cell">
            <select
              (change)="changeOrderStatus(order_status.value, order.id)"
              [(ngModel)]="order.status"
              [ngModelOptions]="{ standalone: true }"
              #order_status
            >
              <option value="Queued">Queued</option>
              <option value="Pending">Pending</option>
              <option value="Cooking">Cooking</option>
              <option value="Ready_To_Serve">Ready to serve</option>
              <option value="Served">Served</option>
              <option value="Paid">Paid</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
          <div class="col cell">
            <button class="cancel" (click)="cancelOrder(order.id)">
              Cancel Order
            </button>
          </div>

          <div class="collapse" id="collapseExample{{ i }}">
            <div class="card card-body">
              <div class="row">
                <div class="col-lg-7 col-md-8">
                  <div class="order_table">
                    <div class="row" *ngFor="let item of orders[i].order_items">
                      <div class="col-5 cell borderleft">
                        <h4>{{ item.digital_menu_section_item.name }}</h4>
                        <h6>Extras</h6>
                        <p *ngFor="let mod of item.order_item_modifiers">
                          {{ mod.name }}
                        </p>
                      </div>

                      <div class="col-3 cell text-center">
                        <h4>{{ item.quantity }}</h4>
                        <h6>Extras</h6>
                        <p *ngFor="let mod of item.order_item_modifiers">
                          {{ mod.quantity }}
                        </p>
                      </div>

                      <div class="col-4 cell price">
                        <h4>EGP {{ item.total }}</h4>
                        <h6>Extras</h6>
                        <p *ngFor="let mod of item.order_item_modifiers">
                          EGP {{ mod.total }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 cell borderleft">
                        <h4 class="comment">Comments</h4>
                        <p class="comment-body">{{ orders[i].comment }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row totalContainer" *ngIf="total">
      <div class="total text-center">
        <h6>Total Receipt</h6>
        <h4>
          EGP <br />
          {{ total }}
        </h4>
      </div>
    </div>
  </div>
</div>
