import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  users: any[];

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router) { 
      this.spinner.show();
      this.auth.getUsers()
        .subscribe((res: any) => {
          console.log(res);
          this.users = res.data;
          this.spinner.hide();
          this.toastr.success(res.message, '', {
            timeOut: 3000
          })
        }, err => {
          console.log(err);
          this.spinner.hide();
          if (err.error.message) {
            this.toastr.error(err.error.message, '', {
              timeOut: 3000
            });
        }

      });
    }

  ngOnInit(): void {
  }

  suspendUser(user_id, value) {
    this.spinner.show();
      this.auth.suspendUser(user_id, value)
        .subscribe((res: any) => {
          console.log(res);
          this.spinner.hide();
          this.toastr.success(res.Message, '', {
            timeOut: 3000
          })
          window.location.reload();
        }, err => {
          console.log(err);
          this.spinner.hide();
          if (err.error.message) {
            this.toastr.error(err.error.message, '', {
              timeOut: 3000
            });
        }

      });
      
  }

  addUser(data) {
    this.spinner.show();
    this.auth.addUser(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.message, '', {
          timeOut: 3000
        })
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.errors.email) {
          this.toastr.error(err.error.errors.email[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.name) {
          this.toastr.error(err.error.errors.name[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

  resendEmail(data) {
    this.spinner.show();
    this.auth.resendEmail(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.message, '', {
          timeOut: 3000
        })
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.errors.email) {
          this.toastr.error(err.error.errors.email[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

  changeUserPassword(data) {
    this.spinner.show();
    this.auth.adminChangeUserPassword(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.message, '', {
          timeOut: 3000
        })
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.errors.email) {
          this.toastr.error(err.error.errors.email[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

}
