<app-navbar [page]="'ADD MENU'"></app-navbar>

<div class="menus">
  <div class="container">
    <div class="text-right">
      <span class="button save" (click)="addMenuData()">Save</span>
    </div>
    <p *ngIf="link" class="mb-0"><b>Link:</b></p>
    <p *ngIf="link"><b>{{link}}</b></p>


    <a *ngIf="link" href="/restaurant_digital_menu/digital_pdf/{{restaurant?.digital_menu?.id}}">Digital menu link</a>


    <div class="file" *ngIf="m_id">
      <div class="f_in">+ Add Logo</div>
      <input type="file" #melog (change)="addMenuLogo(melog.files[0])">
    </div>
    <div class="view" *ngIf="logo_path">
      <a href="{{logo_path}}" target="_blank">View Current Logo</a>
      <span class="button delete" (click)="removeMenuLogo()"  style="display:inline-block; margin-left: 20px;">
        Remove Logo Image</span>
    </div>

    <div class="file" *ngIf="m_id">
      <div class="f_in">+ Add Menu Background</div>
      <input type="file" #meback (change)="addMenuBackground(meback.files[0])">
    </div>
    <div class="view" *ngIf="background_path">
      <a href="{{background_path}}" target="_blank">View Current Background</a>
      <span class="button delete" (click)="removeMenuBackground()"  style="display:inline-block; margin-left: 20px;">
        Remove Background Image</span>
    </div>

    <div class="color">
      <label>Title and Subtitle Color</label>
      <div class="box">
        <input
          type="color"
          [(ngModel)]="restaurant.digital_menu.title_and_subtitle_color"
          name="title_and_subtitle_color"
          id="title_and_subtitle_color"
        />
        <div
          class="left"
          [style.background-color]="restaurant.digital_menu.title_and_subtitle_color"
        ></div>
        <div class="right">
          <span>{{ restaurant.digital_menu.title_and_subtitle_color }}</span>
        </div>
      </div>
    </div>

    <div class="color">
      <label>Section Title Color</label>
      <div class="box">
        <input
          type="color"
          [(ngModel)]="restaurant.digital_menu.section_title_color"
          name="section_title_color"
        />
        <div
          class="left"
          [style.background-color]="restaurant.digital_menu.section_title_color"
        ></div>
        <div class="right">
          <span>{{ restaurant.digital_menu.section_title_color }}</span>
        </div>
      </div>
    </div>

    <div class="color">
      <label>Item Name And Desc Color</label>
      <div class="box">
        <input
          type="color"
          [(ngModel)]="restaurant.digital_menu.item_name_and_desc_color"
          name="item_name_and_desc_color"
        />
        <div
          class="left"
          [style.background-color]="restaurant.digital_menu.item_name_and_desc_color"
        ></div>
        <div class="right">
          <span>{{ restaurant.digital_menu.item_name_and_desc_color }}</span>
        </div>
      </div>
    </div>

    <div class="color">
      <label>Selected Category Color</label>
      <div class="box">
        <input
          type="color"
          [(ngModel)]="restaurant.digital_menu.selected_category_color"
          name="selected_category_color"
        />
        <div
          class="left"
          [style.background-color]="restaurant.digital_menu.selected_category_color"
        ></div>
        <div class="right">
          <span>{{ restaurant.digital_menu.selected_category_color }}</span>
        </div>
      </div>
    </div>

    <h2>Categories</h2>

    <ul *ngFor="let category of categories; let ind = index">
      <li id="{{category.id}}"><input type="checkbox" id="category_selected_{{category.id}}" name="category_selection" (change)="deselectOtherCategories($event.target, 'category_selected_'+category.id, category.name, category.subtitle, category.sort)"> {{category.name}} <div  style="display: inline;" *ngIf="category.subtitle">- {{category.subtitle}} </div> <div style="display: inline;" *ngIf="category.sort">- {{category.sort}}</div> <span (click)="removeCategory(category.id)" class="close">&times;</span></li>
    </ul>
    <br>
    <input type="text" name="category" id="category" placeholder="Name">&nbsp;
    <input type="text" name="category_subtitle" id="category_subtitle" placeholder="Subtitle">&nbsp;
    <input type="number" name="category_sort" id="category_sort" placeholder="Sort">&nbsp;
    <button (click)=addCategory()>Add Category</button>

    <br><br><br>

    <div class="section_head">
      <ng-container *ngFor="let s of sections; let sec_ind = index">
        <span class="button" [class.active]="section == 'section' + sec_ind"
          (click)="chooseSection('section' + sec_ind)">{{s.name || 'Section ' + (sec_ind + 1)}}</span>
      </ng-container>
      <span class="button add" (click)="addSection()">+ Add Section</span>
    </div>

    <div class="row">
      <!-- <div class="col-lg-3 col-md-2"></div> -->
      <div class="col-lg-6 col-md-8">
        <div class="menu">
          <!-- <form #meForm="ngForm" (ngSubmit)="addMenuData(meForm.value)"> -->

          <!-- Start Sections -->
          <div class="sections">

            <!-- <h5>Add Section</h5> -->
            <ng-container *ngFor="let s of sections; let sec_ind = index">
              <div class="sec" *ngIf="section == 'section' + sec_ind">
                <h6>Section {{sec_ind+1}}</h6>
                <!-- <div class="txt">
                  <label>Category</label>
                  <input type="" placeholder="Category" class="txt_in" #sec_category
                    (keyup)="addSectionData(sec_ind,'category',sec_category.value, sec_category.id)"
                    [(ngModel)]="s.category" [ngModelOptions]="{standalone: true}"
                    style="width: 80px;">
                </div> -->
                <label>Category</label>
                <select name="categories" class="txt_in" #categories_list
                (change)="addSectionData(sec_ind,'category',$event.target.value)"
                [(ngModel)]="s.category_id" [ngModelOptions]="{standalone: true}"
                style="width: 80px;">
                <option id="" value="">All</option>
                  <option *ngFor="let category of categories; let cat_ind = index" id="{{category.id}}" value="{{category.id}}">{{category.name}}</option>
                </select>
                <div class="txt">
                  <label>Sort</label>
                  <input type="number" placeholder="Sort" class="txt_in" #sec_sort
                    (keyup)="addSectionData(sec_ind,'sort',sec_sort.value)"
                    [(ngModel)]="s.sort" [ngModelOptions]="{standalone: true}"
                    style="width: 80px;">
                </div>
                <div class="txt">
                  <label>Name</label>
                  <input type="text" placeholder="Name" class="txt_in" #sec_name
                    (keyup)="addSectionData(sec_ind,'name',sec_name.value)" [(ngModel)]="s.name"
                    [ngModelOptions]="{standalone: true}">
                </div>
                <div class="txt">
                  <label>Description</label>
                  <input type="text" placeholder="Description" class="txt_in" #sec_desc
                    (keyup)="addSectionData(sec_ind,'desc',sec_desc.value)" [(ngModel)]="s.description"
                    [ngModelOptions]="{standalone: true}">
                </div>

                <div class="txt">
                  <label>Name Arabic</label>
                  <input type="text" placeholder="Name Arabic" class="txt_in" #sec_name_ar
                    (keyup)="addSectionData(sec_ind,'name_ar',sec_name_ar.value)" [(ngModel)]="s.name_ar"
                    [ngModelOptions]="{standalone: true}">
                </div>
                <div class="txt">
                  <label>Description Arabic</label>
                  <input type="text" placeholder="Description Arabic" class="txt_in" #sec_desc_ar
                    (keyup)="addSectionData(sec_ind,'desc_ar',sec_desc_ar.value)" [(ngModel)]="s.description_ar"
                    [ngModelOptions]="{standalone: true}">
                </div>

                <div class="txt" *ngIf="s.id">
                  <label>Section Image</label>
                  <input type="file" class="txt_in" #sec_img (change)="addSectionImage(sec_img.files[0],s.id)">
                  <a href="{{s.image_path}}" target="_blank" *ngIf="s.image_path">Current Image</a>
                  <!-- <span class="button delete" (click)="removeSectionImage(s.id)" style="display:inline-block; margin-left: 2px;" *ngIf="s.image_path">
                    Remove Item Image</span> -->
                </div>

                <!-- Start Sec Items -->

                <div class="sec_items">
                  <h5>Add Section Item</h5>
                  <div class="item" *ngFor="let sItem of s.digital_menu_section_items; let item_ind = index">
                    <div class="item_head" id="headingOne{{item_ind}}" data-toggle="collapse"
                      [attr.data-target]="'#collapseOne' + item_ind" aria-expanded="false"
                      [attr.aria-controls]="'collapseOne' + item_ind">
                      <h6>{{sItem.name || 'Item ' + (item_ind + 1)}}</h6>
                      <i class="fa fa-chevron-right"></i>
                    </div>
                    <div class="txt">
                      <label>Sort</label>
                      <input type="number" placeholder="Sort" class="txt_in" #sec_it_sort
                        (keyup)="addSectionItemData(sec_ind,item_ind,'sort',sec_it_sort.value)"
                        [(ngModel)]="sItem.sort" [ngModelOptions]="{standalone: true}"
                        style="width: 80px;">
                    </div>
                    <div class="item_body collapse" id="collapseOne{{item_ind}}">
                      <div class="txt">
                        <label>Name</label>
                        <input type="text" placeholder="Name" class="txt_in" #sec_it_name
                          (keyup)="addSectionItemData(sec_ind,item_ind,'name',sec_it_name.value)"
                          [(ngModel)]="sItem.name" [ngModelOptions]="{standalone: true}">
                      </div>
                      <div class="txt">
                        <label>Description</label>
                        <input type="text" placeholder="Description" class="txt_in" #sec_it_desc
                          (keyup)="addSectionItemData(sec_ind,item_ind,'desc',sec_it_desc.value)"
                          [(ngModel)]="sItem.description" [ngModelOptions]="{standalone: true}">
                      </div>

                      <div class="txt">
                        <label>Name Arabic</label>
                        <input type="text" placeholder="Name Arabic" class="txt_in" #sec_it_name_ar
                          (keyup)="addSectionItemData(sec_ind,item_ind,'name_ar',sec_it_name_ar.value)"
                          [(ngModel)]="sItem.name_ar" [ngModelOptions]="{standalone: true}">
                      </div>
                      <div class="txt">
                        <label>Description Arabic</label>
                        <input type="text" placeholder="Description Arabic" class="txt_in" #sec_it_desc_ar
                          (keyup)="addSectionItemData(sec_ind,item_ind,'desc_ar',sec_it_desc_ar.value)"
                          [(ngModel)]="sItem.description_ar" [ngModelOptions]="{standalone: true}">
                      </div>

                      <div class="txt">
                        <label>Price</label>
                        <input type="number" placeholder="Price" class="txt_in" #sec_it_price
                          (keyup)="addSectionItemData(sec_ind,item_ind,'price',sec_it_price.value)"
                          [(ngModel)]="sItem.price" [ngModelOptions]="{standalone: true}">
                      </div>
                      <div class="txt">
                        <label>Size,Price</label>
                        <textarea type="text" placeholder="Size,Price" class="txt_in" #sec_it_size_price
                          (keyup)="addSectionItemData(sec_ind,item_ind,'size_price',sec_it_size_price.value)"
                          [(ngModel)]="sItem.size_price" [ngModelOptions]="{standalone: true}"></textarea>
                      </div>
                      <div class="txt">
                        <label>Available</label>
                        <input type="checkbox" #sec_it_av
                          (change)="addSectionItemData(sec_ind,item_ind,'available',sec_it_av.value)"
                          [value]="sItem.available" [checked]="sItem.available == 1">
                      </div>

                      <div class="item_head" id="headingOptions{{item_ind}}" data-toggle="collapse"
                        [attr.data-target]="'#collapseOptions' + item_ind" aria-expanded="false"
                        [attr.aria-controls]="'collapseOptions' + item_ind">
                        <h6>Options</h6>
                        <i class="fa fa-chevron-right"></i>
                      </div>

                      <div class="item_body collapse" id="collapseOptions{{item_ind}}">
                        <div class="txt">
                          <label>On Offer</label>
                          <input type="checkbox" #sec_it_offer
                            (change)="addSectionItemData(sec_ind,item_ind,'on_offer',sec_it_offer.value)"
                            [value]="sItem.on_offer" [checked]="sItem.on_offer == 1">
                        </div>
                        <div class="txt">
                          <label>Old Price</label>
                          <input type="number" placeholder="Old Price" class="txt_in" #sec_it_oprice
                            (keyup)="addSectionItemData(sec_ind,item_ind,'old_price',sec_it_oprice.value)"
                            [(ngModel)]="sItem.old_price" [ngModelOptions]="{standalone: true}"
                            [disabled]="!sItem.on_offer">
                        </div>
                        <div class="txt">
                          <label>New Price</label>
                          <input type="number" placeholder="New Price" class="txt_in" #sec_it_nprice
                            (keyup)="addSectionItemData(sec_ind,item_ind,'new_price',sec_it_nprice.value)"
                            [(ngModel)]="sItem.new_price" [ngModelOptions]="{standalone: true}"
                            [disabled]="!sItem.on_offer">
                        </div>
                        <div class="txt">
                          <label>Has half portion</label>
                          <input type="checkbox" #sec_it_half
                            (change)="addSectionItemData(sec_ind,item_ind,'has_half_portion',sec_it_half.value)"
                            [value]="sItem.has_half_portion" [checked]="sItem.has_half_portion == 1">
                        </div>
                        <div class="txt">
                          <label>Half portion price</label>
                          <input type="number" placeholder="Half portion price" class="txt_in"
                            (keyup)="addSectionItemData(sec_ind,item_ind,'half_portion_price',sec_it_half_price.value)"
                            #sec_it_half_price [(ngModel)]="sItem.half_portion_price"
                            [ngModelOptions]="{standalone: true}" [disabled]="!sItem.has_half_portion">
                        </div>
                        <div class="txt">
                          <label>Half portion old price</label>
                          <input type="number" placeholder="Half portion old price" class="txt_in" #sec_it_half_oprice
                            (keyup)="addSectionItemData(sec_ind,item_ind,'half_portion_old_price',sec_it_half_oprice.value)"
                            [(ngModel)]="sItem.half_portion_old_price" [ngModelOptions]="{standalone: true}"
                            [disabled]="!sItem.on_offer || !sItem.has_half_portion">
                        </div>
                        <div class="txt">
                          <label>Half portion new price</label>
                          <input type="number" placeholder="Half portion new price" class="txt_in" #sec_it_half_nprice
                            (keyup)="addSectionItemData(sec_ind,item_ind,'half_portion_new_price',sec_it_half_nprice.value)"
                            [(ngModel)]="sItem.half_portion_new_price" [ngModelOptions]="{standalone: true}"
                            [disabled]="!sItem.on_offer || !sItem.has_half_portion">
                        </div>
                        <div class="txt" *ngIf="sItem.id">
                          <label>Item Image</label>
                          <input type="file" class="txt_in" #sec_it_img
                            (change)="addSectionItemImage(sec_it_img.files[0],sItem.id)">
                          <a href="{{sItem.image_path}}" target="_blank" *ngIf="sItem.image_path">Current
                            Image</a>
                            <span class="button delete" (click)="removeItemImage(sItem.id)" style="display:inline-block; margin-left: 2px;" *ngIf="sItem.image_path">
                              Remove Item Image</span>
                        </div>
                        <div class="txt" *ngIf="sItem.id">
                          <label>Item Alert Image</label>
                          <input type="file" class="txt_in" #sec_it_alert_img
                            (change)="addSectionItemAlertImage(sec_it_alert_img.files[0],sItem.id)">
                          <a href="{{sItem.alert_image_path}}" target="_blank" *ngIf="sItem.alert_image_path">Current Alert
                            Image </a>
                            <span class="button delete" (click)="removeItemAlertImage(sItem.id)" style="display:inline-block; margin-left: 2px;">
                              Remove Alert Image</span>

                        </div>

                        <!-- Start Mod -->
                        <div class="mod_items">
                          <h5>Add Section Item Modifier</h5>
                          <div class="item"
                            *ngFor="let mItem of sItem.digital_menu_section_item_modifiers; let mod_item_ind = index">
                            <h6>Modifier {{mod_item_ind+1}}</h6>
                            <div class="txt">
                              <label>Name</label>
                              <input type="text" placeholder="Name" class="txt_in" #mod_sec_it_name
                                (keyup)="addSectionItemModData(sec_ind,item_ind,mod_item_ind,'name',mod_sec_it_name.value)"
                                [(ngModel)]="mItem.name" [ngModelOptions]="{standalone: true}">
                            </div>
                            <div class="txt">
                              <label>Price</label>
                              <input type="number" placeholder="Price" class="txt_in" #mod_sec_it_price
                                (keyup)="addSectionItemModData(sec_ind,item_ind,mod_item_ind,'price',mod_sec_it_price.value)"
                                [(ngModel)]="mItem.price" [ngModelOptions]="{standalone: true}">
                            </div>

                            <span class="button delete" (click)="removeSectionItemMod(sec_ind,item_ind,mod_item_ind)">
                              Remove Ons</span>
                          </div>

                          <span class="button addm" (click)="addSectionItemMod(sec_ind,item_ind)">
                            Add Ons
                          </span>
                        </div>
                        <!-- End Mod -->
                      </div>

                      <span class="button delete" (click)="removeSectionItem(sec_ind,item_ind)">Remove
                        Item</span>
                    </div>
                  </div>

                  <span class="button addq" (click)="addSectionItem(sec_ind)">+ Item</span>
                </div>

                <span class="button delete" (click)="removeSection(sec_ind)">Remove Section</span>
                <!-- End Sec Items -->
              </div>
            </ng-container>

            <!-- End Section -->
          </div>

          <!-- </form> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-2"></div>
    </div>
  </div>
</div>
