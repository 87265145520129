<app-navbar [page]="'ADD USER'"></app-navbar>
<div class="login">
    <div class="container"><div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <div class="log">
                <h4>Users</h4>
                <table>
                    <tr>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                    <tr *ngFor="let user of users">
                      <td>{{user.email}}</td>
                      <td>{{user.role}}</td>
                      <td *ngIf="user.suspended"><button class="btn btn-primary" style="color: blue;" (click)="suspendUser(user.id, 0)">Unsuspend</button></td>
                      <td *ngIf="!user.suspended"><button class="btn btn-primary" style="color: red;" (click)="suspendUser(user.id, 1)">Suspend</button></td>
                    </tr>

                  </table>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
    <hr>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="log">
                    <h4>Add User</h4>
                    <form #welForm="ngForm" (ngSubmit)="addUser(welForm.value)">
                        <div class="txt">
                            <label>Username</label>
                            <input type="text" class="txt_in" ngModel name="name" required>
                        </div>
                        <div class="txt">
                            <label>Email</label>
                            <input type="email" class="txt_in" ngModel name="email" required>
                        </div>
                        <button class="btn btn-primary" [disabled]="!welForm.valid">Add User</button>
                    </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
        <hr>

        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="log">
                    <h4>Resend Verifivation Email</h4>
                    <form #reForm="ngForm" (ngSubmit)="resendEmail(reForm.value)">
                        <div class="txt">
                            <label>Email</label>
                            <input type="email" class="txt_in" ngModel name="email" required>
                        </div>
                        <button class="btn btn-primary" [disabled]="!reForm.valid">Send</button>
                    </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>

        <hr>

        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="log">
                    <h4>Change User Password</h4>
                    <form #chForm="ngForm" (ngSubmit)="changeUserPassword(chForm.value)">
                        <div class="txt">
                            <label>Email</label>
                            <input type="email" class="txt_in" ngModel name="email" required>
                        </div>
                        <div class="txt">
                            <label>Password</label>
                            <input type="password" class="txt_in" ngModel name="new_password" required>
                        </div>
                        <div class="txt">
                            <label>Confirm Password</label>
                            <input type="password" class="txt_in" ngModel name="password_confirmation" required>
                        </div>
                        <button class="btn btn-primary" [disabled]="!chForm.valid">Change</button>
                    </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>

    </div>
</div>