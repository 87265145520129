<div class="home">
  <div class="head">
    <h1>Home</h1>
  </div>
  <div class="body">
    <h2>Offers</h2>
    <div class="items">
      <div
        class="item"
        *ngFor="let offer of offers"
        routerLink="/order/menu/item/{{ offer.id }}"
      >
        <div
          class="pic"
          [style.background-image]="'url(' + offer.image_path + ')'"
        ></div>
        <div class="con">
          <h6>{{ offer.name }}</h6>
          <span>{{ offer.price }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
