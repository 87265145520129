import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order-notifications',
  templateUrl: './order-notifications.component.html',
  styleUrls: ['./order-notifications.component.scss'],
})
export class OrderNotificationsComponent implements OnInit {
  menu: any;
  id: any;

  constructor(
    private spinner: NgxSpinnerService,
    private foodcourtPro: FoodCourtProService,
    private location: Location
  ) {
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }
  getMenu(id) {
    this.spinner.show();
    this.foodcourtPro.getUserDigitalMenu(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.menu = res;
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
}
