<app-navbar [page]="'RESTAURANTS'"></app-navbar>

<div class="rests">
    <div class="container">
        <div class="text-right mb-3">
            <button class="btn btn-primary" routerLink="/base/foodcourts/restaurants/create">Add Restaurant</button>
        </div>

        <div class="items">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let r of restaurants">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h5 class="card-title">{{r.name}}</h5>
                            <p class="card-text">{{r.description}}</p>
                            <div class="text-center">
                                <div class="row">
                                    <div class="col-6">
                                        <button class="btn btn-success"
                                            routerLink="/base/foodcourts/restaurants/menus/{{r.id}}">Menu</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-info"
                                            routerLink="/base/foodcourts/restaurants/orders/{{r.id}}">Orders</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-primary"
                                            routerLink="/base/foodcourts/restaurants/edit/{{r.id}}">Edit</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-danger" (click)="deleteRestaurant(r.id)">Delete</button>
                                    </div>

                                    <div class="col-6">
                                        <button class="btn btn-secondary"
                                            routerLink="/base/foodcourts/restaurants/history/{{r.id}}">History</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>