<div class="ordering">
  <div class="or_content">
    <router-outlet></router-outlet>
  </div>
  <div class="or_tabs text-center">
    <div class="container">
      <div class="row">
        <div
          class="item"
          routerLink="/order/home/{{ id }}"
          routerLinkActive="active"
        >
          <img src="assets/imgs/icons/home.svg" />
          <p>Home</p>
          jkkjadsk
        </div>
        <div
          class="item"
          routerLink="/order/notifications"
          routerLinkActive="active"
        >
          <img src="assets/imgs/icons/notification.svg" />
          <p>Notification</p>
        </div>
        <div class="itemb">
          <div class="pic">
            <img src="{{ menu?.logo_path }}" width="100%" height="auto" />
          </div>
        </div>
        <div class="item" routerLink="/order/menu" routerLinkActive="active">
          <img src="assets/imgs/icons/DineInIcons/menu.png" />
          <p>Menu</p>
        </div>
        <div class="item" routerLink="/order/order" routerLinkActive="active">
          <img src="assets/imgs/icons/order.svg" />
          <p>Order</p>
        </div>
      </div>
    </div>

    <div class="call_btn" (click)="showCallWaiter('callW')">
      <p>
        <img src="assets/imgs/icons/DineInIcons/receptionbell.svg" /> Call
        <br />
        Waiter
      </p>
    </div>
    <div class="call_body" id="callW">
      <div class="text-right">
        <span (click)="hideCallWaiter('callW')" class="c"
          >X
          <!-- <img src="../../../assets/imgs/icons/DineInIcons/Ellipse.svg"> -->
        </span>
      </div>
      <button (click)="callWaiter()">
        <img src="assets/imgs/icons/DineInIcons/food.svg" /> Call Waiter
      </button>
      <button (click)="requestReceipt()">
        <img src="assets/imgs/icons/DineInIcons/receipt.svg" /> Request Receipt
      </button>
    </div>

    <div class="total_btn" (click)="showCallWaiter('totalB')">
      <span>Bill Total</span>
      <p>EGP {{ total }}</p>
    </div>
    <div class="total_body" id="totalB">
      <div class="text-right">
        <span (click)="hideCallWaiter('totalB')" class="c">X</span>
      </div>
      <div class="item" *ngFor="let item of items; let i = index">
        <div
          class="pic"
          [style.background-image]="
            'url(' + item.digital_menu_section_item.image_path + ')'
          "
        ></div>
        <div class="con">
          <h6>{{ item.digital_menu_section_item.name }}</h6>
          <h6>({{ item.quantity }})</h6>
        </div>
        <div class="count">
          <span class="fa fa-minus inc" (click)="removeItem(i)"></span>
          <span class="fa fa-plus inc" (click)="addItem(i)"></span>
        </div>
        <h5>EGP {{ item.digital_menu_section_item.price }}</h5>
      </div>

      <div class="t">
        <h4>
          <span>Bill Total</span> <br />
          EGP {{ total }}
        </h4>
      </div>
    </div>
  </div>
</div>
<span (click)="getItems()" id="foot_or_btn"></span>
<span (click)="getOrderId()" id="foot_or_id"></span>
