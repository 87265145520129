import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';
import { MatDialog } from '@angular/material/dialog';
import { ChoosePaymentPopupComponent } from '../choose-payment-popup/choose-payment-popup.component';

@Component({
  selector: 'app-order-order',
  templateUrl: './order-order.component.html',
  styleUrls: ['./order-order.component.scss'],
})
export class OrderOrderComponent implements OnInit {
  items: any;
  total: any = 0;
  pass_code: any;
  table_number: any;
  status: any = 'check_table';
  order_id: any;

  id: any;
  timer: any;
  seconds: any = 0;
  minutes: any = 0;
  order_status: any;

  c_order: string = 'cart';
  cur_order: any;
  cur_order_id: any;
  cur_total: any;
  order_code: any;
  message: any;
  interval: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private foodcourtPro: FoodCourtProService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.order_id = localStorage.getItem('order_id');
    this.getItems();
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  ngOnInit(): void {
    // if (this.id) {
    //   this.getMenu(this.id);
    //   this.interval = setInterval(() => {
    //     this.getMenu(this.id);
    //     this.showOrder('order');
    //   }, 35000);
    // }
  }

  getMenu(id) {
    this.spinner.show();
    this.foodcourtPro.getUserDigitalMenu(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.message = res.message;
        // this.cur_order_id = res.fc_active_order[0].id;
        // this.cur_order = res.fc_active_order[0].fc_order_items;
        this.cur_order = res.fc_active_order;
        // this.cur_total = res.fc_active_order[0].total;
        // this.order_status = res.fc_active_order[0].status;
        this.timer = res.fc_active_order.map((order) => {
          if (order.timer) {
            const arrTimer = order.timer.split(':');
            return { minutes: arrTimer[0], seconds: arrTimer[1] };
          }

          return { minutes: 0, seconds: 0 };
        });

        if (this.timer) {
          // let ti = this.timer.split(':');
          this.timer = this.timer.map((timer) => {
            return {
              minutes: parseInt(timer.minutes) * 60 * 1000,
              seconds: parseInt(timer.seconds) * 1000,
            };
          });
          // let min = parseInt(ti[0]) * 60 * 1000;
          // let sec = parseInt(ti[1]) * 1000;
          // let x = min + sec;
          let x = this.timer.map((tim, idx) => ({
            [idx]: tim.minutes + tim.seconds,
          }));

          setInterval(() => {
            this.timer.forEach((tim, idx) => {
              if (x[idx][idx] > 0) {
                x[idx][idx] = x[idx][idx] - 1000;
                tim.seconds = Math.floor((x[idx][idx] % (1000 * 60)) / 1000);
                tim.minutes = Math.floor(
                  (x[idx][idx] % (1000 * 60 * 60)) / (1000 * 60)
                );
              }
            });
          }, 1000);
        }
        console.log('timer', this.timer);
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  // checkTable(table_number) {
  //   this.table_number = table_number;
  //   let res_id = localStorage.getItem('res_id');
  //   this.spinner.show();
  //   this.foodcourtPro.checkTable(res_id, table_number)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.spinner.hide();
  //       this.toastr.success(res.Message, '', {
  //         timeOut: 3000
  //       })
  //       if (res.value) {
  //         this.status = 'empty_table';
  //       } else {
  //         this.status = 'check_pass';
  //       }
  //     }, err => {
  //       console.log(err);
  //       this.spinner.hide();
  //     })
  // }

  // checkPassCode(pass_code) {
  //   this.pass_code = pass_code;
  //   let res_id = localStorage.getItem('res_id');
  //   this.spinner.show();
  //   this.foodcourtPro.checkPassCode(res_id, this.table_number, pass_code)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.spinner.hide();
  //       if (!res.value) {
  //         this.toastr.error(res.Message, '', {
  //           timeOut: 3000
  //         })
  //       } else {
  //         this.toastr.success(res.Message, '', {
  //           timeOut: 3000
  //         })
  //       }

  //       if (res.value) {
  //         this.addOrder(pass_code);
  //       }
  //     }, err => {
  //       console.log(err);
  //       this.spinner.hide();
  //     })
  // }

  submitOrder() {}

  addOrder() {
    let d_id = localStorage.getItem('device_id');
    let res_id = localStorage.getItem('res_id');
    this.items.forEach((element) => {
      element.fc_digital_menu_section_item_modifiers =
        element.fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers;
    });
    let data = {
      fc_restaurant_id: res_id,
      user_hash: d_id,
      fc_order_items: this.items,
    };

    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(ChoosePaymentPopupComponent, {
        width: '400px',
        // height: '80vh',
        panelClass: 'sliP',
        data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getItems();
      });
    } else {
      const dialogRef = this.dialog.open(ChoosePaymentPopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getItems();
      });
    }

    // this.spinner.show();
    // this.foodcourtPro.addOrder(data)
    //   .subscribe((res: any) => {
    //     console.log(res);
    //     this.spinner.hide();
    //     // this.order_id = res.Order[0].id;
    //     // localStorage.setItem('order_id', res.Order[0].id);
    //     // localStorage.setItem('t_all_order', JSON.stringify(res.Order[0]));
    //     this.toastr.success('Order placed successfully!', '', {
    //       timeOut: 3000
    //     })
    //     // this.router.navigate(['/order/confirmed']);
    //     // res.Order[0].order_items.forEach(element => {
    //     //   element.fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers = element.order_item_modifiers;
    //     //   element.fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers.price = element.fc_digital_menu_section_item_modifier.price;
    //     // });
    //     // localStorage.setItem('t_order', JSON.stringify(res.Order[0].order_items));
    //     // this.updateOrder();

    //   }, err => {
    //     console.log(err);
    //     this.spinner.hide();
    //     if (err.error.Message) {
    //       this.toastr.error(err.error.Message, '', {
    //         timeOut: 3000
    //       })
    //     } else if (err.error.message) {
    //       this.toastr.error(err.error.message, '', {
    //         timeOut: 3000
    //       })
    //     } else {
    //       this.toastr.error('Something went wrong!', '', {
    //         timeOut: 3000
    //       })
    //     }
    //   })
  }

  // editOrder() {
  //   // let d_id = localStorage.getItem('device_id');
  //   // let res_id = localStorage.getItem('res_id');
  //   // let or_id = localStorage.getItem('order_id');
  //   this.items.forEach(element => {
  //     element.fc_digital_menu_section_item_modifiers = element.fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers;
  //   });

  //   let data = JSON.parse(localStorage.getItem('t_all_order'));
  //   console.log(data);

  //   // let data = or_d;
  //   data.order_items = this.items;
  //   // let data = {
  //   //   id: or_id,
  //   //   restaurant_id: res_id,
  //   //   user_hash: d_id,
  //   //   order_items: this.items
  //   // }
  //   this.spinner.show();
  //   this.foodcourtPro.editOrder(data)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.spinner.hide();
  //       // localStorage.setItem('order_id', res.Order[0].id);
  //       // localStorage.setItem('t_order', res.Order[0].order_items);
  //       this.updateOrder();
  //       this.toastr.success('Order placed successfully!', '', {
  //         timeOut: 3000
  //       })
  //       this.router.navigate(['/order/confirmed'])
  //     }, err => {
  //       console.log(err);
  //       this.spinner.hide();
  //       if (err.error.Message) {
  //         this.toastr.error(err.error.Message, '', {
  //           timeOut: 3000
  //         })
  //       } else if (err.error.message) {
  //         this.toastr.error(err.error.message, '', {
  //           timeOut: 3000
  //         })
  //       } else {
  //         this.toastr.error('Something went wrong!', '', {
  //           timeOut: 3000
  //         })
  //       }
  //     })
  // }

  updateOrder() {
    let profile = document.querySelector('#foot_or_id') as HTMLElement;
    profile.click();
  }

  getItems() {
    let its = JSON.parse(localStorage.getItem('t_order'));
    console.log(its);

    if (its) {
      this.items = its;
      this.getTotal(this.items);
    } else {
      this.items = [];
    }
    console.log(this.items);
  }

  getTotal(items) {
    this.total = 0;
    if (items) {
      items.forEach((element) => {
        for (let index = 0; index < element.quantity; index++) {
          this.total = this.total + element.fc_digital_menu_section_item.price;
        }
        for (
          let mod_index = 0;
          mod_index <
          element.fc_digital_menu_section_item
            .fc_digital_menu_section_item_modifiers.length;
          mod_index++
        ) {
          if (
            element.fc_digital_menu_section_item
              .fc_digital_menu_section_item_modifiers[mod_index].quantity
          ) {
            for (
              let mod_q_ind = 0;
              mod_q_ind <
              element.fc_digital_menu_section_item
                .fc_digital_menu_section_item_modifiers[mod_index].quantity;
              mod_q_ind++
            ) {
              this.total =
                this.total +
                element.fc_digital_menu_section_item
                  .fc_digital_menu_section_item_modifiers[mod_index].price;
            }
          }
        }
      });
    }
  }

  hasMod(item_ind) {
    if (
      this.items[item_ind].fc_digital_menu_section_item
        .fc_digital_menu_section_item_modifiers
    ) {
      for (
        let index = 0;
        index <
        this.items[item_ind].fc_digital_menu_section_item
          .fc_digital_menu_section_item_modifiers.length;
        index++
      ) {
        const element = this.items[item_ind].fc_digital_menu_section_item
          .fc_digital_menu_section_item_modifiers[index];
        if (element.quantity > 0) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  ohasMod(item_ind, or_ind) {
    if (
      this.cur_order[or_ind].fc_order_items[item_ind]
        .fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers
    ) {
      for (
        let index = 0;
        index <
        this.cur_order[or_ind].fc_order_items[item_ind]
          .fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers
          .length;
        index++
      ) {
        const element = this.cur_order[or_ind].fc_order_items[item_ind]
          .fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers[
          index
        ];
        if (element.quantity > 0) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  removeItem(item_ind) {
    this.items.splice(item_ind, 1);
    localStorage.setItem('t_order', JSON.stringify(this.items));
    this.getTotal(this.items);
    this.updateCart();
  }

  updateCart() {
    let profile = document.querySelector('#foot_or_btn') as HTMLElement;
    profile.click();
  }

  showOrder(val) {
    this.c_order = val;
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  retrieveOrder(code) {
    this.spinner.show();
    this.foodcourtPro.retrieveOrder(code).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.cur_order = res;
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
}
