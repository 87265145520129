import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService) { }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    this.spinner.show();
    this.auth.getUserDetails()
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.user = res;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  changePassword(data) {
    this.spinner.show();
    this.auth.changeUserPassword(data)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Password Changed Successfully!', '', {
          timeOut: 3000
        })
        this.router.navigate(['/login']);
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.errors.new_password) {
          this.toastr.error(err.error.errors.new_password[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.old_password) {
          this.toastr.error(err.error.errors.old_password[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

}
