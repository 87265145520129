import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()

export class InterceptorProvider {

  token;
  constructor(
    public http: HttpClient) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('login')) {
      // req = req.clone({ setHeaders: { 'Content-Type': `application/json` } });
      return next.handle(req).pipe(map(event => {
        if (event instanceof HttpResponse) {
          // console.log(event);

          if (event.body.Token) {
            const token = event.body.Token;
            // console.log('token in interceptor ,login: ', token)
            localStorage.setItem('touchless', token);
          }
          if (event.body.user.role) {
            localStorage.setItem('touchless_role', event.body.user.role);
          }
          if (event.body.user.available_qrcode_types) {
            localStorage.setItem('available_types', event.body.user.available_qrcode_types);
          }
          return event;
        }
      }))
    }
    else {
      // console.log('token in int: ', this.token);
      const token = localStorage.getItem('touchless');
      if (token) {
        // console.log('from get storage in interceptor: ', token);
        let authorizedReq = req.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } });
        return next.handle(authorizedReq).pipe(
          tap(event => {

          }, err => {
            if (err.status == 401) {
              // localStorage.clear();
              localStorage.removeItem('touchless');
              localStorage.removeItem('touchless_role');
              localStorage.removeItem('available_types');
              localStorage.removeItem('touch_compare');
              location.reload();
            }
          })
        );
      } else {
        return next.handle(req).pipe(
          tap(event => {

          }, err => {
            if (err.status == 401) {
              localStorage.removeItem('touchless');
              localStorage.removeItem('touchless_role');
              localStorage.removeItem('available_types');
              localStorage.removeItem('touch_compare');
              location.reload();
            }
          })
        );
      }
    }//else
  } //else
}

