<div class="home">
    <div class="head">
        <h1>Notifications</h1>
    </div>
    <div class="body">
        <div class="item">
            <h4>Your Order is {{order_status}}!</h4>
            <!-- <h6>Please pick up your order now</h6> -->
            <!-- <button>Check Order</button> -->
        </div>

        <!-- <div class="item">
            <h6>Your Order has been canceled</h6>
            <p>
                Due to some issues your order has been canceled, feel free to order again
            </p>
            <button>Order Now</button>
        </div> -->

        <div class="item">
            <h6>Thank you for Joining Touch-Less Mori</h6>
            <button routerLink="/mori/menu">Order Now</button>
        </div>

    </div>
</div>