<app-navbar [page]="'SURVEY ANALYTICS'"></app-navbar>
<div class="survey">
    <div class="container">
        <div class="questions">
            <ng-container *ngFor="let q of questions">
                <div class="row">
                    <div class="col-md-8 col-sm-12">
                        <h4>{{q.title}}</h4>
                    </div>
                    <div class="col-md-4 col-sm-12 text-right">
                        <span *ngIf="q.type == 'radio'" (click)="getAnswersChart(q.id)">Chart</span>
                        <span (click)="getAnswers(q.id)">Answers</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="text-right">
            <span class="d" (click)="onDown()"><img src="assets/imgs/download.svg" width="10">XLS</span>
        </div>
        <hr>
        <!-- <div class="answers text-center" *ngIf="answers" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
            [scrollWindow]="true" (scrolled)="onScroll($event)">

            <div class="row">
                <div class="col-md-3">
                    <p><b>Email</b></p>
                </div>
                <div class="col-md-3">
                    <p><b>Phone</b></p>
                </div>
                <div class="col-md-3">
                    <p><b>Answer</b></p>
                </div>
                <div class="col-md-3">
                    <p><b>Time</b></p>
                </div>
            </div>

            <div class="row" *ngFor="let a of answers">
                <div class="col-md-3">
                    <p>{{a.user_email}}</p>
                </div>
                <div class="col-md-3">
                    <p>{{a.user_phone}}</p>
                </div>
                <div class="col-md-3">
                    <p>{{a.answer}}</p>
                </div>
                <div class="col-md-3">
                    <p>{{a.updated_at}}</p>
                </div>
            </div>
        </div> -->

        <div class="answers">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="user_email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element?.user_email}} </td>
                    </ng-container>

                    <!-- Phone Column -->
                    <ng-container matColumnDef="user_phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                        <td mat-cell *matCellDef="let element"> {{element?.user_phone}} </td>
                    </ng-container>

                    <!-- Answer Column -->
                    <ng-container matColumnDef="answer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Answer </th>
                        <td mat-cell *matCellDef="let element"> {{element?.answer}} </td>
                    </ng-container>

                    <!-- Symbol Time -->
                    <ng-container matColumnDef="updated_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
                        <td mat-cell *matCellDef="let element"> {{element?.updated_at}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator #paginator [pageSizeOptions]="[20]" [length]="resTotal" showFirstLastButtons (page)="pageChanged($event)"></mat-paginator>
            </div>
        </div>

    </div>
</div>
