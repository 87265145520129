<div class="addQr">
    <div class="container-fluid">
        <div class="buttons text-right mb-3" *ngIf="id">
            <button class="btn btn-primary" routerLink="/base/addqr">Add New Qr Code</button>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="choose">
                    <div class="row">
                        <div class="col-sm-4">
                            <button (click)="chooseType('template')" [class.active]="type == 'template'"
                                [disabled]="id && type != 'template'">Create
                                Template</button>
                        </div>
                        <div class="col-sm-4">
                            <button (click)="chooseType('link')" [class.active]="type == 'link'"
                                [disabled]="id && type != 'link'">Create Link</button>
                        </div>
                        <div class="col-sm-4">
                            <button (click)="chooseType('file')" [class.active]="type == 'file'"
                                [disabled]="id && type != 'file'">Create File</button>
                        </div>
                    </div>
                </div>

                <div class="forms">
                    <div class="item" *ngIf="type == 'template'">
                        <form #addM="ngForm" (ngSubmit)="addMenu(addM.value, men.files[0], log.files[0])">

                            <div class="form-group">
                                <label>File</label>
                                <input type="file" name="file" class="form-control" #men>
                                <a href="{{tfile_path}}" target="_blank" *ngIf="id && tfile_path">View Current File</a>
                            </div>

                            <!-- <div class="form-group">
                                <label>Link</label>
                                <input type="text" [(ngModel)]="tlink" name="link" class="form-control">
                            </div> -->

                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" [(ngModel)]="tname" name="name" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Logo</label>
                                <input type="file" name="logo" class="form-control" #log>
                                <a href="{{tlogo_path}}" target="_blank" *ngIf="id && tlogo_path">View Current File</a>
                            </div>

                            <div class="form-group">
                                <label>Color</label>
                                <input type="color" [(ngModel)]="tcolor" name="color" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Background Color</label>
                                <input type="color" [(ngModel)]="tbackground_color" name="background_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Size</label>
                                <select [(ngModel)]="tsize" name="size" class="form-control">
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    <option value="600">600</option>
                                    <option value="800">800</option>
                                    <option value="1000">1000</option>
                                    <option value="1200">1200</option>
                                    <option value="1400">1400</option>
                                    <option value="1600">1600</option>
                                    <option value="1800">1800</option>
                                    <option value="2000">2000</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Style</label>
                                <select class="form-control" [(ngModel)]="tstyle" name="style">
                                    <option value="square">Square</option>
                                    <option value="dot">Dot</option>
                                    <option value="round">Round</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Eye Style</label>
                                <select class="form-control" [(ngModel)]="teye_style" name="eye_style">
                                    <option value="square">Square</option>
                                    <option value="circle">Circle</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Gradient Start Color</label>
                                <input type="color" [(ngModel)]="tgradient_start_color" name="gradient_start_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Gradient End Color</label>
                                <input type="color" [(ngModel)]="tgradient_end_color" name="gradient_end_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Gradient Type</label>
                                <select class="form-control" [(ngModel)]="tgradient_type" name="gradient_type">
                                    <option value="vertical">Vertical</option>
                                    <option value="horizontal">Horizontal</option>
                                    <option value="diagonal">Diagonal</option>
                                    <option value="inverse_diagonal">Inverse Diagonal</option>
                                    <option value="radial">Radial</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Eye Color One Inner</label>
                                <input type="color" [(ngModel)]="teye_color_0_in" name="eye_color_0_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color One Outer</label>
                                <input type="color" [(ngModel)]="teye_color_0_out" name="eye_color_0_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Two Inner</label>
                                <input type="color" [(ngModel)]="teye_color_1_in" name="eye_color_1_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Two Outer</label>
                                <input type="color" [(ngModel)]="teye_color_1_out" name="eye_color_1_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Three Inner</label>
                                <input type="color" [(ngModel)]="teye_color_2_in" name="eye_color_2_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Three Outer</label>
                                <input type="color" [(ngModel)]="teye_color_2_out" name="eye_color_2_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Facebook</label>
                                <input type="text" [(ngModel)]="tfacebook" name="facebook" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Instagram</label>
                                <input type="text" [(ngModel)]="tinstagram" name="instagram" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Survey</label>
                                <input type="text" [(ngModel)]="tsurvey" name="survey" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" [(ngModel)]="twebsite" name="website" class="form-control">
                            </div>

                            <button class="btn btn-primary">Add QR Code</button>
                        </form>
                    </div>

                    <div class="item" *ngIf="type == 'link'">
                        <form #addLin="ngForm" (ngSubmit)="addMenu(addLin.value, null, log.files[0])">

                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" [(ngModel)]="lname" name="name" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Logo</label>
                                <input type="file" name="logo" class="form-control" #log>
                                <a href="{{llogo_path}}" target="_blank" *ngIf="id && llogo_path">View Current File</a>
                            </div>

                            <div class="form-group">
                                <label>Link</label>
                                <input type="text" [(ngModel)]="llink" name="link" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Color</label>
                                <input type="color" [(ngModel)]="lcolor" name="color" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Background Color</label>
                                <input type="color" [(ngModel)]="lbackground_color" name="background_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Size</label>
                                <select [(ngModel)]="lsize" name="size" class="form-control">
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    <option value="600">600</option>
                                    <option value="800">800</option>
                                    <option value="1000">1000</option>
                                    <option value="1200">1200</option>
                                    <option value="1400">1400</option>
                                    <option value="1600">1600</option>
                                    <option value="1800">1800</option>
                                    <option value="2000">2000</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Style</label>
                                <select class="form-control" [(ngModel)]="lstyle" name="style">
                                    <option value="square">Square</option>
                                    <option value="dot">Dot</option>
                                    <option value="round">Round</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Eye Style</label>
                                <select class="form-control" [(ngModel)]="leye_style" name="eye_style">
                                    <option value="square">Square</option>
                                    <option value="circle">Circle</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Gradient Start Color</label>
                                <input type="color" [(ngModel)]="lgradient_start_color" name="gradient_start_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Gradient End Color</label>
                                <input type="color" [(ngModel)]="lgradient_end_color" name="gradient_end_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Gradient Type</label>
                                <select class="form-control" [(ngModel)]="lgradient_type" name="gradient_type">
                                    <option value="vertical">Vertical</option>
                                    <option value="horizontal">Horizontal</option>
                                    <option value="diagonal">Diagonal</option>
                                    <option value="inverse_diagonal">Inverse Diagonal</option>
                                    <option value="radial">Radial</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Eye Color One Inner</label>
                                <input type="color" [(ngModel)]="leye_color_0_in" name="eye_color_0_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color One Outer</label>
                                <input type="color" [(ngModel)]="leye_color_0_out" name="eye_color_0_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Two Inner</label>
                                <input type="color" [(ngModel)]="leye_color_1_in" name="eye_color_1_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Two Outer</label>
                                <input type="color" [(ngModel)]="leye_color_1_out" name="eye_color_1_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Three Inner</label>
                                <input type="color" [(ngModel)]="leye_color_2_in" name="eye_color_2_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Three Outer</label>
                                <input type="color" [(ngModel)]="leye_color_2_out" name="eye_color_2_out"
                                    class="form-control">
                            </div>

                            <button class="btn btn-primary">Add QR Code</button>
                        </form>
                    </div>

                    <div class="item" *ngIf="type == 'file'">
                        <form #addFi="ngForm" (ngSubmit)="addMenu(addFi.value, men.files[0], log.files[0])">

                            <div class="form-group">
                                <label>File</label>
                                <input type="file" name="file" class="form-control" #men>
                                <a href="{{ffile_path}}" target="_blank" *ngIf="id && ffile_path">View Current File</a>
                            </div>

                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" [(ngModel)]="fname" name="name" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Logo</label>
                                <input type="file" name="logo" class="form-control" #log>
                                <a href="{{flogo_path}}" target="_blank" *ngIf="id && flogo_path">View Current File</a>
                            </div>

                            <div class="form-group">
                                <label>Color</label>
                                <input type="color" [(ngModel)]="fcolor" name="color" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Background Color</label>
                                <input type="color" [(ngModel)]="fbackground_color" name="background_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Size</label>
                                <select [(ngModel)]="fsize" name="size" class="form-control">
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    <option value="600">600</option>
                                    <option value="800">800</option>
                                    <option value="1000">1000</option>
                                    <option value="1200">1200</option>
                                    <option value="1400">1400</option>
                                    <option value="1600">1600</option>
                                    <option value="1800">1800</option>
                                    <option value="2000">2000</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Style</label>
                                <select class="form-control" [(ngModel)]="fstyle" name="style">
                                    <option value="square">Square</option>
                                    <option value="dot">Dot</option>
                                    <option value="round">Round</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Eye Style</label>
                                <select class="form-control" [(ngModel)]="feye_style" name="eye_style">
                                    <option value="square">Square</option>
                                    <option value="circle">Circle</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Gradient Start Color</label>
                                <input type="color" [(ngModel)]="fgradient_start_color" name="gradient_start_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Gradient End Color</label>
                                <input type="color" [(ngModel)]="fgradient_end_color" name="gradient_end_color"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Gradient Type</label>
                                <select class="form-control" [(ngModel)]="fgradient_type" name="gradient_type">
                                    <option value="vertical">Vertical</option>
                                    <option value="horizontal">Horizontal</option>
                                    <option value="diagonal">Diagonal</option>
                                    <option value="inverse_diagonal">Inverse Diagonal</option>
                                    <option value="radial">Radial</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Eye Color One Inner</label>
                                <input type="color" [(ngModel)]="feye_color_0_in" name="eye_color_0_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color One Outer</label>
                                <input type="color" [(ngModel)]="feye_color_0_out" name="eye_color_0_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Two Inner</label>
                                <input type="color" [(ngModel)]="feye_color_1_in" name="eye_color_1_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Two Outer</label>
                                <input type="color" [(ngModel)]="feye_color_1_out" name="eye_color_1_out"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Three Inner</label>
                                <input type="color" [(ngModel)]="feye_color_2_in" name="eye_color_2_in"
                                    class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Eye Color Three Outer</label>
                                <input type="color" [(ngModel)]="feye_color_2_out" name="eye_color_2_out"
                                    class="form-control">
                            </div>

                            <button class="btn btn-primary">Add QR Code</button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div *ngIf="id" class="preview">
                    <img *ngIf="qr" src="{{qr}}?rand={{rand}}" width="100%" height="auto" class="q">
                </div>
            </div>
        </div>

    </div>
</div>