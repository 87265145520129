<app-navbar [page]="'ORDERS'"></app-navbar>

<audio
  id="jingle_bell"
  src="assets/imgs/bell.mp3"
  hidden=""
  controls="false"
  #audioplayer
>
  <p>Audio is not supported in your browser.</p>
</audio>
<mat-tab-group
  mat-align-tabs="start"
  (selectedTabChange)="handleTabChange($event)"
  [selectedIndex]="tabIndex"
>
  <mat-tab label="Dine In">
    <div
      class="orders"
      infinite-scroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="true"
      (scrolled)="onScroll($event)"
    >
      <div class="container" *ngIf="orders?.length">
        <h1>All Orders</h1>
        <div class="order_table text-center">
          <div class="table_head">
            <div class="row">
              <div class="col cell borderleft" *ngIf="validate(orders)">
                <h4>Restaurant</h4>
              </div>
              <div class="col cell">
                <h4>Table</h4>
              </div>
              <div class="col cell">
                <h4>Order</h4>
              </div>
              <div class="col cell">
                <h4>Price</h4>
              </div>
              <div class="col cell">
                <h4>Waiter</h4>
              </div>
              <div class="col cell">
                <h4>Receipt</h4>
              </div>
              <div *ngIf="orders[0].seated_at" class="col cell">
                <h4>Seated At</h4>
              </div>
              <div class="col cell">
                <h4>Status</h4>
              </div>
              <div class="col cell">
                <h4>Action</h4>
              </div>
            </div>
          </div>
          <div class="table_body">
            <div class="row" *ngFor="let order of orders; let or_ind = index">
              <div class="col cell borderleft">
                {{ order.restaurant_name }}
              </div>
              <div class="col cell" *ngIf="order.table_number">
                <a
                  [routerLink]="['/base/restaurants/table_orders']"
                  [queryParams]="{
                    rest_id: order.restaurant_id,
                    table_id: order.table_number
                  }"
                  >{{ order.table_number }}</a
                >
              </div>
              <div class="col cell">
                <button
                  class="check-order-btn"
                  routerLink="/base/restaurants/order-items/{{ order.id }}"
                >
                  Check Order
                </button>
              </div>
              <div class="col cell">
                <p>{{ order.total }} EGP</p>
              </div>
              <div
                class="col cell"
                [class.active]="order.call_waiter"
                (click)="callWaiterOff(or_ind, order.id)"
              ></div>
              <div
                class="col cell"
                [class.active]="order.ask_for_check"
                (click)="requestReceiptOff(or_ind, order.id)"
              ></div>
              <div *ngIf="order.seated_at" class="col cell">
                <p>{{ order.seated_at }}</p>
              </div>
              <div class="col cell">
                <select
                  (change)="changeOrderStatus(or_status.value, order.id)"
                  [(ngModel)]="order.status"
                  [ngModelOptions]="{ standalone: true }"
                  #or_status
                >
                  <option value="Queued">Queued</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Pending">Pending</option>
                  <option value="Cooking">Cooking</option>
                  <option value="Ready_To_Serve">Ready to serve</option>
                  <option value="Served">Served</option>
                  <option value="Paid">Done</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div class="col cell">
                <button class="cancel" (click)="cancelOrder(order.id)">
                  Cancel Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="!orders?.length">
        <p>no orders yet</p>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Pick Up">
    <div
      class="orders"
      infinite-scroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="true"
      (scrolled)="onScroll($event)"
    >
      <div class="container" *ngIf="orders?.length">
        <h1>All Orders</h1>
        <div class="order_table text-center">
          <div class="table_head">
            <div class="row">
              <div class="col cell borderleft" *ngIf="validate(orders)">
                <h4>Restaurant</h4>
              </div>
              <div class="col cell">
                <h4>Table</h4>
              </div>
              <div class="col cell">
                <h4>Order</h4>
              </div>
              <div class="col cell">
                <h4>Price</h4>
              </div>
              <div class="col cell">
                <h4>Pick-up Time</h4>
              </div>
              <div class="col cell">
                <h4>Status</h4>
              </div>
              <div class="col cell">
                <h4>Action</h4>
              </div>
            </div>
          </div>
          <div class="table_body">
            <div class="row" *ngFor="let order of orders; let or_ind = index">
              <div class="col cell borderleft">
                {{ order.restaurant_name }}
              </div>
              <div class="col cell" *ngIf="order.table_number">
                <a
                  [routerLink]="['/base/restaurants/table_orders']"
                  [queryParams]="{
                    rest_id: order.restaurant_id,
                    table_id: order.table_number
                  }"
                  >{{ order.table_number }}</a
                >
              </div>
              <div class="col cell">
                <button
                  class="check-order-btn"
                  routerLink="/base/restaurants/order-items/{{ order.id }}"
                >
                  Check Order
                </button>
              </div>
              <div class="col cell">
                <p>{{ order.total }} EGP</p>
              </div>

              <div class="col cell">
                {{ order.pickup_time }}
              </div>

              <div class="col cell">
                <select
                  (change)="changeOrderStatus(or_status.value, order.id)"
                  [(ngModel)]="order.status"
                  [ngModelOptions]="{ standalone: true }"
                  #or_status
                >
                  <option value="Queued">Queued</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Pending">Pending</option>
                  <option value="Cooking">Cooking</option>
                  <option value="Ready_To_Serve">Ready to serve</option>
                  <option value="Served">Served</option>
                  <option value="Paid">Done</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div class="col cell">
                <button class="cancel" (click)="cancelOrder(order.id)">
                  Cancel Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="!orders?.length">
        <p>no orders yet</p>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Delivery">
    <div
      class="orders"
      infinite-scroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="true"
      (scrolled)="onScroll($event)"
    >
      <div class="container" *ngIf="orders?.length">
        <h1>All Orders</h1>
        <div class="order_table text-center">
          <div class="table_head">
            <div class="row">
              <div class="col cell borderleft" *ngIf="validate(orders)">
                <h4>Restaurant</h4>
              </div>
              <div class="col cell">
                <h4>Table</h4>
              </div>
              <div class="col cell">
                <h4>Order</h4>
              </div>
              <div class="col cell">
                <h4>Price</h4>
              </div>
              <div class="col cell">
                <h4>Phone Number</h4>
              </div>
              <div class="col cell">
                <h4>Address</h4>
              </div>
              <div class="col cell">
                <h4>Status</h4>
              </div>
              <div class="col cell">
                <h4>Action</h4>
              </div>
            </div>
          </div>
          <div class="table_body">
            <div class="row" *ngFor="let order of orders; let or_ind = index">
              <div class="col cell borderleft">
                {{ order.restaurant_name }}
              </div>
              <div class="col cell" *ngIf="order.table_number">
                <a
                  [routerLink]="['/base/restaurants/table_orders']"
                  [queryParams]="{
                    rest_id: order.restaurant_id,
                    table_id: order.table_number
                  }"
                  >{{ order.table_number }}</a
                >
              </div>
              <div class="col cell">
                <button
                  class="check-order-btn"
                  routerLink="/base/restaurants/order-items/{{ order.id }}"
                >
                  Check Order
                </button>
              </div>
              <div class="col cell">
                <p>{{ order.total }} EGP</p>
              </div>
              <div class="col cell">
                <p>{{ order.phone_number }}</p>
              </div>
              <div class="col cell">
                <p class="address">{{ order.address }}</p>
              </div>
              <div class="col cell">
                <select
                  (change)="changeOrderStatus(or_status.value, order.id)"
                  [(ngModel)]="order.status"
                  [ngModelOptions]="{ standalone: true }"
                  #or_status
                >
                  <option value="Queued">Queued</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Pending">Pending</option>
                  <option value="Cooking">Cooking</option>
                  <option value="Ready_To_Serve">Ready to serve</option>
                  <option value="Served">Served</option>
                  <option value="Paid">Done</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div class="col cell">
                <button class="cancel" (click)="cancelOrder(order.id)">
                  Cancel Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="!orders?.length">
        <p>no orders yet</p>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
