<div class="base">
  <mat-sidenav-container class="example-container" (backdropClick)="close()">
    <img
      src="assets/imgs/menu_blue.png"
      (click)="sidenav.open()"
      class="menu d-lg-none"
    />
    <mat-sidenav
      class="d-lg-none"
      #sidenav
      (keydown.escape)="close()"
      disableClose
    >
      <img
        src="assets/imgs/menu_blue.png"
        (click)="sidenav.close()"
        class="menu d-lg-none"
      />
      <app-side-menu></app-side-menu>
    </mat-sidenav>

    <div class="row">
      <div class="col-lg-2 d-lg-block d-none">
        <app-side-menu></app-side-menu>
      </div>
      <div class="col-lg-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-container>
</div>
