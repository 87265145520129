import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { QrCodesProService } from '../qr-codes-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  public surveyGroup: FormGroup;
  questions: FormArray;
  answers: FormArray;
  selectValues = [];
  error = false;

  constructor(private qrCodeService: QrCodesProService, private spinner: NgxSpinnerService, private toast: ToastrService) { }

  ngOnInit() {
    this.initalizeSurveyGroup();

  }

  initalizeSurveyGroup() {
    this.surveyGroup = new FormGroup({
      type: new FormControl('survey'),
      name: new FormControl(null, Validators.required),
      questions: new FormArray([
        this.createQuestion(),
      ]),
    });
    this.selectValues = [
      {
        value: 'text',
        display: 'Text'
      },
      {
        value: 'radio',
        display: 'Radio'
      },
      // {
      //   value: 'select',
      //   display: 'select'
      // }
    ];
  }

  getQuestions(form) {
    return form.controls.questions.controls;
  }

  getAnswers(form) {
    return form.controls.answers.controls;
  }

  createQuestion() {
    return new FormGroup({
      title: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      answers: new FormArray([
        this.createAnswer(),
      ]),
    });
  }

  createAnswer() {
    return new FormGroup({
      answer: new FormControl(null),
    });
  }


  addQuestion(): void {
    this.questions = this.surveyGroup.get('questions') as FormArray;
    this.questions.push(this.createQuestion());
  }

  addAnswer(index) {
    const control = this.surveyGroup.get('questions')['controls'][index].get('answers') as FormArray;
    control.push(this.createAnswer());
  }

  deleteQuestion(index) {
    this.questions = this.surveyGroup.get('questions') as FormArray;
    if (this.questions.length > 1) {
      this.questions.removeAt(index);
    }
  }

  deleteAnswer(index, group) {
    const control = this.surveyGroup.get('questions')['controls'][index].get('answers') as FormArray;
    control.removeAt(group);
  }

  createSurvey() {
    const survey = this.surveyGroup.get('questions').value;
    for (const item of survey) {
      if (item.type === 'radio' || item.type === 'select') {
        let data = '';
        for (let i = 0; i < item.answers.length; i++) {
          if (item.answers[i]['answer'] === 'empty') {
            this.error = true;
          } else {
            data += item.answers[i]['answer'] + '@';
          }
        }
        item.answers = data;
      } else if (item.type === 'text') {
        item.answers = '';
      }
    }
    if (!this.error) {
      this.spinner.show();
      this.qrCodeService.createSurvey('survey', this.surveyGroup.value.name,
        this.surveyGroup.value.questions).subscribe(res => {
          this.spinner.hide();
          this.surveyGroup.reset();
          console.log(this.surveyGroup.value);
          this.toast.success('Survey Created Successfully!', '', {
            timeOut: 3000
          });
        }, err => {
          this.spinner.hide();
          this.toast.error('Something went wrong!', '', {
            timeOut: 3000
          });
          console.log(err);
        });
    }
  }

}
