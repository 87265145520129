import { Component, OnInit } from '@angular/core';
import { FoodCourtProService } from '../food-court-pro.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-food-court-admin',
  templateUrl: './add-food-court-admin.component.html',
  styleUrls: ['./add-food-court-admin.component.scss'],
})
export class AddFoodCourtAdminComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService
  ) {}

  ngOnInit(): void {}

  addFoodCourtAdmin(data) {
    this.spinner.show();
    this.foodcourtPro.addFoodCourtAdmin(data).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('FoodCourt admin added successfully!', '', {
          timeOut: 3000,
        });
        window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }
}
