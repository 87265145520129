<div class="header">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h6>{{page}}</h6>
            </div>
            <div class="col-6">
                <div class="icons text-right">
                    <span>{{user?.user?.name}}</span>
                    <img src="assets/imgs/user.svg" routerLink="/base/profile">
                </div>
            </div>
        </div>
    </div>
</div>