<div class="home">
    <div class="head">
        <h1>Menu</h1>
    </div>
    <div class="body">
        <div class="tabs_head text-center">
            <div class="tab_item offers" [class.active]="section == 'sectionO'" *ngIf="offers?.length != 0"
                (click)="showSection('sectionO')">Offers</div>
            <div class="tab_item" *ngFor="let m of menu; let i = index" [class.active]="section == 'section' + i"
                (click)="showSection('section' + i)">{{m.name}}</div>
        </div>

        <ng-container *ngFor="let m of menu; let i = index">
            <div class="items" *ngIf="section == 'section' + i">
                <div class="item" *ngFor="let item of m.digital_menu_section_items"
                    (click)="itemDetailsPage(item.available, item.id)">
                    <div class="pic" [style.background-image]="'url(' + item.image_path + ')'"></div>
                    <div class="con">
                        <h6>{{item.name}}</h6>
                        <span class="p">EGP {{item.price}}</span>
                        <div class="count">
                            <!-- <span class="fa fa-minus inc"></span>
                            <span class="num">1</span> -->
                            <span class="fa fa-plus inc"></span>
                        </div>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="offers?.length != 0">
            <div class="items" *ngIf="section == 'sectionO'">
                <div class="item" *ngFor="let item of offers" routerLink="/mori/menu/item/{{item.id}}">
                    <div class="pic" [style.background-image]="'url(' + item.image_path + ')'"></div>
                    <div class="con">
                        <h6>{{item.name}}</h6>
                        <span class="p"> EGP </span>
                        <span class="p o"> {{item.old_price}} </span>
                        <span class="p"> {{item.new_price}} </span>
                        <div class="count">
                            <!-- <span class="fa fa-minus inc"></span>
                            <span class="num">1</span> -->
                            <span class="fa fa-plus inc"></span>
                        </div>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>