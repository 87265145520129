import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';

@Component({
  selector: 'app-dashboard-items-popup',
  templateUrl: './dashboard-items-popup.component.html',
  styleUrls: ['./dashboard-items-popup.component.scss']
})
export class DashboardItemsPopupComponent implements OnInit {

  menu: any;
  offers: any;
  section: any = 'section0';

  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private menuPro: MenuProService) { }

  ngOnInit(): void {
    this.getRestaurant();
  }

  getRestaurant() {
    this.menuPro.getRestaurant(this.data.id)
      .subscribe((res: any) => {
        console.log(res);
        this.menu = res.digital_menu.digital_menu_sections;
      }, err => {
        console.log(err);
      })
  }

  showSection(val) {
    this.section = val;
  }

  chooseItem(available, item) {
    if (available) {
      let d = {
        quantity: 1,
        digital_menu_section_item: item,
        order_item_modifiers: item.digital_menu_section_item_modifiers
      }
      // item.digital_menu_section_item = item;
      // item.order_item_modifiers = item.digital_menu_section_item_modifiers;
      // item.quantity = 1;
      d.order_item_modifiers.forEach(element => {
        element.quantity = 0;
      });
      this.dialogRef.close(d);
    } else {
      this.toastr.error('This item is not available now!', '', {
        timeOut: 3000
      })
    }
  }

}
