import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  baseURL: string = environment.url;
  errorMessage = '';

  constructor(private http: HttpClient) {}

  createRestaurantAdmin(admin): Observable<any> {
    return this.http
      .post(`${this.baseURL}api/add/restaurantadmin`, admin)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.errorMessage = error.error.message;
    } else {
      this.errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(this.errorMessage);
  }
}
