<ng-container *ngIf="type == 'template'">
  <div class="menu">
    <div class="content">
      <img
        *ngIf="isPdf != 'pdf'"
        src="{{ file_path }}"
        width="100%"
        height="auto"
      />
      <pdf-viewer
        *ngIf="isPdf == 'pdf'"
        [src]="file_path"
        [original-size]="true"
        [fit-to-page]="true"
        [render-text]="true"
        [autoresize]="true"
        (after-load-complete)="callBackFn($event)"
        style="display: block"
      >
      </pdf-viewer>
    </div>

    <div class="b_home" (click)="goBack()" *ngIf="back">HOME</div>
  </div>

  <div class="side">
    <div class="logo" id="s_logo" (click)="show('s_logo', 'i_logo')">
      <img src="{{ logo_path }}" width="50" height="50" />
    </div>
    <div class="icons text-center" id="i_logo">
      <a href="{{ getLink(instagram) }}" target="_blank" *ngIf="instagram"
        ><img src="assets/imgs/inst1.jpg" width="25" height="auto"
      /></a>
      <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook"
        ><img src="assets/imgs/face1.jpg" width="25" height="auto"
      /></a>
      <a href="{{ getLink(website) }}" target="_blank" *ngIf="website"
        ><img src="assets/imgs/site1.jpg" width="25" height="auto"
      /></a>
      <a href="{{ getLink(survey) }}" *ngIf="survey"
        ><img src="assets/imgs/sur1.jpg" width="25" height="auto"
      /></a>
      <a href="{{ getLink(service) }}" *ngIf="service"
        ><img src="assets/imgs/service.png" width="25" height="auto"
      /></a>
    </div>
  </div>
</ng-container>

<div class="spin" id="spin">
  <img class="loader-spinner" src="{{ logo_path }}" />
</div>

<ng-container *ngIf="type == 'cumulative'">
  <ng-container *ngIf="!design_type || design_type == 'default'">
    <app-cumulative-default [res]="res"></app-cumulative-default>
  </ng-container>

  <ng-container *ngIf="!design_type || design_type == 'default-white'">
    <app-cumulative-default [res]="res"></app-cumulative-default>
  </ng-container>

  <ng-container *ngIf="!design_type || design_type == 'art-town'">
    <app-cumulative-art-town [res]="res"></app-cumulative-art-town>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'theG'">
    <app-cumulative-theg [res]="res"></app-cumulative-theg>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'ixina'">
    <app-cumulative-ixina [res]="res"></app-cumulative-ixina>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'fresh_food'">
    <app-cumulative-fresh-food [res]="res"></app-cumulative-fresh-food>
  </ng-container>
  <ng-container *ngIf="design_type && design_type == 'gff'">
    <app-cumulative-gff [res]="res"></app-cumulative-gff>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'masa_special'">
    <app-cumulative-masa [res]="res"></app-cumulative-masa>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'chiringuito_special'">
    <app-cumulative-chiringutio [res]="res"></app-cumulative-chiringutio>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'water'">
    <app-cumulative-water [res]="res"></app-cumulative-water>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'watertemplate'">
    <app-cumulative-watertemplate [res]="res"></app-cumulative-watertemplate>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'xprs'">
    <app-cumulative-xprs [res]="res"></app-cumulative-xprs>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'water-single'">
    <app-cumulative-water-single [res]="res"></app-cumulative-water-single>
  </ng-container>

  <ng-container *ngIf="design_type && design_type == 'maf'">
    <app-cumulative-maf [res]="res"></app-cumulative-maf>
  </ng-container>

<ng-container *ngIf="design_type && design_type == 'sol'">
  <app-cumulative-sol [res]="res"></app-cumulative-sol>
</ng-container>

</ng-container>

<ng-container *ngIf="type == 'survey'">
  <div class="survey" [style.background-image]="'url(' + background_path + ')'">
    <div class="side" *ngIf="instagram || facebook || website || service">
      <div class="logo" id="s_logo" (click)="show('s_logo', 'i_logo')">
        <img src="{{ logo_path }}" width="50" height="50" />
      </div>
      <div class="icons text-center" id="i_logo">
        <a href="{{ getLink(instagram) }}" target="_blank" *ngIf="instagram"
          ><img src="assets/imgs/inst1.jpg" width="25" height="auto"
        /></a>
        <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook"
          ><img src="assets/imgs/face1.jpg" width="25" height="auto"
        /></a>
        <a href="{{ getLink(website) }}" target="_blank" *ngIf="website"
          ><img src="assets/imgs/site1.jpg" width="25" height="auto"
        /></a>
        <a href="{{ getLink(service) }}" *ngIf="service"
          ><img src="assets/imgs/service.png" width="25" height="auto"
        /></a>
      </div>
    </div>

    <div class="container">
      <div class="head">
        <div class="con">
          <h5>Customer Satisfaction Survey</h5>
          <p>Please answer the questions below</p>
        </div>
        <div class="pic" (click)="goBack()">
          <!-- <img *ngIf="logo_path" src="{{logo_path}}" width="100%"> -->
          <h2>Home</h2>
        </div>
      </div>

      <div
        id="carouselExampleControls"
        class="carousel slide carousel-fade"
        data-interval="false"
        data-wrap="false"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            *ngFor="let question of questions; let ques_index = index"
            [class.active]="ques_index == 0"
          >
            <div class="item" *ngIf="question.type == 'text'">
              <label class="q">{{ question.title }}</label>
              <input
                type="text"
                class="txt"
                (keyup)="getAnswers(tx_input.value, ques_index, question.id)"
                #tx_input
              />
            </div>
            <div class="item" *ngIf="question.type == 'radio'">
              <!-- <div class="overlay"></div> -->
              <label class="q">{{ question.title }}</label>
              <div
                class="fg"
                *ngFor="let answer of question.answers; let ans_index = index"
              >
                <input
                  type="radio"
                  name="answer{{ ques_index }}"
                  id="an{{ answer }}{{ ans_index }}{{ ques_index }}"
                  class="form-check-input r"
                  value="{{ answer }}"
                  (change)="getAnswers(answer, ques_index, question.id)"
                />
                <label
                  class="a"
                  for="an{{ answer }}{{ ans_index }}{{ ques_index }}"
                  >{{ answer }}</label
                >
              </div>
            </div>
            <div class="item" *ngIf="question.type == 'checkboxes'">
              <!-- <div class="overlay"></div> -->
              <label class="q">{{ question.title }}</label>
              <div
                class="fg"
                *ngFor="let answer of question.answers; let ans_index = index"
              >
                <input
                  type="checkbox"
                  name="answer{{ ques_index }}"
                  id="an{{ answer }}{{ ans_index }}{{ ques_index }}"
                  class="form-check-input r"
                  value="{{ answer }}"
                  (change)="getCheckboxesAnswers($event, answer, ques_index, question.id, ans_index)"
                />
                <label
                  class="a"
                  for="an{{ answer }}{{ ans_index }}{{ ques_index }}"
                  >{{ answer }}</label
                >
              </div>
            </div>
            <div class="item" *ngIf="question.type == 'stars'">
              <label class="q">{{ question.title }}</label>
              <div class="rate">
                <fieldset class="rating">
                  <input
                    type="radio"
                    (change)="getAnswers(5, ques_index, question.id)"
                    id="rate5{{ ques_index }}"
                    name="stars{{ ques_index }}"
                    value="5"
                  /><label
                    class="full"
                    for="rate5{{ ques_index }}"
                    title="5 Stars"
                  ></label>
                  <input
                    type="radio"
                    (change)="getAnswers(4, ques_index, question.id)"
                    id="rate4{{ ques_index }}"
                    name="stars{{ ques_index }}"
                    value="4"
                  /><label
                    class="full"
                    for="rate4{{ ques_index }}"
                    title="4 Stars"
                  ></label>
                  <input
                    type="radio"
                    (change)="getAnswers(3, ques_index, question.id)"
                    id="rate3{{ ques_index }}"
                    name="stars{{ ques_index }}"
                    value="3"
                  /><label
                    class="full"
                    for="rate3{{ ques_index }}"
                    title="3 Stars"
                  ></label>
                  <input
                    type="radio"
                    (change)="getAnswers(2, ques_index, question.id)"
                    id="rate2{{ ques_index }}"
                    name="stars{{ ques_index }}"
                    value="2"
                  /><label
                    class="full"
                    for="rate2{{ ques_index }}"
                    title="2 Stars"
                  ></label>
                  <input
                    type="radio"
                    (change)="getAnswers(1, ques_index, question.id)"
                    id="rate1{{ ques_index }}"
                    name="stars{{ ques_index }}"
                    value="1"
                  /><label
                    class="full"
                    for="rate1{{ ques_index }}"
                    title="1 Star"
                  ></label>
                </fieldset>
                <div class="cls"></div>
              </div>
            </div>

            <!-- <a href="#carouselExampleControls" role="button" data-slide="next">
                            <div class="item disabled" *ngIf="questions[ques_index+1]?.type == 'text'">
                                <div class="overlay"></div>
                                <label class="q">{{questions[ques_index+1]?.title}}</label>
                                <input type="text" class="txt" disabled>
                            </div>
                            <div class="item disabled" *ngIf="questions[ques_index+1]?.type == 'radio'">
                                <div class="overlay"></div>
                                <label class="q">{{questions[ques_index+1]?.title}}</label>
                                <div class="fg"
                                    *ngFor="let answer of questions[ques_index+1]?.answers; let ans_index = index">
                                    <input type="radio" name="answer{{ques_index}}" disabled
                                        id="an{{answer}}{{ans_index}}{{ques_index}}" class="form-check-input r"
                                        value="{{answer}}">
                                    <label class="a" for="an{{answer}}{{ans_index}}{{ques_index}}">{{answer}}</label>
                                </div>
                            </div>
                            <div class="item disabled" *ngIf="questions[ques_index+1]?.type == 'stars'">
                                <div class="overlay"></div>
                                <label class="q">{{questions[ques_index+1]?.title}}</label>
                                <div class="rate">
                                    <fieldset class="rating">
                                        <input type="radio" disabled
                                            (change)="getAnswers(5,ques_index,questions[ques_index+1]?.id)"
                                            id="ratee5{{ques_index}}" name="starss{{ques_index}}" value="5" /><label
                                            class="full" for="ratee5{{ques_index}}" title="5 Starss"></label>
                                        <input type="radio" disabled
                                            (change)="getAnswers(4,ques_index,questions[ques_index+1]?.id)"
                                            id="ratee4{{ques_index}}" name="starss{{ques_index}}" value="4" /><label
                                            class="full" for="ratee4{{ques_index}}" title="4 Starss"></label>
                                        <input type="radio" disabled
                                            (change)="getAnswers(3,ques_index,questions[ques_index+1]?.id)"
                                            id="ratee3{{ques_index}}" name="starss{{ques_index}}" value="3" /><label
                                            class="full" for="ratee3{{ques_index}}" title="3 Starss"></label>
                                        <input type="radio" disabled
                                            (change)="getAnswers(2,ques_index,questions[ques_index+1]?.id)"
                                            id="ratee2{{ques_index}}" name="starss{{ques_index}}" value="2" /><label
                                            class="full" for="ratee2{{ques_index}}" title="2 Starss"></label>
                                        <input type="radio" disabled
                                            (change)="getAnswers(1,ques_index,questions[ques_index+1]?.id)"
                                            id="ratee1{{ques_index}}" name="starss{{ques_index}}" value="1" /><label
                                            class="full" for="ratee1{{ques_index}}" title="1 Star"></label>
                                    </fieldset>
                                    <div class="cls"></div>
                                </div>
                            </div>
                        </a> -->

            <a
              class="back button"
              *ngIf="ques_index != 0"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
              >Back</a
            >
            <a
              class="next button"
              *ngIf="ques_index != questions?.length - 1"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
              >Next</a
            >
            <a
              class="next button"
              *ngIf="ques_index == questions?.length - 1"
              (click)="submitSurvey()"
              >Submit</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
