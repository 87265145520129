import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-survey-arabic',
  templateUrl: './static-survey-arabic.component.html',
  styleUrls: ['./static-survey-arabic.component.scss']
})
export class StaticSurveyArabicComponent implements OnInit {

  questions = [
    {type: 'text', name: 'name', label: 'ما هو إسمك؟', placeholder : 'برجاء كتابة الإسم كاملا', isHidden : false},
    {type: 'number', name: 'number', label: 'ما هو رقم هاتفك؟',
    placeholder : 'برجاء إضافة الرقم المحمول, ليصلك كل ما هو جديد', isHidden : true},
    {type: 'radio', name: 'quality', label: 'ما تقييمك لجوده الخدمه ؟', values: [
      {value: 1, display: 1},
      {value: 2, display: 2},
      {value: 3, display: 3},
      {value: 4, display: 4},
      {value: 5, display: 5},
    ], isHidden: true},
    {type: 'radio', name: 'clean', label: 'ما تقييمك لنظافه المكان و الاجراءات الوقائيه المتخذه ؟', values: [
      {value: 1, display: 1},
      {value: 2, display: 2},
      {value: 3, display: 3},
      {value: 4, display: 4},
      {value: 5, display: 5},
    ], isHidden: true},
    {type: 'text', name: 'comment', label: 'هل لديك اي استفسارات، تعليقات او اهتمامات ؟',
    placeholder : 'إكتب جوابك هنا', isHidden : true},

  ];

  surveyFinished = false;
  inputStr = '';

  constructor() { }

  ngOnInit(): void {
  }

  modelChange(str: string): void {
    this.inputStr = str;
  }

}
