import { Component, OnInit, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodesProService } from '../qr-codes-pro.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-chart-popup',
  templateUrl: './chart-popup.component.html',
  styleUrls: ['./chart-popup.component.scss']
})
export class ChartPopupComponent implements OnInit {

  // Pie Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabels: Array<any> = [];
  public pieChartData: any[] = [];
  // public lineChartColors: Array<any> = [{ backgroundColor: [] }];
  // public pieChartLabels = ['10%', '20%', '30%', '40%','50%'];
  public pieChartLegend = true;
  // public pieChartData = [30, 90, 120, 60, 40];
  public pieChartType = "pie";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private qrCodesPro: QrCodesProService) { }

  ngOnInit(): void {
    this.getAnswers();
  }

  getAnswers() {
    console.log(this.data);

    this.spinner.show();
    this.qrCodesPro.getSurveyAnswersChart(this.data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.pieChartData = res.total;
        this.pieChartLabels = res.answers;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
