import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() page: any;
  user: any;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    this.auth.getUserDetails()
      .subscribe(res => {
        console.log(res);
        this.user = res;
      }, err => {
        console.log(err);
      })
  }

}
