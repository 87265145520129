import { Component, OnInit, ViewChild } from '@angular/core';
import { QrCodesProService } from '../qr-codes-pro.service';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import {Router} from "@angular/router"

@Component({
  selector: 'app-edit-qr-code',
  templateUrl: './edit-qr-code.component.html',
  styleUrls: ['./edit-qr-code.component.scss'],
})
export class EditQrCodeComponent implements OnInit {
  groups: any;

  id: any;
  select: any = 'type';
  rand: any = Math.random();
  allData: any;
  type: any;
  template_attributes: any[] = [];
  email_attributes: any[] = [];
  wifi_attributes: any[] = [];
  phone_number_attributes: any[] = [];
  sms_attributes: any[] = [];
  geo_attributes: any[] = [];
  vcard_attributes: any[] = [];
  questions: any[] = [];
  cumulative_attributes: any[] = [
    { key: 'Qrcodes', value: [] },
    { key: 'Links', value: [] },
  ];
  cumulativeQrs: any[] = [];
  extra_images: any[] = [];
  qrDetails: any;
  qr: any;
  fileName: any;

  name: any;
  link: any;
  qrcode_group_id: any = '';
  design_type: any ;
  text_color: any;
  qrcode_user_id: any = '';

  facebook: any;
  instagram: any;
  website: any;
  survey: any;
  qrcodes_title: any;
  service: any;
  file_path: any;
  logo_path: any;
  background_path: any;
  file: any;

  email: any;
  subject: any;
  body: any;

  encryption: any = '';
  ssid: any;
  password: any;
  hidden: any;

  phone_number: any;
  message: any;

  first_name: any = '';
  last_name: any = '';
  address: any = '';
  mobile: any = '';
  title: any = '';
  organization: any = '';

  color: any;
  background_color: any;
  style: any;
  size: any = null;
  eye_style: any;
  gradient_start_color: any;
  gradient_end_color: any;
  gradient_type: any;
  eye_color_0_in: any = null;
  eye_color_0_out: any = null;
  eye_color_1_in: any = null;
  eye_color_1_out: any = null;
  eye_color_2_in: any = null;
  eye_color_2_out: any = null;
  whatsAppAttributes: any[] = [];
  // whatsAppURL: any = new URL('https://api.whatsapp.com/send');
  latitude: any;
  longitude: any;
  locationChosen = false;

  users: any;

  options = {
    types: [],
    componentRestrictions: { country: 'EG' },
  };

  available_types: any;
  file_name: any;

  constructor(
    private qrCodesPro: QrCodesProService,
    private _FileSaverService: FileSaverService,
    private _http: HttpClient,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.available_types = localStorage.getItem('available_types');
    this.route.params.subscribe((params) => {
      this.rand = Math.random();
      this.id = params.id;
      if (this.id) {
        this.getQrCodeDetails(this.id, 'setup');
      }
    });
  }

  ngOnInit(): void {
    this.getLocation();
    this.getQrGroups();
  }

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  public handleAddressChange(address: any) {
    // Do some stuff
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    console.log(this.longitude, this.latitude);
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.longitude = position.coords.longitude;
        this.latitude = position.coords.latitude;
        // console.log(longitude, latitude);
      });
    } else {
      console.log('No support for geolocation');
      this.latitude = 51.678418;
      this.longitude = 7.809007;
    }
  }

  onChoseLocation(event) {
    // this.latitude = event.coords.lat;
    // this.longitude = event.coords.lng;
    // this.locationChosen = true;
    // console.log(this.latitude, this.longitude);
  }

  changeSelect(val) {
    this.select = val;
  }

  chooseType(val) {
    this.type = val;
    this.select = 'setup';
  }

  addInfo(background, data, menu, logo) {
    console.log(data);

    this.template_attributes = [];
    this.email_attributes = [];
    this.wifi_attributes = [];
    if (menu) {
      data.file = menu;
    } else {
      if (this.type == 'template') {
        this.template_attributes.push(this.file);
      } else if (this.type == 'cumulative') {
        this.cumulative_attributes.push(this.file);
      }
    }
    if (logo) {
      data.logo = logo;
    }
    data.type = this.type;
    if (this.type == 'template') {
      if (data.facebook && data.facebook != '') {
        let d = { key: 'facebook', value: data.facebook };
        this.template_attributes.push(d);
      }
      if (data.qrcodes_title && data.qrcodes_title != '') {
        let d = { key: 'qrcodes_title', value: data.qrcodes_title };
        this.template_attributes.push(d);
      }
      if (data.instagram && data.instagram != '') {
        let d = { key: 'instagram', value: data.instagram };
        this.template_attributes.push(d);
      }
      if (data.survey && data.survey != '') {
        let d = { key: 'survey', value: data.survey };
        this.template_attributes.push(d);
      }
      if (data.service && data.service != '') {
        let d = { key: 'service', value: data.service };
        this.template_attributes.push(d);
      }
      if (data.website && data.website != '') {
        let d = { key: 'website', value: data.website };
        this.template_attributes.push(d);
      }
      delete data.facebook;
      delete data.instagram;
      delete data.survey;
      delete data.qrcodes_title;
      delete data.service;
      delete data.website;
      data.template_attributes = JSON.stringify(this.template_attributes);
    } else if (this.type == 'email') {
      if (data.email && data.email != '') {
        let d = { key: 'email', value: data.email };
        this.email_attributes.push(d);
      }
      if (data.subject && data.subject != '') {
        let d = { key: 'subject', value: data.subject };
        this.email_attributes.push(d);
      }
      if (data.body && data.body != '') {
        let d = { key: 'body', value: data.body };
        this.email_attributes.push(d);
      }
      delete data.email;
      delete data.subject;
      delete data.body;
      data.email_attributes = JSON.stringify(this.email_attributes);
    } else if (this.type == 'wifi') {
      if (data.encryption && data.encryption != '') {
        let d = { key: 'encryption', value: data.encryption };
        this.wifi_attributes.push(d);
      }
      if (data.ssid && data.ssid != '') {
        let d = { key: 'ssid', value: data.ssid };
        this.wifi_attributes.push(d);
      }
      if (data.password && data.password != '') {
        let d = { key: 'password', value: data.password };
        this.wifi_attributes.push(d);
      }
      if (data.hidden && data.hidden != '') {
        let d = { key: 'hidden', value: data.hidden };
        this.wifi_attributes.push(d);
      }
      delete data.encryption;
      delete data.ssid;
      delete data.password;
      delete data.hidden;
      data.wifi_attributes = JSON.stringify(this.wifi_attributes);
    } else if (this.type == 'phone_number') {
      if (data.phone_number && data.phone_number != '') {
        let d = { key: 'phone_number', value: data.phone_number };
        this.phone_number_attributes.push(d);
      }
      delete data.phone_number;
      data.phone_number_attributes = JSON.stringify(
        this.phone_number_attributes
      );
    } else if (this.type == 'sms') {
      if (data.phone_number && data.phone_number != '') {
        let d = { key: 'phone_number', value: data.phone_number };
        this.sms_attributes.push(d);
      }
      if (data.message && data.message != '') {
        let d = { key: 'message', value: data.message };
        this.sms_attributes.push(d);
      }
      delete data.phone_number;
      delete data.message;
      data.sms_attributes = JSON.stringify(this.sms_attributes);
    } else if (this.type == 'whatsapp') {
      const url = new URL('https://api.whatsapp.com/send');

      if (data.phone_number && data.phone_number != '') {
        let d = { key: 'phone_number', value: data.phone_number };
        this.whatsAppAttributes.push(d);
        url.searchParams.append('phone', data.phone_number);
      }
      if (data.message && data.message != '') {
        let d = { key: 'message', value: data.message };
        this.whatsAppAttributes.push(d);
        url.searchParams.append('text', data.message);
      }
      delete data.phone_number;
      delete data.message;

      data.link = url.href;
      data.whatsapp_attributes = JSON.stringify(this.whatsAppAttributes);
    } else if (this.type == 'geo') {
      if (this.latitude) {
        let d = { key: 'lat', value: this.latitude };
        this.geo_attributes.push(d);
      }
      if (this.longitude) {
        let d = { key: 'long', value: this.longitude };
        this.geo_attributes.push(d);
      }
      data.geo_attributes = JSON.stringify(this.geo_attributes);
    } else if (this.type == 'vcard') {
      if (data.first_name && data.first_name != '') {
        let d = { key: 'first_name', value: data.first_name };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'first_name', value: '' };
        this.vcard_attributes.push(d);
      }

      if (data.last_name && data.last_name != '') {
        let d = { key: 'last_name', value: data.last_name };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'last_name', value: '' };
        this.vcard_attributes.push(d);
      }

      if (data.address && data.address != '') {
        let d = { key: 'address', value: data.address };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'address', value: '' };
        this.vcard_attributes.push(d);
      }

      if (data.email && data.email != '') {
        let d = { key: 'email', value: data.email };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'email', value: '' };
        this.vcard_attributes.push(d);
      }

      if (data.mobile && data.mobile != '') {
        let d = { key: 'mobile', value: data.mobile };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'mobile', value: '' };
        this.vcard_attributes.push(d);
      }

      if (data.title && data.title != '') {
        let d = { key: 'title', value: data.title };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'title', value: '' };
        this.vcard_attributes.push(d);
      }

      if (data.organization && data.organization != '') {
        let d = { key: 'organization', value: data.organization };
        this.vcard_attributes.push(d);
      } else {
        let d = { key: 'organization', value: '' };
        this.vcard_attributes.push(d);
      }
      delete data.first_name;
      delete data.last_name;
      delete data.address;
      delete data.email;
      delete data.mobile;
      delete data.title;
      delete data.organization;
      data.vcard_attributes = JSON.stringify(this.vcard_attributes);
    } else if (this.type == 'survey') {
      if (data.facebook && data.facebook != '') {
        let d = { key: 'facebook', value: data.facebook };
        this.template_attributes.push(d);
      }
      if (data.qrcodes_title && data.qrcodes_title != '') {
        let d = { key: 'qrcodes_title', value: data.qrcodes_title };
        this.template_attributes.push(d);
      }
      if (data.instagram && data.instagram != '') {
        let d = { key: 'instagram', value: data.instagram };
        this.template_attributes.push(d);
      }
      if (data.service && data.service != '') {
        let d = { key: 'service', value: data.service };
        this.template_attributes.push(d);
      }
      if (data.website && data.website != '') {
        let d = { key: 'website', value: data.website };
        this.template_attributes.push(d);
      }
      delete data.facebook;
      delete data.instagram;
      delete data.survey;
      delete data.qrcodes_title;
      delete data.service;
      delete data.website;
      data.template_attributes = JSON.stringify(this.template_attributes);
      if (background) {
        data.background_image = background;
      }
      this.questions.forEach((element) => {
        if (element.type == 'radio' || element.type == 'checkboxes') {
          element.answers = element.answers.join('@');
        } else if (element.type == 'text') {
          element.answers = '';
        } else if (element.type == 'stars') {
          element.answers = '';
        }
      });
      data.questions = JSON.stringify(this.questions);
    } else if (this.type == 'cumulative') {
      if (background) {
        data.background_image = background;
      }
      let cuLinks = [];
      if (data.facebook && data.facebook != '') {
        let d = { key: 'facebook', value: data.facebook };
        cuLinks.push(d);
      }
      if (data.instagram && data.instagram != '') {
        let d = { key: 'instagram', value: data.instagram };
        cuLinks.push(d);
      }
      if (data.service && data.service != '') {
        let d = { key: 'service', value: data.service };
        cuLinks.push(d);
      }
      if (data.website && data.website != '') {
        let d = { key: 'website', value: data.website };
        cuLinks.push(d);
      }
      if (data.survey && data.survey != '') {
        let d = { key: 'survey', value: data.survey };
        cuLinks.push(d);
      }
      if (data.qrcodes_title && data.qrcodes_title != '') {
        let d = { key: 'qrcodes_title', value: data.qrcodes_title };
        cuLinks.push(d);
      }
      delete data.facebook;
      delete data.instagram;
      delete data.survey;
      delete data.qrcodes_title;
      delete data.website;
      delete data.survey;

      for (let index = 0; index < this.cumulativeQrs.length; index++) {
        const element = this.cumulativeQrs[index];
        data[`extra_image_${index + 1}`] = element.extra_picture;
        delete element.extra_picture;
      }

      let cumLis = JSON.stringify(cuLinks);
      this.cumulative_attributes[1].value = cumLis;
      let cumQrs = JSON.stringify(this.cumulativeQrs);
      this.cumulative_attributes[0].value = cumQrs;
      data.cumulative_attributes = JSON.stringify(this.cumulative_attributes);
    }

    if (this.eye_color_0_in && this.eye_color_0_out) {
      data.eye_color_0 = this.eye_color_0_in + ',' + this.eye_color_0_out;
    }
    if (this.eye_color_1_in && this.eye_color_1_out) {
      data.eye_color_1 = this.eye_color_1_in + ',' + this.eye_color_1_out;
    }
    if (this.eye_color_2_in && this.eye_color_2_out) {
      data.eye_color_2 = this.eye_color_2_in + ',' + this.eye_color_2_out;
    }
    data.color = this.color;
    data.background_color = this.background_color;
    data.gradient_start_color = this.gradient_start_color;
    data.gradient_end_color = this.gradient_end_color;
    data.gradient_type = this.gradient_type;
    data.size = this.size;
    data.eye_style = this.eye_style;
    data.style = this.style;

    this.allData = data;
    Object.keys(this.allData).forEach((key) => {
      // console.log(key, data[key]);
      if (!this.allData[key]) {
        delete this.allData[key];
      }
    });

    if (this.id) {
      this.allData.qrcode_id = this.id;
      this.updateQr(this.allData, 'customize');
    } else {
      this.addQr(this.allData, 'customize');
    }
    console.log(this.allData);
    // this.select = 'customize';
    this.rand = Math.random();
  }

  confirm(data, select) {
    console.log(data);

    if (data.eye_color_0_in && data.eye_color_0_out) {
      this.allData.eye_color_0 =
        data.eye_color_0_in + ',' + data.eye_color_0_out;
    }
    if (data.eye_color_1_in && data.eye_color_1_out) {
      this.allData.eye_color_1 =
        data.eye_color_1_in + ',' + data.eye_color_1_out;
    }
    if (data.eye_color_2_in && data.eye_color_2_out) {
      this.allData.eye_color_2 =
        data.eye_color_2_in + ',' + data.eye_color_2_out;
    }
    this.allData.color = data.color;
    this.allData.background_color = data.background_color;
    this.allData.gradient_start_color = data.gradient_start_color;
    this.allData.gradient_end_color = data.gradient_end_color;
    this.allData.gradient_type = data.gradient_type;
    this.allData.size = data.size;
    this.allData.eye_style = data.eye_style;
    this.allData.style = data.style;
    Object.keys(this.allData).forEach((key) => {
      // console.log(key, data[key]);
      if (!this.allData[key]) {
        delete this.allData[key];
      }
    });
    console.log(this.allData);
    this.updateQr(this.allData, select);
  }

  updateQr(data, select) {
    this.rand = Math.random();
    this.spinner.show();
    this.qrCodesPro.updateQrCode(data, this.type).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.rand = Math.random();
        this.qr = res.user_qrcode.qrcode_path;
        // this.rand = Math.random();
        // this.getQrCodeDetails(res.user_qrcode.id, null);
        if (select) {
          this.select = select;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.errors.file) {
          this.toastr.error(err.error.errors.file[0], '', {
            timeOut: 3000,
          });
        } else if (err.error.errors.name) {
          this.toastr.error(err.error.errors.name[0], '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  addQr(data, select) {
    // debugger;
    this.spinner.show();
    this.qrCodesPro.addQrCode(data).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.rand = Math.random();
        this.qr = res.user_qrcode.qrcode_path;
        if (!this.id) {
          this.id = res.user_qrcode.id;
          this.allData.qrcode_id = this.id;
        }
        // this.rand = Math.random();
        // this.getQrCodeDetails(res.user_qrcode.id, null);
        if (select) {
          this.select = select;
        }
        // this.getQrCodeDetails(res.user_qrcode.id);
        // this.select = 'finish';
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 5000,
          });
        } else if (err.error.errors.file) {
          this.toastr.error(err.error.errors.file[0], '', {
            timeOut: 3000,
          });
        } else if (err.error.errors.name) {
          this.toastr.error(err.error.errors.name[0], '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  getQrCodeDetails(id, select) {
    // this.rand = Math.random();
    this.spinner.show();
    this.qrCodesPro.getQrCodeDetails(id).subscribe(
      (res: any) => {
        this.users = res.users;
        console.log(res);
        this.spinner.hide();
        if (select) {
          this.select = select;
        }
        this.rand = Math.random();
        this.qrDetails = res.qrcode;
        this.qr = this.qrDetails.qrcode_path;
        this.type = this.qrDetails.type;
        this.logo_path = this.qrDetails.logo_path;
        this.name = this.qrDetails.name;
        if (this.qrDetails.qrcode_group_id) {
          this.qrcode_group_id = this.qrDetails.qrcode_group_id;
        }
        if (this.qrDetails.user_id) {
          this.qrcode_user_id = this.qrDetails.user_id;
        }
        this.design_type = this.qrDetails.design_type;
        this.text_color = this.qrDetails.text_color;
        this.color = this.qrDetails.color;
        this.background_color = this.qrDetails.background_color;
        this.size = this.qrDetails.size;
        this.style = this.qrDetails.style;
        this.eye_style = this.qrDetails.eye_style;
        this.gradient_start_color = this.qrDetails.gradient_start_color;
        this.gradient_end_color = this.qrDetails.gradient_end_color;
        this.gradient_type = this.qrDetails.gradient_type;
        if (this.qrDetails.eye_color_0) {
          let c = this.qrDetails.eye_color_0.split(',');
          this.eye_color_0_in = c[0];
          this.eye_color_0_out = c[1];
        }
        if (this.qrDetails.eye_color_1) {
          let c = this.qrDetails.eye_color_1.split(',');
          this.eye_color_1_in = c[0];
          this.eye_color_1_out = c[1];
        }
        if (this.qrDetails.eye_color_2) {
          let c = this.qrDetails.eye_color_2.split(',');
          this.eye_color_2_in = c[0];
          this.eye_color_2_out = c[1];
        }
        if (this.type == 'template') {
          this.link = this.qrDetails.link;
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'facebook') {
              this.facebook = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'instagram') {
              this.instagram = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'survey') {
              this.survey = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'qrcodes_title') {
              this.qrcodes_title = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'service') {
              this.service = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'website') {
              this.website = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'file_path') {
              this.file_path = this.qrDetails.attributes[index].value;
              this.file = this.qrDetails.attributes[index];
            }
          }
        } else if (this.type == 'link') {
          this.link = this.qrDetails.link;
        } else if (this.type == 'file') {
          this.file_path = this.qrDetails.link;
        } else if (this.type == 'email') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'email') {
              this.email = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'subject') {
              this.subject = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'body') {
              this.body = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'wifi') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'encryption') {
              this.encryption = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'ssid') {
              this.ssid = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'password') {
              this.password = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'hidden') {
              this.hidden = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'phone_number') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'phone_number') {
              this.phone_number = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'sms') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'phone_number') {
              this.phone_number = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'message') {
              this.message = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'whatsapp') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'phone_number') {
              console.log(this.qrDetails.attributes[index].key);
              this.phone_number = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'message') {
              this.message = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'geo') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'lat') {
              this.latitude = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'long') {
              this.longitude = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'vcard') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'first_name') {
              this.first_name = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'last_name') {
              this.last_name = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'address') {
              this.address = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'email') {
              this.email = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'mobile') {
              this.mobile = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'title') {
              this.title = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'organization') {
              this.organization = this.qrDetails.attributes[index].value;
            }
          }
        } else if (this.type == 'survey') {
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'facebook') {
              this.facebook = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'instagram') {
              this.instagram = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'service') {
              this.service = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'website') {
              this.website = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'qrcodes_title') {
              this.qrcodes_title = this.qrDetails.attributes[index].value;
            }
          }
          this.background_path = this.qrDetails.background_image_path;
          this.questions = this.qrDetails.survey_attributes;
          this.questions.forEach((element) => {
            if (element.type == 'radio' || element.type == 'checkboxes') {
              element.answers = element.answers.split('@');
            }
          });
        } else if (this.type == 'cumulative') {
          this.background_path = this.qrDetails.background_image_path;
          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'Links') {
              let linksArr = JSON.parse(this.qrDetails.attributes[index].value);
              linksArr.forEach((element) => {
                if (element.key == 'facebook') {
                  this.facebook = element.value;
                } else if (element.key == 'instagram') {
                  this.instagram = element.value;
                } else if (element.key == 'website') {
                  this.website = element.value;
                } else if (element.key == 'survey') {
                  this.survey = element.value;
                } else if (element.key == 'qrcodes_title') {
                  this.qrcodes_title = element.value;
                }
              });
            } else if (this.qrDetails.attributes[index].key == 'Qrcodes') {
              this.cumulativeQrs = JSON.parse(
                this.qrDetails.attributes[index].value
              );
            } else if (this.qrDetails.attributes[index].key == 'file_path') {
              this.file_path = this.qrDetails.attributes[index].value;
              this.file = this.qrDetails.attributes[index];
            } else if ((this.qrDetails.attributes[index].key).includes('extra_image')) {
              this.extra_images[(this.qrDetails.attributes[index].key).substring(12)-1] = this.qrDetails.attributes[index].value;
              // console.log(this.extra_images);
            }
          }
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  onDown(name) {
    // const fileName = `save.png`;
    this._http
      .get(`${this.qr}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((res) => {
        this._FileSaverService.save(res.body, name);
      });
  }

  editQr() {
    this.getQrCodeDetails(this.id, 'setup');
  }

  // Survey Questions

  addQuestion() {
    let q = { title: null, type: null, answers: [] };
    this.questions.push(q);
  }

  deleteQuestion(index) {
    this.questions.splice(index, 1);
  }

  addQuestionsToArray(index, val, type) {
    if (type == 'tit') {
      this.questions[index].title = val;
    } else if (type == 'type') {
      this.questions[index].type = val;
    }
    console.log(this.questions);
  }

  addQuestionAnswer(index, val) {
    this.questions[index].answers.push(val);  
    console.log(this.questions);
  }

  deleteQuestionAnswer(index, ans_index) {
    this.questions[index].answers.splice(ans_index, 1);
  }

  // Start cumulativeQrs

  addCumQr() {
    this.cumulativeQrs.push({ key: null, value: null, extra_picture: null });
  }

  deleteCumQr(index) {
    this.cumulativeQrs.splice(index, 1);
  }

  addCumQrData(data, attr, index) {
    if (attr == 'name') {
      this.cumulativeQrs[index].key = data;
    } else if (attr == 'val') {
      this.cumulativeQrs[index].value = data;
    } else if (attr == 'pic') {
      this.cumulativeQrs[index].extra_picture = data;
    }
  }

  checkTypes(type) {
    this.available_types = localStorage.getItem('available_types');
    // console.log(this.available_types);
    if (this.available_types) {
      if (this.available_types == '*') {
        return true;
      } else {
        if (this.available_types.includes(type)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  getFileName(file) {
    // console.log(file.files[0]);
    // this.file_name = file.files[0];
  }

  getQrGroups() {
    this.qrCodesPro.getQrGroups().subscribe(
      (res: any) => {
        console.log(res);
        this.groups = res.user_qrcode_groups;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  removeFile() {
    this.qrCodesPro.removeFile(this.id).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success('File removed successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  removeLogo() {
    this.qrCodesPro.removeLogo(this.id).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success('Logo removed successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  removeBackground() {
    this.qrCodesPro.removeBackground(this.id).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success('Background removed successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editTemplateQr(c_link) {
    var c_id = c_link.split('/');
    this.router.navigate([`/base/addqr/${c_id[c_id.length-1]}`]);
  }

}
