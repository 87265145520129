import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './base/base.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MenuComponent } from './menu/menu.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ChartsModule } from 'ng2-charts';
import { AuthGuardService } from '../auth-guard.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QrCodesComponent } from './qr-codes/qr-codes.component';
import { GroupQrCodesComponent } from './group-qr-codes/group-qr-codes.component';
import { AddQrCodeComponent } from './add-qr-code/add-qr-code.component';
import { QrCodeDetailsComponent } from './qr-code-details/qr-code-details.component';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { SurveyComponent } from './survey/survey.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddUserComponent } from './add-user/add-user.component';
import { RoleGuardService } from './role-guard.service';
import { QrAnalyticsComponent } from './qr-analytics/qr-analytics.component';
import { NewAddQrCodeComponent } from './new-add-qr-code/new-add-qr-code.component';
import { EditQrCodeComponent } from './edit-qr-code/edit-qr-code.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProfileComponent } from './profile/profile.component';
import { AgmCoreModule } from '@agm/core';
import { SurveyAnalrticsComponent } from './survey-analrtics/survey-analrtics.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';

import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChartPopupComponent } from './chart-popup/chart-popup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompareAnalyticsComponent } from './compare-analytics/compare-analytics.component';
import { RatePopupComponent } from './rate-popup/rate-popup.component';
import { CarlosPopupComponent } from './carlos-popup/carlos-popup.component';
import { GroupsComponent } from './groups/groups.component';
import { CumulativeMasaComponent } from './user-menu/cumulative-masa/cumulative-masa.component';
import { CumulativeArtTownComponent } from './user-menu/cumulative-art-town/cumulative-art-town.component';
import { CumulativeChiringutioComponent } from './user-menu/cumulative-chiringutio/cumulative-chiringutio.component';
import { CumulativeThegComponent } from './user-menu/cumulative-theg/cumulative-theg.component';
import { CumulativeFreshFoodComponent } from './user-menu/cumulative-fresh-food/cumulative-fresh-food.component';
import { CumulativeDefaultComponent } from './user-menu/cumulative-default/cumulative-default.component';
import { CumulativeDefaultWhiteComponent } from './user-menu/cumulative-default-white/cumulative-default-white.component';
import { CumulativeIxinaComponent } from './user-menu/cumulative-ixina/cumulative-ixina.component';
import { CumulativePadbolComponent } from './user-menu/cumulative-padbol/cumulative-padbol.component';
import { CumulativeGffComponent } from './user-menu/cumulative-gff/cumulative-gff.component';
import { TemplateComponent } from './user-menu/template/template.component';
import { CumulativeWaterComponent } from './user-menu/cumulative-water/cumulative-water.component';
import { CumulativeWaterSingleComponent } from './user-menu/cumulative-water-single/cumulative-water-single.component';
import { CumulativeWatertemplateComponent } from './user-menu/cumulative-watertemplate/cumulative-watertemplate.component';
import { CumulativeMafComponent } from './user-menu/cumulative-maf/cumulative-maf.component';
import { CumulativeXprsComponent } from './user-menu/cumulative-xprs/cumulative-xprs.component';
import { CumulativeSolComponent } from './user-menu/cumulative-sol/cumulative-sol.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { AddRestaurantComponent } from './add-restaurant/add-restaurant.component';
import { MenusComponent } from './menus/menus.component';
import { OrdersComponent } from './orders/orders.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { OrderItemsComponent } from './order-items/order-items.component';
import { OrderItemsEditComponent } from './order-items-edit/order-items-edit.component';
import { OrdersHistoryComponent } from './orders-history/orders-history.component';
import { FoodCourtsComponent } from './foodcourt/food-courts/food-courts.component';
import { AddFoodCourtComponent } from './foodcourt/add-food-court/add-food-court.component';
import { AddFoodCourtAdminComponent } from './foodcourt/add-food-court-admin/add-food-court-admin.component';
import { AddFoodCourtRestaurantComponent } from './foodcourt/add-food-court-restaurant/add-food-court-restaurant.component';
import { FoodCourtRestaurantsComponent } from './foodcourt/food-court-restaurants/food-court-restaurants.component';
import { AddFoodCourtRestaurantAdminComponent } from './foodcourt/add-food-court-restaurant-admin/add-food-court-restaurant-admin.component';
import { FcRestaurantsComponent } from './foodcourt/fc-restaurants/fc-restaurants.component';
import { AddFcMenuComponent } from './foodcourt/add-fc-menu/add-fc-menu.component';
import { FcOrdersComponent } from './foodcourt/fc-orders/fc-orders.component';
import { FcOrdersHistoryComponent } from './foodcourt/fc-orders-history/fc-orders-history.component';
import { FcOrderItemsComponent } from './foodcourt/fc-order-items/fc-order-items.component';
import { FcOrderItemsEditComponent } from './foodcourt/fc-order-items-edit/fc-order-items-edit.component';
import { DashboardItemsPopupComponent } from './dashboard-items-popup/dashboard-items-popup.component';
import { NotAvailaplePopupComponent } from './not-availaple-popup/not-availaple-popup.component';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AddRestaurantAdminComponent } from './add-restaurant-admin/add-restaurant-admin.component';
import { TableOrdersComponent } from './table-orders/table-orders.component';
import { FreshOrdersComponent } from './fresh-orders/fresh-orders.component';
@NgModule({
  declarations: [
    BaseComponent,
    SideMenuComponent,
    MenuComponent,
    AnalyticsComponent,
    QrAnalyticsComponent,
    QrCodesComponent,
    AddQrCodeComponent,
    AddMenuComponent,
    SurveyComponent,
    AddUserComponent,
    NewAddQrCodeComponent,
    EditQrCodeComponent,
    NavbarComponent,
    ProfileComponent,
    SurveyAnalrticsComponent,
    ChartPopupComponent,
    DashboardComponent,
    CompareAnalyticsComponent,
    RatePopupComponent,
    CarlosPopupComponent,
    GroupsComponent,
    GroupQrCodesComponent,
    CumulativeMasaComponent,
    CumulativeChiringutioComponent,
    CumulativeThegComponent,
    CumulativeFreshFoodComponent,
    CumulativeDefaultComponent,
    CumulativeDefaultWhiteComponent,
    CumulativePadbolComponent,
    TemplateComponent,
    CumulativeWaterComponent,
    CumulativeWaterComponent,
    CumulativeWaterSingleComponent,
    CumulativeWatertemplateComponent,
    CumulativeMafComponent,
    RestaurantsComponent,
    AddRestaurantComponent,
    MenusComponent,
    OrdersComponent,
    AllOrdersComponent,
    OrderItemsComponent,
    OrderItemsEditComponent,
    OrdersHistoryComponent,
    FoodCourtsComponent,
    AddFoodCourtComponent,
    AddFoodCourtAdminComponent,
    AddFoodCourtRestaurantComponent,
    FoodCourtRestaurantsComponent,
    AddFoodCourtRestaurantAdminComponent,
    FcRestaurantsComponent,
    AddFcMenuComponent,
    FcOrdersComponent,
    FcOrdersHistoryComponent,
    FcOrderItemsComponent,
    FcOrderItemsEditComponent,
    DashboardItemsPopupComponent,
    NotAvailaplePopupComponent,
    DeletePopupComponent,
    AddRestaurantAdminComponent,
    TableOrdersComponent,
    FreshOrdersComponent,
    CumulativeGffComponent,
    CumulativeArtTownComponent,
    CumulativeIxinaComponent,
    CumulativeXprsComponent,
    CumulativeSolComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    PdfViewerModule,
    InfiniteScrollModule,
    GooglePlaceModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatInputModule,
    MatTabsModule,
    MatSidenavModule,
    AgmCoreModule.forRoot({
      // apiKey: "AIzaSyBAjVVN9IO-ZND91rj1uW2zHTJx59_nbGo"
      apiKey: 'AIzaSyC70PdcuaUP9ZZGWyKju9twuWmgKbTAWrw',
    }),
    RouterModule.forChild([
      {
        path: '',
        component: BaseComponent,
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            component: DashboardComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'menu',
            component: MenuComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'analytics',
            component: AnalyticsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'analytics/:id',
            component: QrAnalyticsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'survey-analytics/:id',
            component: SurveyAnalrticsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'qrcodes',
            component: QrCodesComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'new-addqr',
            component: AddQrCodeComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'addqr',
            component: EditQrCodeComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'addqr/:id',
            component: EditQrCodeComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'survey',
            component: SurveyComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'add-user',
            component: AddUserComponent,
            canActivate: [AuthGuardService, RoleGuardService],
          },
          {
            path: 'profile',
            component: ProfileComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'compare',
            component: CompareAnalyticsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'qrcodes/groups',
            component: GroupsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'qrcodes/groups/:id',
            component: GroupQrCodesComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'add-restaurant-admin',
            component: AddRestaurantAdminComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants',
            component: RestaurantsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/create',
            component: AddRestaurantComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/edit/:id',
            component: AddRestaurantComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/menus/:r_id',
            component: AddMenuComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/orders/:r_id',
            component: OrdersComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/all_orders',
            component: AllOrdersComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/table_orders',
            component: TableOrdersComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/history/:r_id',
            component: OrdersHistoryComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/order-items/:or_id',
            component: OrderItemsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'restaurants/order-items-edit/:or_id',
            component: OrderItemsEditComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts',
            component: FoodCourtsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/create',
            component: AddFoodCourtComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/edit/:id',
            component: AddFoodCourtComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/admin/create',
            component: AddFoodCourtAdminComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/admin/create/:id',
            component: AddFoodCourtRestaurantAdminComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants',
            component: FcRestaurantsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/create',
            component: AddFoodCourtRestaurantComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/edit/:res_id',
            component: AddFoodCourtRestaurantComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/menus/:r_id',
            component: AddFcMenuComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/orders/:r_id',
            component: FcOrdersComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/history/:r_id',
            component: FcOrdersHistoryComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/order-items/:or_id',
            component: FcOrderItemsComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'foodcourts/restaurants/order-items-edit/:or_id',
            component: FcOrderItemsEditComponent,
            canActivate: [AuthGuardService],
          },
        ],
      },
    ]),
  ],
  entryComponents: [
    ChartPopupComponent,
    RatePopupComponent,
    CarlosPopupComponent,
    DashboardItemsPopupComponent,
    NotAvailaplePopupComponent,
    DeletePopupComponent,
  ],
  exports: [
    CumulativeMasaComponent,
    CumulativeChiringutioComponent,
    CumulativeThegComponent,
    CumulativeDefaultComponent,
    CumulativeDefaultWhiteComponent,
    CumulativeIxinaComponent,
    CumulativeFreshFoodComponent,
    CumulativeWaterComponent,
    CumulativeWaterSingleComponent,
    CumulativeWatertemplateComponent,
    CumulativeMafComponent,
    CumulativePadbolComponent,
    CumulativeGffComponent,
    CumulativeArtTownComponent,
    CumulativeXprsComponent,
    CumulativeSolComponent,
    SurveyComponent,
    TemplateComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  ],
})
export class DashboardModule {}
