import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-survery',
  templateUrl: './static-survery.component.html',
  styleUrls: ['./static-survery.component.scss']
})
export class StaticSurveryComponent implements OnInit {

  questions = [
    {type: 'text', name: 'name', label: 'What is your name', placeholder : 'Please tell us your full name', isHidden : false},
    {type: 'number', name: 'number', label: 'What is your phone number',
    placeholder : 'Stay up to date with our latest news', isHidden : true},
    {type: 'radio', name: 'quality', label: 'How would you rate the quality of the service?', values: [
      {value: 1, display: 1},
      {value: 2, display: 2},
      {value: 3, display: 3},
      {value: 4, display: 4},
      {value: 5, display: 5},
    ], isHidden: true},
    {type: 'radio', name: 'clean', label: 'How would you rate our cleanliness and safety measures taken?', values: [
      {value: 1, display: 1},
      {value: 2, display: 2},
      {value: 3, display: 3},
      {value: 4, display: 4},
      {value: 5, display: 5},
    ], isHidden: true},
    {type: 'text', name: 'comment', label: 'Do you have any other comments, questions, or concerns?',
    placeholder : 'Type your answer here', isHidden : true},

  ];
  surveyFinished = false;
  inputStr = '';
  constructor() { }

  ngOnInit(): void {
  }

   modelChange(str: string): void {
    this.inputStr = str;
  }

}
