import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OrderItemsComponent implements OnInit {

  id: any;
  order: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private menuPro: MenuProService) {
    this.route.params.subscribe(params => {
      this.id = params.or_id;
      if (this.id) {
        this.getRestaurantOrderItems(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurantOrderItems(id) {
    this.spinner.show();
    this.menuPro.getRestaurantOrderItems(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.order = res.Order[0];
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
