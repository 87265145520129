import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  role: any;

  constructor(private router: Router) {
    this.role = localStorage.getItem('touchless_role');
  }

  ngOnInit(): void {
  }

  logout() {
    localStorage.removeItem('touchless');
    localStorage.removeItem('touchless_role');
    localStorage.removeItem('available_types');
    localStorage.removeItem('touch_compare');
    this.router.navigate(['/']);
  }

}
