import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-home',
  templateUrl: './order-home.component.html',
  styleUrls: ['./order-home.component.scss'],
})
export class OrderHomeComponent implements OnInit {
  id: any;
  offers: any;

  constructor(
    private menuPro: MenuProService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getMenu(this.id);
        let m_id = localStorage.getItem('t_menu_id');
        if (m_id) {
          if (m_id != this.id) {
            localStorage.setItem('t_menu_id', this.id);
            localStorage.removeItem('t_order');
            localStorage.removeItem('order_id');
            localStorage.removeItem('res_id');
          }
        } else {
          localStorage.setItem('t_menu_id', this.id);
        }
      }
    });
  }

  ngOnInit(): void {}

  getMenu(id) {
    this.spinner.show();
    this.menuPro.getUserDigitalMenu(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.offers = res.Menu.offers;
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
}
