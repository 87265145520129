<app-navbar [page]="'GROUPS'"></app-navbar>

<div class="groups">
    <div class="container">
        <div class="add_group">
            <div class="row">
                <div class="col-md-6">
                    <form #gForm="ngForm" (ngSubmit)="addGroup(gForm.value)">
                        <div class="form-group">
                            <label>Group Name</label>
                            <input type="text" class="form-control" placeholder="Group Name" name="name" ngModel
                                required>
                        </div>
                        <button class="btn btn-primary" [disabled]="!gForm.valid">Add Group</button>
                    </form>
                </div>
            </div>
        </div>
        <hr>

        <div class="all_groups">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let g of groups">
                    <div class="item">
                        <h4>{{g.name}}</h4>
                        <div class="buttons text-center">
                            <button class="btn btn-danger" (click)="deleteGroup(g.id)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>