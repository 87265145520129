import { Component, OnInit } from '@angular/core';
import { MenuProService } from '../menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  days: any = 10;
  scans: any;
  scan_counts: any;
  scan_days: any;

  lineChartData: ChartDataSets[] = [
    { data: [], label: 'Scans' },
  ];
  lineChartLabels: Label[] = [];
  lineChartOptions: (any & { annotation: any }) = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  lineChartLegend = true;
  lineChartType = 'line';
  lineChartPlugins = [];

  constructor(
    private menuPro: MenuProService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getScans(this.days);
  }

  getScans(days) {
    this.spinner.show();
    this.menuPro.getAnalytics(days)
      .subscribe((res: any) => {
        console.log(res);
        this.scans = res;
        this.scan_counts = res.scan_counts;
        this.scan_days = res.scan_days;
        this.lineChartData[0].data = this.scan_counts;
        this.lineChartLabels = this.scan_days;
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
