import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';

@Component({
  selector: 'app-order-home',
  templateUrl: './order-home.component.html',
  styleUrls: ['./order-home.component.scss'],
})
export class OrderHomeComponent implements OnInit {
  id: any;
  offers: any;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    nav: false,
    dots: false,
    items: 4,
    stagePadding: 70,
    margin: 25,
    autoWidth: true,
  };

  constructor(
    private foodcourtPro: FoodCourtProService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getMenu(this.id);
        let m_id = localStorage.getItem('t_menu_id');
        if (m_id) {
          if (m_id != this.id) {
            localStorage.setItem('t_menu_id', this.id);
            localStorage.removeItem('t_order');
            localStorage.removeItem('order_id');
            localStorage.removeItem('res_id');
          }
        } else {
          localStorage.setItem('t_menu_id', this.id);
        }
      }
    });
  }

  ngOnInit(): void {}

  getMenu(id) {
    this.spinner.show();
    this.foodcourtPro.getUserDigitalMenu(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.offers = res.fc_menu.fc_offers;
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
}
