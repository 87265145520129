<div class="order">
  <div class="container">
    <header>
      <h1>Touch-less <span>Ordering</span></h1>
      <img src="/assets/imgs/donation.svg" alt="donation" />
    </header>

    <div class="order__list"></div>

    <footer>Powered by <strong>Touch-less &copy;</strong> 2020</footer>
  </div>
</div>
