import { Component, OnInit } from '@angular/core';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-ordering',
  templateUrl: './ordering.component.html',
  styleUrls: ['./ordering.component.scss']
})
export class OrderingComponent implements OnInit {

  id: any;
  menu: any;
  items: any;
  total: any = 0;
  order_id: any;

  constructor(
    private router: Router,
    private menuPro: MenuProService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.hideCallWaiter('callW');
        this.hideCallWaiter('totalB');
      }
    });
    this.getItems();
    this.getOrderId();
  }

  ngOnInit(): void {
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  getItems() {
    let its = JSON.parse(localStorage.getItem('t_order'));
    if (its) {
      this.items = its;
      this.getTotal(this.items);
    } else {
      this.items = [];
      this.total = 0;
    }
    console.log(this.items);
  }

  getOrderId() {
    this.order_id = localStorage.getItem('order_id');
  }

  getTotal(items) {
    this.total = 0;
    if (items) {
      items.forEach(element => {
        for (let index = 0; index < element.quantity; index++) {
          if (element.half_portion == '1') {
            if (element.on_offer) {
              this.total = this.total + element.digital_menu_section_item.half_portion_new_price;
            } else {
              this.total = this.total + element.digital_menu_section_item.half_portion_price;
            }
          } else if (element.on_offer) {
            this.total = this.total + element.digital_menu_section_item.new_price;
          } else {
            this.total = this.total + element.digital_menu_section_item.price;
          }
        }
        for (let mod_index = 0; mod_index < element.digital_menu_section_item.digital_menu_section_item_modifiers.length; mod_index++) {
          if (element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index].quantity) {
            for (let mod_q_ind = 0; mod_q_ind < element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index].quantity; mod_q_ind++) {
              this.total = this.total + element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index].price;
            }
          }
        }
      });
    }
  }

  getMenu(id) {
    this.menuPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        this.menu = res.Menu;
        localStorage.setItem('res_id', this.menu.restaurant_id);
      }, err => {
        console.log(err);
      })
  }

  showCallWaiter(id) {
    document.getElementById(id).classList.add('show');
  }

  hideCallWaiter(id) {
    document.getElementById(id).classList.remove('show');
  }

  addItem(ind) {
    this.items[ind].quantity = this.items[ind].quantity + 1;
    this.getTotal(this.items);
    localStorage.setItem('t_order', JSON.stringify(this.items));
  }

  removeItem(ind) {
    this.items[ind].quantity = this.items[ind].quantity - 1;
    if (this.items[ind].quantity == 0) {
      this.items.splice(ind, 1);
    }
    this.getTotal(this.items);
    localStorage.setItem('t_order', JSON.stringify(this.items));
  }

  requestReceipt() {
    let id = localStorage.getItem('t_menu_id');
    this.menuPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        if (res.active_order) {
          res.active_order.forEach(element => {
            if (element.id) {
              this.spinner.show();
              this.menuPro.requestReceipt(element.id)
                .subscribe((res: any) => {
                  console.log(res);
                  this.spinner.hide();
                  this.toastr.success(res.Message, '', {
                    timeOut: 3000
                  })
                }, err => {
                  console.log(err);
                  this.spinner.hide();
                  if (err.error.Message) {
                    this.toastr.error(err.error.Message, '', {
                      timeOut: 3000
                    })
                  } else if (err.error.message) {
                    this.toastr.error(err.error.message, '', {
                      timeOut: 3000
                    })
                  } else {
                    this.toastr.error('Something went wrong!', '', {
                      timeOut: 3000
                    })
                  }
                })
            }

          });
        } else {
          this.toastr.error('You must place order first!', '', {
            timeOut: 3000
          })
        }
      }, err => {
        console.log(err);
      })
  }

  callWaiter() {
    let id = localStorage.getItem('t_menu_id');
    this.menuPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        if (res.active_order) {
          res.active_order.forEach(element => {
            if (element.id) {
              this.spinner.show();
              this.menuPro.callWaiter(element.id)
                .subscribe((res: any) => {
                  console.log(res);
                  this.spinner.hide();
                  this.toastr.success(res.Message, '', {
                    timeOut: 3000
                  })
                }, err => {
                  console.log(err);
                  this.spinner.hide();
                  if (err.error.Message) {
                    this.toastr.error(err.error.Message, '', {
                      timeOut: 3000
                    })
                  } else if (err.error.message) {
                    this.toastr.error(err.error.message, '', {
                      timeOut: 3000
                    })
                  } else {
                    this.toastr.error('Something went wrong!', '', {
                      timeOut: 3000
                    })
                  }
                })
            }

          });
        } else {
          this.toastr.error('You must place order first!', '', {
            timeOut: 3000
          })
        }
      }, err => {
        console.log(err);
      })
  }

}
