<div class="menu">
  <div class="text-center">
    <img src="assets/imgs/logo.jpg" width="130" />
  </div>
  <button routerLink="/base/addqr" *ngIf="role !== 'restaurant_admin'">
    + CREATE
  </button>
  <!-- <button routerLink="/base/survey">Create Survey</button> -->

  <ul class="list-unstyled">
    <li
      routerLink="/base/dashboard"
      routerLinkActive="active"
      *ngIf="role !== 'restaurant_admin'"
    >
      <img src="assets/imgs/dashboard.svg" />Dashboard
    </li>
    <li
      routerLink="/base/qrcodes"
      routerLinkActive="active"
      *ngIf="role !== 'restaurant_admin'"
    >
      <img src="assets/imgs/qr-code.svg" />QR Codes
    </li>
    <li
      *ngIf="role == 'super_admin'"
      routerLink="/base/add-user"
      routerLinkActive="active"
    >
      <img src="assets/imgs/campaign.svg" />Add User
    </li>
    <!-- <li routerLink="/base/compare" routerLinkActive="active"><img src="assets/imgs/pie-chart.svg"> Compare Qr Codes</li> -->
    <li
      *ngIf="role == 'restaurant_admin' || role == 'super_admin'"
      routerLink="/base/restaurants"
      routerLinkActive="active"
    >
      <img src="assets/imgs/pie-chart.svg" /> Restaurants
    </li>
    <li
      *ngIf="role == 'super_admin'"
      routerLink="/base/add-restaurant-admin"
      routerLinkActive="active"
    >
      <img src="assets/imgs/pie-chart.svg" /> Add Restaurant Admin
    </li>
    <li
      *ngIf="
        role == 'fc_restaurant_admin' ||
        role == 'fc_admin' ||
        role == 'super_admin'
      "
      routerLink="/base/foodcourts/restaurants"
      routerLinkActive="active"
    >
      <img src="assets/imgs/pie-chart.svg" />
      Food Court Restaurants
    </li>
    <li
      *ngIf="role == 'fc_admin' || role == 'super_admin'"
      routerLink="/base/foodcourts"
      routerLinkActive="active"
    >
      <img src="assets/imgs/pie-chart.svg" />Food Courts
    </li>
    <li
      *ngIf="role == 'super_admin'"
      routerLink="/base/foodcourts/admin/create"
      routerLinkActive="active"
    >
      <img src="assets/imgs/pie-chart.svg" /> Add Food Court Admin
    </li>

    <li (click)="logout()"><img src="assets/imgs/pie-chart.svg" />Logout</li>
    <!-- <li routerLink="/base/menus" routerLinkActive="active"><img src="assets/imgs/pie-chart.svg"> Menus</li> -->
    <!-- <li routerLink="/base/analytics" routerLinkActive="active"><img src="assets/imgs/pie-chart.svg"> Analytics</li> -->
  </ul>
  <!-- <p routerLink="/base/menu">Add Menu</p> -->
  <!-- <hr> -->
  <!-- <p routerLink="/base/addqr">QR Codes</p> -->
  <!-- <hr> -->
  <!-- <p routerLink="/base/analytics">Analytics</p> -->
  <!-- <hr> -->
  <!-- <p routerLink="/base/qrcodes">View QR Codes</p>
    <hr>
    <p routerLink="/base/addqr">QR Codes</p>
    <hr>
    <p routerLink="/base/analytics">Analytics</p>
    <hr>
    <p routerLink="/base/qrcodes">View QR Codes</p>
    <hr>
    <p *ngIf="role == 'super_admin'" routerLink="/base/add-user">Add User</p> -->
</div>
