<div class="home">
    <div class="head">
        <h1>Order</h1>
    </div>
    <div class="body">
        <h2>Your Order <span>6005</span></h2>
        <div class="item">
            <div class="row">
                <div class="col-6 nopadding">
                    <h6>Salad Test</h6>
                    <p>Would you like to add any modifiers?<br><span>Click Here</span></p>
                </div>
                <div class="col-4 text-center nopadding">
                    <h5>EGP 30.00</h5>
                </div>
                <div class="col-2 text-center">
                    <span class="c">X</span>
                </div>
            </div>
        </div>

        <div class="item">
            <div class="row">
                <div class="col-6 nopadding">
                    <h6>Salad Test</h6>
                    <p>Modifier 1 | Modifier 2</p>
                </div>
                <div class="col-4 text-center nopadding">
                    <h5>EGP 30.00</h5>
                </div>
                <div class="col-2 text-center">
                    <span class="c">X</span>
                </div>
            </div>
        </div>

        <div class="total">
            <div class="row">
                <div class="col-6 nopadding">
                    <h6>Total</h6>
                    <button routerLink="/order/menu">+ Add New item</button>
                </div>
                <div class="col-6 text-right nopadding">
                    <h4>EGP 60.00</h4>
                </div>
            </div>
        </div>

        <div class="ready">
            <h6>Order to be ready in</h6>
            <p><span>3</span> <span>0</span> : <span>0</span> <span>0</span></p>
        </div>

    </div>
</div>