import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-notifications',
  templateUrl: './order-notifications.component.html',
  styleUrls: ['./order-notifications.component.scss']
})
export class OrderNotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
