import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuProService } from '../menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss'],
})
export class AddMenuComponent implements OnInit {
  r_id: any;
  m_id: any;
  menu: any;

  background_image_path: any;
  logo_path: any;
  background_path: any;
  primary_color: any;
  secondary_color: any;
  sections: any[] = [];

  restaurant: any;
  link: any;

  section: string = 'section0';

  categories: any;

  constructor(
    private route: ActivatedRoute,
    private menuPro: MenuProService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe((params) => {
      this.r_id = params.r_id;
      if (this.r_id) {
        this.getRestaurant(this.r_id);
      }
    });

  }

  ngOnInit(): void {}

  getRestaurant(id) {
    this.spinner.show();
    this.menuPro.getRestaurant(id).subscribe(
      (res: any) => {
        console.log(res);

        this.restaurant = res;
        if (this.restaurant.digital_menu) {
          this.menu = this.restaurant.digital_menu;
          this.link = this.restaurant.digital_menu.digital_menu_link;
          this.m_id = this.menu.id;
          this.background_image_path = this.menu.background_image_path;
          this.logo_path = this.menu.logo_path;
          this.background_path = this.menu.background_path;
          this.primary_color = this.menu.primary_color;
          this.secondary_color = this.menu.secondary_color;
          this.sections = this.menu.digital_menu_sections;
          console.log(this.restaurant);
          this.menuPro.getUserDigitalMenuCategories(this.restaurant.digital_menu.id).subscribe(
            (res: any) => {
              console.log(res);
              this.spinner.hide();
              this.categories = res.data;
            },
            (err) => {
              console.log(err);
              this.spinner.hide();
            }
          );
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  addMenuData() {
    let data = {
      restaurant_id: this.r_id,
      digital_menu_sections: this.sections,
      digitalmenu_id: null,
      title_and_subtitle_color: (<HTMLInputElement> document.getElementsByName("title_and_subtitle_color")[0]).value,
      section_title_color: (<HTMLInputElement> document.getElementsByName("section_title_color")[0]).value,
      item_name_and_desc_color: (<HTMLInputElement> document.getElementsByName("item_name_and_desc_color")[0]).value,
      selected_category_color: (<HTMLInputElement> document.getElementsByName("selected_category_color")[0]).value
    };

    if (this.m_id) {
      data.digitalmenu_id = this.m_id;
      this.editMenu(data);
    } else {
      delete data.digitalmenu_id;
      this.addMenu(data);
    }
  }

  addMenu(data) {
    this.spinner.show();
    this.menuPro.addDigitalMenu(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Menu added successfully!', '', {
          timeOut: 3000,
        });
        this.getRestaurant(this.r_id);
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  editMenu(data) {
    this.spinner.show();
    console.log("dtaaaaaaaaaaaaaaaaaaaaaa")
    console.log(data)
    this.menuPro.editDigitalMenu(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Menu updated successfully!', '', {
          timeOut: 3000,
        });
        this.getRestaurant(this.r_id);
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  addSection() {
    let s = { sort: null, name: null, description: null, name_ar: null, description_ar: null, category_id: null, category_name: null, digital_menu_section_items: [] };
    this.sections.push(s);
  }

  removeSection(index) {
    this.sections.splice(index, 1);
  }

  addSectionData(index, attr, val) {
    if (attr == 'name') {
      this.sections[index].name = val;
    } else if (attr == 'desc') {
      this.sections[index].description = val;
    } else if (attr == 'sort') {
      this.sections[index].sort = val;
    } else if (attr == 'name_ar') {
      this.sections[index].name_ar = val;
    } else if (attr == 'desc_ar') {
      this.sections[index].desc_ar = val;
    } else if (attr == 'category') {
      this.sections[index].category_id = val;
      this.sections[index].category_name = document.getElementById(val).innerText.substring(0, document.getElementById(val).innerText.length-2).split("-")[0];
      console.log(this.sections);
    }
  }

  addSectionItem(index) {
    let s_item = {
      name: null,
      description: null,
      name_ar: null,
      description_ar: null,
      available: 1,
      price: null,
      size_price: null,
      on_offer: 0,
      new_price: null,
      old_price: null,
      has_half_portion: 0,
      half_portion_price: null,
      half_portion_new_price: null,
      half_portion_old_price: null,
      digital_menu_section_item_modifiers: [],
    };
    this.sections[index].digital_menu_section_items.push(s_item);
  }

  removeSectionItem(index, item_ind) {
    this.sections[index].digital_menu_section_items.splice(item_ind, 1);
  }

  addSectionItemData(index, item_ind, attr, val) {
    if (attr == 'name') {
      this.sections[index].digital_menu_section_items[item_ind].name = val;
    } else if (attr == 'desc') {
      this.sections[index].digital_menu_section_items[
        item_ind
      ].description = val;
    } else if (attr == 'name_ar') {
      this.sections[index].digital_menu_section_items[item_ind].name_ar = val;
    } else if (attr == 'description_ar') {
      this.sections[index].digital_menu_section_items[item_ind].description_ar = val;
    } else if (attr == 'sort') {
      this.sections[index].digital_menu_section_items[item_ind].price = val;
    } else if (attr == 'price') {
      this.sections[index].digital_menu_section_items[item_ind].price = val;
    } else if (attr == 'size_price') {
      this.sections[index].digital_menu_section_items[item_ind].size_price = val;
    } else if (attr == 'new_price') {
      this.sections[index].digital_menu_section_items[item_ind].new_price = val;
    } else if (attr == 'old_price') {
      this.sections[index].digital_menu_section_items[item_ind].old_price = val;
    } else if (attr == 'on_offer') {
      if (this.sections[index].digital_menu_section_items[item_ind].on_offer) {
        this.sections[index].digital_menu_section_items[item_ind].on_offer = 0;
      } else {
        this.sections[index].digital_menu_section_items[item_ind].on_offer = 1;
      }
    } else if (attr == 'available') {
      if (this.sections[index].digital_menu_section_items[item_ind].available) {
        this.sections[index].digital_menu_section_items[item_ind].available = 0;
      } else {
        this.sections[index].digital_menu_section_items[item_ind].available = 1;
      }
    } else if (attr == 'has_half_portion') {
      if (
        this.sections[index].digital_menu_section_items[item_ind]
          .has_half_portion
      ) {
        this.sections[index].digital_menu_section_items[
          item_ind
        ].has_half_portion = 0;
      } else {
        this.sections[index].digital_menu_section_items[
          item_ind
        ].has_half_portion = 1;
      }
    } else if (attr == 'half_portion_price') {
      this.sections[index].digital_menu_section_items[
        item_ind
      ].half_portion_price = val;
    } else if (attr == 'half_portion_new_price') {
      this.sections[index].digital_menu_section_items[
        item_ind
      ].half_portion_new_price = val;
    } else if (attr == 'half_portion_old_price') {
      this.sections[index].digital_menu_section_items[
        item_ind
      ].half_portion_old_price = val;
    }
  }

  /* Mod */

  addSectionItemMod(index, item_ind) {
    let s_item = { name: null, price: null };
    this.sections[index].digital_menu_section_items[
      item_ind
    ].digital_menu_section_item_modifiers.push(s_item);
  }

  removeSectionItemMod(index, item_ind, item_mod_ind) {
    this.sections[index].digital_menu_section_items[
      item_ind
    ].digital_menu_section_item_modifiers.splice(item_mod_ind, 1);
  }

  addSectionItemModData(index, item_ind, item_mod_ind, attr, val) {
    console.log(this.sections);
    if (attr == 'name') {
      this.sections[index].digital_menu_section_items[
        item_ind
      ].digital_menu_section_item_modifiers[item_mod_ind].name = val;
    } else if (attr == 'price') {
      this.sections[index].digital_menu_section_items[
        item_ind
      ].digital_menu_section_item_modifiers[item_mod_ind].price = val;
    }
  }

  // Add Images

  addSectionImage(pic, id) {
    let data = { image: pic, section_id: id };
    this.spinner.show();
    this.menuPro.addDigitalMenuSectionImage(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Image added successfully!', '', {
          timeOut: 3000,
        });
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  addSectionItemImage(pic, id) {
    let data = { image: pic, item_id: id };
    this.spinner.show();
    this.menuPro.addDigitalMenuItemImage(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Image added successfully!', '', {
          timeOut: 3000,
        });
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  addSectionItemAlertImage(pic, id) {
    let data = { image: pic, item_id: id };
    this.spinner.show();
    this.menuPro.addDigitalMenuItemAlertImage(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Image added successfully!', '', {
          timeOut: 3000,
        });
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  addMenuLogo(pic) {
    let data = { logo: pic, digitalmenu_id: this.m_id };
    this.spinner.show();
    this.menuPro.addDigitalMenuLogo(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Logo added successfully!', '', {
          timeOut: 3000,
        });
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  chooseSection(val) {
    this.section = val;
  }

  removeItemAlertImage(item_id) {
    this.menuPro.deleteItemAlertImage(item_id).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Alert image deleted successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000,
        });
      }
    )

  }

  removeItemImage(item_id) {
    this.menuPro.deleteItemImage(item_id).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Item image deleted successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000,
        });
      }
    )

  }

  deselectOtherCategories(el: HTMLInputElement, checkbox_id, category_name, category_subtitle, category_sort) {
    const elements = document.getElementsByName("category_selection");
    for (let index = 0; index < elements.length; index++) {
      const e = <HTMLInputElement> elements[index]
      if (e.getAttribute("id") != el.getAttribute("id") && el.checked) {
        e.checked=false
      }
    }
    if (category_name) {
      const cat = <HTMLInputElement> document.getElementById("category");
      cat.value = category_name
    } else {
      const cat = <HTMLInputElement> document.getElementById("category");
      cat.value = null
    }
    if (category_subtitle) {
      const subtitle = <HTMLInputElement> document.getElementById("category_subtitle");
      subtitle.value = category_subtitle
    } else {
      const subtitle = <HTMLInputElement> document.getElementById("category_subtitle");
      subtitle.value = null
    }
    if (category_sort) {
      const sort = <HTMLInputElement> document.getElementById("category_sort");
      sort.value = category_sort
    } else {
      const sort = <HTMLInputElement> document.getElementById("category_sort");
      sort.value = null
    }
  }

  addCategory() {
    const category_name = (<HTMLInputElement>document.getElementById("category")).value;
    const category_subtitle = (<HTMLInputElement>document.getElementById("category_subtitle")).value;
    const category_sort = (<HTMLInputElement>document.getElementById("category_sort")).value;
    const category_selections = document.getElementsByName("category_selection");
    let category_selected = null
    for (let index = 0; index < category_selections.length; index++) {
      const e = <HTMLInputElement> category_selections[index]
      if (e.checked) {
        category_selected = e.getAttribute("id").replace("category_selected_", "")
      }
    }
    console.log(category_name);
    this.menuPro.addCategory(this.restaurant.digital_menu.id, category_name, category_subtitle, category_sort, category_selected).subscribe(
      (res) => {
        console.log(res);
        console.log(this.categories);
        this.categories = res['data'];
        console.log(this.categories);
        this.spinner.hide();
        this.toastr.success('Category added successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000,
        });
      }
    )
  }

  removeCategory(category_id: any) {
    this.menuPro.removeCategory(this.restaurant.digital_menu.id, category_id).subscribe(
      (res) => {
        console.log(res);
        console.log(this.categories);
        this.categories = res['data'];
        console.log(this.categories);
        this.spinner.hide();
        this.toastr.success('Category removed successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000,
        });
      }
    )
  }
  removeMenuLogo() {
    this.spinner.show();
    this.menuPro.removeMenuLogo(this.restaurant.digital_menu.id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Logo removed successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000,
        });
      }
    );
  }

  addMenuBackground(pic) {
    let data = { background: pic, digitalmenu_id: this.m_id };
    this.spinner.show();
    this.menuPro.addDigitalMenuBackground(data).subscribe(
      (res) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Background added successfully!', '', {
          timeOut: 3000,
        });
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  removeMenuBackground() {
    this.spinner.show();
    this.menuPro.removeMenuBackground(this.restaurant.digital_menu.id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Background removed successfully!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000,
        });
      }
    );
  }
}

