<div class="menu">
    <div class="row">
        <div class="col-md-8">
            <h1>Add Menu</h1>
            <div class="menu_form">
                <form #addM="ngForm" (ngSubmit)="addMenu(addM.value, men.files[0], log.files[0])">

                    <div class="form-group">
                        <label>Menu</label>
                        <input type="file" name="file" class="form-control" #men>
                        <a href="{{menu_content?.path}}" *ngIf="menu_content && menu_content.path"
                            target="_blank">View Current File</a>
                    </div>

                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" [(ngModel)]="name" name="name" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Logo</label>
                        <input type="file" name="logo" class="form-control" #log>
                        <a href="{{menu_content?.logo_path}}" *ngIf="menu_content && menu_content.logo_path"
                            target="_blank">View Current File</a>
                    </div>

                    <div class="form-group">
                        <label>Color</label>
                        <input type="color" [(ngModel)]="color" name="color" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Background Color</label>
                        <input type="color" [(ngModel)]="background_color" name="background_color" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Size</label>
                        <select [(ngModel)]="size" name="size" class="form-control">
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="600">600</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Style</label>
                        <select class="form-control" [(ngModel)]="style" name="style">
                            <option value="square">Square</option>
                            <option value="dot">Dot</option>
                            <option value="round">Round</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Eye Style</label>
                        <select class="form-control" [(ngModel)]="eye_style" name="eye_style">
                            <option value="square">Square</option>
                            <option value="circle">Circle</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Gradient Start Color</label>
                        <input type="color" [(ngModel)]="gradient_start_color" name="gradient_start_color"
                            class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Gradient End Color</label>
                        <input type="color" [(ngModel)]="gradient_end_color" name="gradient_end_color"
                            class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Gradient Type</label>
                        <select class="form-control" [(ngModel)]="gradient_type" name="gradient_type">
                            <option value="vertical">Vertical</option>
                            <option value="horizontal">Horizontal</option>
                            <option value="diagonal">Diagonal</option>
                            <option value="inverse_diagonal">Inverse Diagonal</option>
                            <option value="radial">Radial</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Facebook</label>
                        <input type="text" [(ngModel)]="facebook" name="facebook" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Instagram</label>
                        <input type="text" [(ngModel)]="instagram" name="instagram" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Survey</label>
                        <input type="text" [(ngModel)]="survey" name="survey" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Website</label>
                        <input type="text" [(ngModel)]="website" name="website" class="form-control">
                    </div>

                    <button class="btn btn-primary">Add Menu</button>
                </form>

            </div>
        </div>
        <div class="col-md-4 pi">
            <div class="qr_pic text-center">
                <h6 *ngIf="!qr">Please fill menu to get QR code</h6>
                <img *ngIf="qr" src="{{qr}}?rand={{rand}}" width="100%" height="auto" class="q"
                    routerLink="/menus/{{menu_content?.user_id}}">

                <div class="show_menu" *ngIf="menu">
                    <div class="container">
                        <div class="content">
                            <img *ngIf="isPdf != 'pdf'" src="{{menu?.path}}" width="100%" height="auto">
                            <!-- <a [href]="menu?.path" target="_blank" class="btn btn-primary mb-2">Open in new window</a> -->
                            <!-- <iframe *ngIf="url" [src]="url" style="width:100%; height:700px;" frameborder="0"></iframe> -->
                            <pdf-viewer *ngIf="isPdf == 'pdf'" [src]="menu?.path" [original-size]="false"
                                style="display: block;"></pdf-viewer>
                            <!-- <embed [src]="url" type="application/pdf" height="700" width="100%"> -->
                            <h3>{{menu?.name}}</h3>

                            <div *ngIf="menu?.survey">
                                <a href="{{menu?.survey}}" target="_balnk">Survey</a>
                            </div>

                            <div *ngIf="menu?.website">
                                <a href="{{menu?.website}}" target="_balnk">Website</a>
                            </div>

                            <div class="icons text-center">
                                <a *ngIf="menu?.facebook" href="{{menu?.facebook}}" target="_blank">
                                    <img src="assets/imgs/facebook.png" width="40" height="auto">
                                </a>
                                <a *ngIf="menu?.instagram" href="{{menu?.instagram}}" target="_blank">
                                    <img src="assets/imgs/instagram.png" width="40" height="auto">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>