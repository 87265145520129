<div class="login">
    <div class="head">
        <h1>Login</h1>
    </div>
    <div class="login_form">
        <input type="email" placeholder="Email">
        <input type="password" placeholder="Password">
        <p>Forget Password?</p>
        <button routerLink="/order">Login</button>
        <p>New here? <span routerLink="/order-register">Signup</span></p>
    </div>
</div>