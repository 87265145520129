<app-navbar [page]="'ORDERS'"></app-navbar>

<div class="orders" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="true"
    (scrolled)="onScroll($event)">
    <div class="container">
        <h1>All Orders</h1>
        <div class="order_table text-center">
            <div class="table_head">
                <div class="row">
                    <div class="col cell borderleft">
                        <h4>Order #</h4>
                    </div>
                    <div class="col cell">
                        <h4>Order</h4>
                    </div>
                    <div class="col cell">
                        <h4>Price</h4>
                    </div>
                    <!-- <div class="col cell">
                        <h4>Waiter</h4>
                    </div>
                    <div class="col cell">
                        <h4>Receipt</h4>
                    </div> -->
                    <div class="col cell">
                        <h4>Status</h4>
                    </div>
                    <!-- <div class="col">
                        <h4></h4>
                    </div> -->
                </div>
            </div>
            <div class="table_body">
                <div class="row" *ngFor="let order of orders; let or_ind = index">
                    <div class="col cell borderleft">
                        <p>{{order.id}}</p>
                    </div>
                    <div class="col cell">
                        <button routerLink="/base/foodcourts/restaurants/order-items/{{order.id}}">Check Order</button>
                    </div>
                    <div class="col cell">
                        <p>{{order.total}} EGP</p>
                    </div>
                    <!-- <div class="col cell" [class.active]="order.call_waiter" (click)="callWaiterOff(or_ind,order.id)">

                    </div>
                    <div class="col cell" [class.active]="order.ask_for_check"
                        (click)="requestReceiptOff(or_ind,order.id)">

                    </div> -->
                    <div class="col cell s" (click)="changeOrderStatus(order.status, order.id, or_ind)">
                        <p>{{order.status}}</p>
                        <!-- <select (change)="changeOrderStatus(or_status.value, order.id)" [(ngModel)]="order.status"
                            [ngModelOptions]="{standalone: true}" #or_status>
                            <option value="Paid">Paid</option>
                            <option value="Pending Payment">Pending Payment</option>
                            <option value="Cooking">Cooking</option>
                            <option value="Ready For Pickup">Ready For Pickup</option>
                            <option value="Picked Up">Picked Up</option>
                        </select> -->
                    </div>
                    <!-- <div class="col">
                        <button class="cancel" (click)="cancelOrder(order.id)">Cancel Order</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>