import { Component, OnInit, Inject } from '@angular/core';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-payment-popup',
  templateUrl: './choose-payment-popup.component.html',
  styleUrls: ['./choose-payment-popup.component.scss'],
})
export class ChoosePaymentPopupComponent implements OnInit {
  payment: any = 'visa';

  constructor(
    public dialogRef: MatDialogRef<any>,
    private foodcourtPro: FoodCourtProService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  choosePayment(val) {
    this.payment = val;
  }

  submitOrder() {
    this.data.payment_method = this.payment;
    this.spinner.show();
    this.foodcourtPro.addOrder(this.data).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        // this.order_id = res.Order[0].id;
        // localStorage.setItem('order_id', res.Order[0].id);
        // localStorage.setItem('t_all_order', JSON.stringify(res.Order[0]));
        this.toastr.success(res.Message, '', {
          timeOut: 3000,
        });
        this.dialogRef.close();
        localStorage.removeItem('t_order');
        this.updateOrder();
        if (this.payment == 'visa') {
          this.router.navigate(['/foodcourt/payment', res.fc_order[0].id]);
        } else {
        }
        // res.Order[0].order_items.forEach(element => {
        //   element.fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers = element.order_item_modifiers;
        //   element.fc_digital_menu_section_item.fc_digital_menu_section_item_modifiers.price = element.fc_digital_menu_section_item_modifier.price;
        // });
        // localStorage.setItem('t_order', JSON.stringify(res.Order[0].order_items));
        // this.updateOrder();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  updateOrder() {
    let profile = document.querySelector('#foot_or_btn') as HTMLElement;
    profile.click();
  }
}
