<form class="formContainer" dir="rtl">

    <ng-container *ngFor="let question of questions; let i = index">
        <div [hidden]="question.isHidden" class="form-group activeForm"> 
            <label class="labelArabic">{{question.label}}</label>

            <input [(ngModel)]="inputStr" name="inputStr" type="{{question.type}}" class="form-control" placeholder="{{question.placeholder}}"
                *ngIf="question.type != 'radio'" (ngModelChange)="modelChange($event)" dir="rtl">

            <div *ngIf="question.type == 'radio'" class="radioContainer">
                <div class="form-check form-check-inline" *ngFor="let value of question.values">
                    <label class="form-check-label" class="labelArabic">{{value.display}}</label>
                    <input class="form-check-input" type="radio" value="{{value.value}}" [(ngModel)]="inputStr" (ngModelChange)="modelChange($event)" name="{{value.value}}">
                </div>
            </div>


            <div class="btnContainer">
                <button type="button" class="btn btn-primary"
                    (click)="questions[i].isHidden = true; questions[i+1] ? questions[i+1].isHidden = false : surveyFinished = true" (click)="inputStr = ''" [disabled]="inputStr == ''" >
                    Next
                </button>
            </div>
        </div>

       
    </ng-container>


    <div *ngIf="surveyFinished" class="surveyFinished">
        <p>شكرا لك علي تقديم الإستبيان</p>
    </div>


</form>