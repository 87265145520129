<div class="home-page">
  <div class="header">
    <h1 class="title">Home</h1>
  </div>
  <div class="offers container">
    <h2>offers</h2>
    <owl-carousel-o [options]="customOptions">
      <ng-container>
        <ng-template
          carouselSlide
          *ngFor="let offer of offers"
          [id]="offer.name"
          [width]="150"
        >
          <div class="card" routerLink="/foodcourt/menu/item/{{ offer.id }}">
            <div class="card__image">
              <img [src]="offer.image_path" [alt]="offer.name" />
            </div>
            <div class="container d-flex flex-column align-items-start">
              <div class="card__title">
                {{ offer.name }}
              </div>
              <div class="card__content">
                <p class="old-price">
                  {{ offer.old_price | currency: "EGP":"symbol" }}
                </p>
                <p>{{ offer.new_price | currency: "EGP":"symbol" }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <div class="container offersContainer" *ngIf="!offers?.length">
      <p>Stay tuned with our latest offers</p>
    </div>
  </div>
</div>
