import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class FoodCourtProService {
  deviceInfo: any;
  device_id: any;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {}

  addFoodCourtAdmin(data) {
    let url = environment.url;
    return this.http.post(`${url}api/add/fcadmin`, data);
  }

  getFoodCourts() {
    let url = environment.url;
    return this.http.get(`${url}api/my/fcs`);
  }

  getFoodCourt(id) {
    let url = environment.url;
    return this.http.get(`${url}api/get/fc/${id}`);
  }

  deleteFoodCourt(id) {
    let url = environment.url;
    return this.http.get(`${url}api/delete/fc/${id}`);
  }

  addFoodCourt(data) {
    let url = environment.url;
    return this.http.post(`${url}api/add/fc`, data);
  }

  editFoodCourt(data) {
    let url = environment.url;
    return this.http.post(`${url}api/edit/fc`, data);
  }

  // Restaurants

  addFoodCourtRestaurantAdmin(data) {
    let url = environment.url;
    return this.http.post(`${url}api/add/fcrestadmin`, data);
  }

  getFoodCourtRestaurants() {
    let url = environment.url;
    return this.http.get(`${url}api/my/fcrestaurants`);
  }

  getFoodCourtAllRestaurants(id) {
    let url = environment.url;
    return this.http.get(`${url}api/all/fcrestaurants/${id}`);
  }

  getFoodCourtRestaurant(id) {
    let url = environment.url;
    return this.http.get(`${url}api/fcrestaurant/${id}`);
  }

  deleteFoodCourtRestaurant(id) {
    let url = environment.url;
    return this.http.get(`${url}api/delete/fcrestaurant/${id}`);
  }

  addFoodCourtRestaurant(data) {
    let url = environment.url;
    return this.http.post(`${url}api/add/fcrestaurant`, data);
  }

  editFoodCourtRestaurant(data) {
    let url = environment.url;
    return this.http.post(`${url}api/edit/fcrestaurant`, data);
  }

  // Digital Menu

  getDigitalMenu(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/fcdigitalmenu/${id}`);
    return res;
  }

  addDigitalMenu(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/fcdigitalmenu`, data);
    return res;
  }

  editDigitalMenu(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/edit/fcdigitalmenu`, data);
    return res;
  }

  deleteDigitalMenu(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/delete/fcdigitalmenu/${id}`);
    return res;
  }

  addDigitalMenuSectionImage(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    return this.http.post(`${url}api/add/fcsectionimage`, formData);
  }

  addDigitalMenuLogo(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });
    return this.http.post(`${url}api/add/fcmenulogo`, formData);
  }

  addDigitalMenuItemImage(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    return this.http.post(`${url}api/add/fcitemimage`, formData);
  }

  getUserDigitalMenu(id) {
    let d_id = localStorage.getItem('device_id');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (d_id) {
      this.device_id = d_id;
    } else {
      let d_id = Math.random() * 10000000000000000;
      localStorage.setItem('device_id', JSON.stringify(d_id));
      this.device_id = JSON.stringify(d_id);
    }
    let url = environment.url;
    let res = this.http.post(`${url}api/get/fcdigitalmenu`, {
      menu_id: id,
      device_id: this.device_id,
      device_info: JSON.stringify(this.deviceInfo),
    });
    return res;
  }

  getUserDigitalMenuItem(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/get/fcitem/${id}`);
    return res;
  }

  addOrder(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add/fcorder`, data);
    return res;
  }

  editOrder(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/edit/fcorder`, data);
    return res;
  }

  cancelOrder(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/cancel/fcorder/${id}`);
    return res;
  }

  deleteOrder(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/delete/fcorder/${id}`);
    return res;
  }

  checkTable(res_id, table_num) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/check/fcemptytable/${res_id}/${table_num}`
    );
    return res;
  }

  checkPassCode(res_id, table_num, pass_code) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/check/fcpasscode/${res_id}/${table_num}/${pass_code}`
    );
    return res;
  }

  getRestaurantOrders(res_id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/activeorders/fcrestaurant/${res_id}`);
    return res;
  }

  getRestaurantOrdersHistory(res_id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/ordershistory/fcrestaurant/${res_id}`);
    return res;
  }

  getRestaurantOrderItems(or_id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/get/fcorder/${or_id}`);
    return res;
  }

  getNextPage(url) {
    return this.http.get(url);
  }

  callWaiter(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/call/fcwaiter/${id}`);
    return res;
  }

  requestReceipt(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/ask/fccheck/${id}`);
    return res;
  }

  callWaiterOff(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/call/fcwaiter/off/${id}`);
    return res;
  }

  requestReceiptOff(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/ask/fccheck/off/${id}`);
    return res;
  }

  editOrderStatus(id, status) {
    let url = environment.url;
    let res = this.http.post(`${url}api/change/fcorderstatus`, {
      order_id: id,
      status: status,
    });
    return res;
  }

  getIframe(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/generate/fcpayment/${id}`);
    return res;
  }

  retrieveOrder(code) {
    let url = environment.url;
    let res = this.http.get(`${url}api/retrieve/fcorder/${code}`);
    return res;
  }
}
