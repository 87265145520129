import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-current',
  templateUrl: './order-current.component.html',
  styleUrls: ['./order-current.component.scss']
})
export class OrderCurrentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
