<div class="login" *ngIf="verified">
    <div class="container">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="log">
                    <form #passForm="ngForm" (ngSubmit)="changePassword(passForm.value)">
                        <div class="form-group">
                            <label>New Password</label>
                            <input type="password" class="form-control" ngModel name="password" required>
                        </div>
                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input type="password" class="form-control" ngModel name="password_confirmation" required>
                        </div>
                        <button class="btn btn-primary" [disabled]="!passForm.valid">Change Password</button>
                    </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</div>