<app-navbar [page]="'ADD RESTAURANT ADMIN'"></app-navbar>

<div class="add-restaurant-admin">
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="rest">
          <form
            [formGroup]="restaurantAdminForm"
            (ngSubmit)="addRestaurantAdmin()"
          >
            <div class="txt">
              <label>Name</label>
              <input
                class="txt_in"
                type="text"
                formControlName="name"
                name="name"
                required
              />
            </div>
            <div class="txt">
              <label>Email</label>
              <input
                class="txt_in"
                type="email"
                formControlName="email"
                name="email"
                required
              />
            </div>
            <div class="txt">
              <label>Password</label>
              <input
                class="txt_in"
                type="password"
                formControlName="password"
                name="password"
                required
              />
            </div>
            <button
              class="btn btn-primary"
              [disabled]="!restaurantAdminForm.valid"
            >
              Add Restaurant Admin
            </button>
          </form>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</div>
