import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodesProService } from '../qr-codes-pro.service';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  data: any;
  rand: any = Math.random();

  constructor(
    private spinner: NgxSpinnerService,
    private _FileSaverService: FileSaverService,
    private _http: HttpClient,
    private qrcodesPro: QrCodesProService) { }

  ngOnInit(): void {
    this.getDashboardDetails();
  }

  getDashboardDetails() {
    this.spinner.show();
    this.qrcodesPro.getDashboardDetails()
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.data = res;
        this.rand = Math.random();
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onDown(file) {
    // const fileName = `save.png`;
    this._http.get(`${file}`, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this._FileSaverService.save(res.body);
    });

  }

}
