<app-navbar [page]="'ANALYTICS'"></app-navbar>
<div class="scans text-center">

    <div class="row">
        <div class="col-md-4">
            <div class="text-left">
                <label>Number Of Days</label>
                <input type="number" [(ngModel)]="days" class="form-control">
            </div>
        </div>
        <div class="col-md-2">
            <button class="btn btn-primary" (click)="getScans(days)">Done</button>
        </div>
    </div>

    <p>QR code was scanned {{scans?.count}} times</p>

    <div>
        <canvas *ngIf="scan_counts" baseChart width="800" height="300" [datasets]="lineChartData"
            [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
            [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
        </canvas>
    </div>
</div>