<div class="home">
  <div class="header">
    <h1>Order</h1>
  </div>
  <div class="body">
    <h2>Your Order</h2>
    <div class="or_head mb-2">
      <span [class.active]="c_order == 'cart'" (click)="showOrder('cart')"
        >Cart</span
      >
      <span [class.active]="c_order == 'order'" (click)="showOrder('order')"
        >Order</span
      >
    </div>

    <ng-container *ngIf="c_order == 'cart'">
      <ng-container *ngIf="items?.length != 0">
        <div class="item" *ngFor="let item of items; let i = index">
          <div class="row">
            <div class="col-6 nopadding">
              <h6>{{ item.fc_digital_menu_section_item.name }}</h6>
              <p *ngIf="!hasMod(i)">
                Would you like to add any modifiers?<br /><span
                  routerLink="/foodcourt/menu/item/{{
                    item.fc_digital_menu_section_item.id
                  }}"
                  >Click Here</span
                >
              </p>
              <p *ngIf="hasMod(i)">
                <ng-container
                  *ngFor="
                    let mod of item.fc_digital_menu_section_item
                      .fc_digital_menu_section_item_modifiers
                  "
                >
                  <ng-container *ngIf="mod.quantity > 0"
                    >{{ mod.name }} |
                  </ng-container>
                </ng-container>
              </p>
            </div>
            <div class="col-4 text-center nopadding">
              <h5>
                {{
                  item.fc_digital_menu_section_item.price
                    | currency: "EGP":"symbol"
                }}
                ({{ item.quantity }})
              </h5>
            </div>
            <div class="col-2 text-right">
              <span class="c text-center" (click)="removeItem(i)">X</span>
            </div>
          </div>
        </div>

        <div class="total">
          <div class="row">
            <div class="col-6 nopadding">
              <h6>Total</h6>
            </div>
            <div class="col-6 text-right nopadding">
              <h4 class="mr-1">{{ total | currency: "EGP":"symbol" }}</h4>
            </div>
          </div>
        </div>

        <!-- <div class="table" *ngIf="!order_id">
                    <ng-container *ngIf="status == 'check_table'">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" placeholder="Table Number" [(ngModel)]="table_number">
                            </div>
                            <div class="col-4">
                                <button (click)="checkTable(table_number)" [disabled]="!table_number">Check</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="status != 'check_table'">
                        <div class="row">
                            <div class="col-8">
                                <input type="number" placeholder="Pass Code" [(ngModel)]="pass_code">
                            </div>
                            <div class="col-4">
                                <button (click)="checkPassCode(pass_code)" [disabled]="!pass_code"
                                    *ngIf="status == 'check_pass'">Check</button>
                                <button (click)="addOrder(pass_code)" [disabled]="!pass_code"
                                    *ngIf="status == 'empty_table'">Order</button>
                            </div>
                        </div>
                    </ng-container>
                </div> -->

        <div class="table text-right">
          <div class="row">
            <div class="col">
              <button (click)="addOrder()">Complete Order</button>
            </div>
          </div>
          <!-- <button routerLink="/order/payment">Pay Now</button> -->
        </div>
      </ng-container>

      <!-- <ng-container *ngIf="order_id">
                <div class="timerr">
                    <div class="row">
                        <div class="col-4 nopadding">
                            <h6>Timer</h6>
                            <h6 *ngIf="order_status">Status <br> <span>{{order_status}}</span></h6>
                        </div>
                        <div class="col-8 text-right nopadding">
                            <p><span>{{minutes}}</span> : <span>{{seconds}}</span></p>
                        </div>
                    </div>
                </div>
            </ng-container> -->

      <div class="no text-center" *ngIf="items && items?.length == 0">
        <p>Cart is empty!</p>
      </div>
    </ng-container>

    <ng-container *ngIf="c_order == 'order'">
      <ng-container *ngIf="cur_order && cur_order?.length != 0">
        <ng-container *ngFor="let cur of cur_order; let o_ind = index">
          <div
            class="item"
            *ngFor="let item of cur.fc_order_items; let i = index"
          >
            <div class="row">
              <div class="col-8 nopadding">
                <div class="item-name">
                  <strong>x{{ item.quantity }}</strong>
                  <h6>
                    {{ item?.fc_digital_menu_section_item?.name }}
                    <strong>#{{ cur?.id }}</strong>
                  </h6>
                </div>
                <p *ngIf="ohasMod(i, o_ind)">
                  <ng-container *ngFor="let mod of item.order_item_modifiers">
                    <ng-container *ngIf="mod.quantity > 0"
                      >{{ mod.name }} |</ng-container
                    >
                  </ng-container>
                </p>
              </div>
              <div class="col-4 text-right nopadding">
                <h5>
                  {{
                    item.fc_digital_menu_section_item.price
                      | currency: "EGP":"symbol"
                  }}
                </h5>
              </div>
            </div>
          </div>
          <hr />
          <div class="total">
            <div class="row">
              <div class="col-6 nopadding">
                <h6>Total</h6>
              </div>
              <div class="col-6 text-right nopadding">
                <h4>{{ cur.total | currency: "EGP":"symbol" }}</h4>
              </div>
            </div>
          </div>

          <ng-container>
            <div class="timerr">
              <div class="row">
                <div class="col-7 nopadding">
                  <h6 *ngIf="cur.status">
                    <span>Your order is</span>
                    <strong>{{ cur.status }}</strong>
                  </h6>
                  <h6 class="message">
                    <span *ngIf="cur.status == 'Pending Payment'">
                      Please pay to start preparing your order
                    </span>
                    <span *ngIf="cur.status == 'Ready For Pickup'">
                      Please pick up your order
                    </span>
                  </h6>
                </div>
                <div class="col-5 text-right nopadding">
                  <p>
                    <span>{{ timer[o_ind].minutes }}</span> :
                    <span>{{ timer[o_ind].seconds }}</span>
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="message">
                <div class="col nopadding">
                  <h5 *ngIf="message" class="mt-2">
                    {{ message }} (#{{ cur.id }})
                  </h5>
                  <button
                    routerLink="/foodcourt/payment/{{ cur.id }}"
                    *ngIf="cur.status == 'Queued'"
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <div
        class="no text-center table"
        *ngIf="!cur_order || cur_order?.length == 0"
      >
        <ng-container *ngIf="status == 'check_table'">
          <div class="row mb-3 mt-3">
            <div class="col-8">
              <input
                type="number"
                placeholder="Order Code"
                [(ngModel)]="order_code"
              />
            </div>
            <div class="col-4">
              <button
                (click)="retrieveOrder(order_code)"
                [disabled]="!order_code"
              >
                Check
              </button>
            </div>
          </div>
        </ng-container>
        <p>No orders yet!</p>
      </div>
    </ng-container>
  </div>
</div>
