import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QrCodesProService {
  constructor(public http: HttpClient) {}

  addQrCode(form) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    console.log('form ', form);
    console.log('formdata ', formData);
    return this.http.post(`${url}api/user_qrcode`, formData);
  }

  updateQrCode(form, type) {
    let url = environment.url;
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
      // console.log(key, form[key]);
    });
    console.log(formData);
    if (type == 'survey') {
      return this.http.post(`${url}api/survey/edit`, formData);
    } else {
      return this.http.post(`${url}api/user_qrcode/edit`, formData);
    }
  }

  getQrCodes(val) {
    let url = environment.url;
    let res = this.http.get(`${url}api/user_qrcodes?name=${val}`);
    return res;
  }

  getSurveys(val) {
    let url = environment.url;
    let res = this.http.get(`${url}api/user/surveys?name=${val}`);
    return res;
  }

  getNextPage(url) {
    return this.http.get(url);
  }

  getQrCodeDetails(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/user_qrcode/${id}`);
    return res;
  }

  getDashboardDetails() {
    let url = environment.url;
    let res = this.http.get(`${url}api/dashboard`);
    return res;
  }

  getSurveyAnswers(data, index) {
    let url = environment.url;
    let res = this.http.post(`${url}api/survey/questionstats?page=${index}`, data);
    return res;
  }

  getSurveySheet(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/export_survey_answers?qrcode_id=${id}`);
    return res;
  }

  getSurveyAnswersChart(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/survey/stats`, data);
    return res;
  }

  createSurvey(surveyType, surveyName, surveyQuestions): Observable<any> {
    let url = environment.url;
    return this.http
      .post<any>(`${url}api/user_qrcode`, {
        type: surveyType,
        name: surveyName,
        questions: JSON.stringify(surveyQuestions),
      })
      .pipe(
        map((res) => {
          console.log(res);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  submitSurvey(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/answer/survey`, data);
    return res;
  }

  deleteQrCode(id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete_user_qrcode/${id}`, {});
    return res;
  }

  addRating(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/rate_qrcode`, data);
    return res;
  }

  getRating(id) {
    let url = environment.url;
    let res = this.http.get(`${url}api/qrcode_rating?qrcode_id=${id}`);
    return res;
  }

  // Group

  getQrGroups() {
    let url = environment.url;
    let res = this.http.get(`${url}api/qrcode_groups`);
    return res;
  }

  addQrGroup(data) {
    let url = environment.url;
    let res = this.http.post(`${url}api/create_qrcode_group`, data);
    return res;
  }

  getGroupQrs(id, val) {
    let url = environment.url;
    let res = this.http.get(
      `${url}api/qrcode_group_qrcodes?qrcode_group_id=${id}&name=${val}`
    );
    return res;
  }

  deleteQrGroup(id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/delete_qrcode_group`, {
      qrcode_group_id: id,
    });
    return res;
  }

  changeGroup(qr_id, value) {
    let url = environment.url;
    let res = this.http.post(`${url}api/add_qrcode_to_group`, {
      qrcode_id: qr_id,
      qrcode_group_id: value
    });
    return res;
  }

  removeFile(qr_id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/qrcode/remove_file`, {
      qrcode_id: qr_id,
    });
    return res;
  }

  removeLogo(qr_id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/qrcode/remove_logo`, {
      qrcode_id: qr_id,
    });
    return res;
  }

  removeBackground(qr_id) {
    let url = environment.url;
    let res = this.http.post(`${url}api/qrcode/remove_background`, {
      qrcode_id: qr_id,
    });
    return res;
  }

  suspendQrcode(qr_id, value) {
    let url = environment.url;
    let res = this.http.post(`${url}api/qrcode/suspend`, {
      qrcode_id: qr_id,
      value: value
    });
    return res;
  }
}
