import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-fc-order-items-edit',
  templateUrl: './fc-order-items-edit.component.html',
  styleUrls: ['./fc-order-items-edit.component.scss']
})
export class FcOrderItemsEditComponent implements OnInit {

  id: any;
  order: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.id = params.or_id;
      if (this.id) {
        this.getRestaurantOrderItems(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurantOrderItems(id) {
    this.spinner.show();
    this.foodcourtPro.getRestaurantOrderItems(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.order = res.Order[0];
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  editOrder() {
    this.spinner.show();
    this.foodcourtPro.editOrder(this.order)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Order Updated Successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  addItem(item_ind) {
    this.order.order_items[item_ind].quantity = this.order.order_items[item_ind].quantity + 1;
  }

  removeOrderItem(item_ind) {
    this.order.order_items.splice(item_ind, 1);
  }

  removeItem(item_ind) {
    if (this.order.order_items[item_ind].quantity > 0) {
      this.order.order_items[item_ind].quantity = this.order.order_items[item_ind].quantity - 1;
    }
  }

  addItemMod(item_ind, mod_ind) {
    this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity = this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity + 1;
  }

  removeItemMod(item_ind, mod_ind) {
    if (this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity > 0) {
      this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity = this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity - 1;
    }
  }

}
