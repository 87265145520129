<div class="water_cumulative">
  <div class="top" id="top">
    <div class="logo">
      <img
        src="assets/imgs/icons/logowhite.svg"
        width="50"
        height="auto"
        class="l"
      />
      <img *ngIf="logo_path != null" src="{{logo_path}}" class="log" width="120" />
    </div>
    <div class="cls"></div>
    <div class="wel">
      <h5>Welcome to</h5>
      <h4> {{menu.name}}</h4>
      <h6>{{ qrcodes_title || 'Choose your restaurant' }}</h6>
    </div>
    <div class="buttons">
      <div class="row">
        <div div class="col-4" *ngFor="let b of qrButtons; let i = index">
          <a href="{{ b.value }}?back=true">
            <img src="{{ getImage(i) }}" width="100%" />
          </a>
        </div>
      </div>
    </div>
    <div class="social text-center">
      <div class="s">
        <a href="{{ getLink(survey) }}" *ngIf="survey"
          >CLICK HERE AND GIVE US YOUR OPINION</a
        >
      </div>
      <div class="icons">
        <a href="{{ getLink(instagram) }}" target="_blank" *ngIf="instagram">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="{{ getLink(website) }}" target="_blank" *ngIf="website">
          <i class="fas fa-globe"></i>
        </a>
      </div>
    </div>
    <!-- <div class="swipe text-center" [routerLink]='"."' [fragment]="'bottom'">
            <p>Swipe up for menu</p>
            <i class="fa fa-chevron-down"></i>
        </div> -->
  </div>
  <!-- <div class="bottom" id="bottom">
    <div class="f" [class.show_f]="show_f">
      <div class="row">
        <div class="col-6">
          <div class="b_home" [routerLink]="'.'" [fragment]="'top'">HOME</div>
        </div>
        <div class="col-6">
          <div class="c_side">
            <div class="logo" id="s_logo" (click)="show('s_logo', 'i_logo')">
              <img src="{{ logo_path }}" width="50" height="50" />
            </div>
            <div class="icons text-center" id="i_logo">
              <a
                href="{{ getLink(instagram) }}"
                target="_blank"
                *ngIf="instagram"
                ><img src="assets/imgs/inst1.jpg" width="25" height="auto"
              /></a>
              <a href="{{ getLink(facebook) }}" target="_blank" *ngIf="facebook"
                ><img src="assets/imgs/face1.jpg" width="25" height="auto"
              /></a>
              <a href="{{ getLink(website) }}" target="_blank" *ngIf="website"
                ><img src="assets/imgs/site1.jpg" width="25" height="auto"
              /></a>
              <a href="{{ getLink(survey) }}" *ngIf="survey"
                ><img src="assets/imgs/sur1.jpg" width="25" height="auto"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img
      *ngIf="isPdf != 'pdf'"
      src="{{ file_path }}"
      width="100%"
      height="auto"
    />
    <pdf-viewer
      *ngIf="isPdf == 'pdf'"
      [src]="file_path"
      [original-size]="true"
      [fit-to-page]="true"
      [render-text]="true"
      [autoresize]="true"
      (after-load-complete)="callBackFn($event)"
      style="display: block"
    >
    </pdf-viewer>
  </div> -->

  <!-- <div class="spin" id="spin">
        <img class="loader-spinner" src="{{logo_path}}" />
    </div> -->
</div>
