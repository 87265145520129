<app-navbar [page]="'ADD RESTAURANT'"></app-navbar>

<div class="rests">
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="rest">
          <form #resForm="ngForm" (ngSubmit)="addRestaurantData(resForm.value)">
            <div class="file">
              <div class="f_in">+ Add Logo</div>
              <input type="file" (change)="addFile($event.target.files)" />
            </div>
            <div class="txt" *ngIf="restaurant && restaurant.logo_path">
            <a href="{{restaurant.logo_path}}" target="_blank" *ngIf="restaurant.logo_path">Current Logo </a>
              <span class="button delete" (click)="removeItemRestaurantLogo(restaurant.id)" style="display:inline-block; margin-left: 2px;">
                Remove Logo Image</span>
                </div>
            <div class="txt">
              <label>Name</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="name"
                name="name"
                required
              />
            </div>
            <div class="txt">
              <label>Description</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="description"
                name="description"
                required
              />
              <div class="txt">
                <label>User</label>
                <select
                  [(ngModel)]="admin_id"
                  name="admin_id"
                  class="txt_in"
                >
                  <option value="">No User</option>
                  <option value="{{ u.id }}" *ngFor="let u of users">
                    {{ u.name }} &nbsp; - &nbsp; {{u.id}}
                  </option>
                </select>
              </div>
            </div>
            <div class="txt">
              <label>Location</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="location"
                name="location"
                required
              />
            </div>
            <div class="txt">
              <label>Api Key</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="api_key"
                name="api_key"
              />
            </div>
            <div class="txt">
              <label>Merchant Id</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="merchant_id"
                name="merchant_id"
              />
            </div>
            <div class="txt">
              <label>IFrame</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="iframe_id"
                name="iframe_id"
              />
            </div>
            <div class="txt">
              <label>Integration Id</label>
              <input
                type="number"
                class="txt_in"
                [(ngModel)]="integration_id"
                name="integration_id"
              />
            </div>
            <div class="color">
              <label>Primary Color</label>
              <div class="box">
                <input
                  type="color"
                  [(ngModel)]="primary_color"
                  name="primary_color"
                />
                <div
                  class="left"
                  [style.background-color]="primary_color"
                ></div>
                <div class="right">
                  <span>{{ primary_color }}</span>
                </div>
              </div>
            </div>

            <div class="color">
              <label>Secondary Color</label>
              <div class="box">
                <input
                  type="color"
                  [(ngModel)]="secondary_color"
                  name="secondary_color"
                />
                <div
                  class="left"
                  [style.background-color]="secondary_color"
                ></div>
                <div class="right">
                  <span>{{ secondary_color }}</span>
                </div>
              </div>
            </div>
            <div>
              <label>Sections Font</label>
              <div class="box">
                <select
                [(ngModel)]="sections_font"
                name="sections_font">
                <option value="montecatini_pro_stretto_regular">Montecatini Pro (Stretto Regular)</option>
                <option value="herman_bold">Herman (Bold)</option>
                <option value="verdana">Verdana</option>
                <option value="trebuchet_ms">Trebuchet MS</option>
                <option value="arimo">Arimo</option>
                <option value="bebas_neue">Bebas Neue</option>
                <option value="cabin">Cabin</option>
                <option value="dm_sans">DM Sans</option>
                <option value="inria_sans">inria Sans</option>
                <option value="josefin_sans">Josefin Sans</option>
                <option value="lobster">Lobster</option>
                <option value="museo_moderno">Museo Moderno</option>
                <option value="poppins">Poppins</option>
                <option value="protest_strike">Protest Strike</option>
                <option value="pt_serif">PT Serif</option>
                <option value="red_hat">Red Hat Display</option>
                <option value="teko">Tekko</option>

                </select>

              </div>
            </div>
            <div>
              <label>Items Font</label>
              <div class="box">

                <select
                [(ngModel)]="items_font"
                name="items_font">
                  <option value="montecatini_pro_stretto_regular">Montecatini Pro (Stretto Regular)</option>
                  <option value="herman_bold">Herman (Bold)</option>
                  <option value="verdana">Verdana</option>
                  <option value="trebuchet_ms">Trebuchet MS</option>
                  <option value="arimo">Arimo</option>
                  <option value="bebas_neue">Bebas Neue</option>
                  <option value="cabin">Cabin</option>
                  <option value="dm_sans">DM Sans</option>
                  <option value="inria_sans">inria Sans</option>
                  <option value="josefin_sans">Josefin Sans</option>
                  <option value="lobster">Lobster</option>
                  <option value="museo_moderno">Museo Moderno</option>
                  <option value="poppins">Poppins</option>
                  <option value="protest_strike">Protest Strike</option>
                  <option value="pt_serif">PT Serif</option>
                  <option value="red_hat">Red Hat Display</option>
                  <option value="teko">Tekko</option>
                </select>

              </div>
            </div>
            <div class="order-mode">
              <label for="dine_in"
                >Dine-in
                <input
                  type="checkbox"
                  name="dine_in"
                  id="dine_in"
                  [(ngModel)]="dine_in"
                />
              </label>
              <label for="delivery"
                >Delivery
                <input
                  type="checkbox"
                  name="delivery"
                  id="delivery"
                  [(ngModel)]="delivery"
                />
              </label>
              <label for="pick_up"
                >Pick-up
                <input
                  type="checkbox"
                  name="pick_up"
                  id="pick_up"
                  [(ngModel)]="pick_up"
                />
              </label>
            </div>

            <div class="txt">
              <label>Facebook Link</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="facebook"
                name="facebook"
              />
            </div>
            <div class="txt">
              <label>Instagram Link</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="instagram"
                name="instagram"
              />
            </div>
            <div class="txt">
              <label>Survey Link</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="survey"
                name="survey"
              />
            </div>
            <div class="txt">
              <label>Website Link</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="website"
                name="website"
              />
            </div>
            <!-- <div class="txt">
              <label>Service Link</label>
              <input
                type="text"
                class="txt_in"
                [(ngModel)]="service"
                name="service"
              />
            </div> -->

            <button class="btn btn-primary" [disabled]="!resForm.valid">
              Add Restaurant
            </button>
          </form>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</div>
