<app-navbar [page]="'Profile'"></app-navbar>
<div class="profile">
    <div class="box">
        <p *ngIf="user?.user?.name"><span>Username:</span>{{user?.user?.name}}</p>
        <p *ngIf="user?.user?.email"><span>Email:</span>{{user?.user?.email}}</p>
        <p *ngIf="user?.user?.role"><span>Role:</span>{{user?.user?.role}}</p>
        <!-- <p *ngIf="user?.count of qr codes"><span>Count of qr codes:</span>{{user?.count of qr codes}}</p> -->
        <!-- <p *ngIf="user?.total scans today"><span>Total scans today:</span>{{user?.total scans today}}</p> -->
    </div>
    <div class="box">
        <h2>Change Password</h2>
        <form #chForm="ngForm" (ngSubmit)="changePassword(chForm.value)">
            <div class="txt">
                <!-- <label>Old Password</label> -->
                <input type="password" placeholder="Old Password" class="txt_in" ngModel name="old_password" required>
            </div>
            <div class="txt">
                <!-- <label>New Password</label> -->
                <input type="password" placeholder="New Password" class="txt_in" ngModel name="new_password" required>
            </div>
            <div class="txt">
                <!-- <label>New Password</label> -->
                <input type="password" placeholder="Confirm Password" class="txt_in" ngModel name="new_password_confirmation" required>
            </div>
            <button [disabled]="!chForm.valid">Confirm</button>
        </form>
    </div>
</div>