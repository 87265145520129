import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-restaurant',
  templateUrl: './add-restaurant.component.html',
  styleUrls: ['./add-restaurant.component.scss'],
})
export class AddRestaurantComponent implements OnInit {
  id: any;
  name: any;
  description: any;
  admin_id: any;
  location: any;
  restaurant: any;
  api_key: any;
  merchant_id: any;
  iframe_id: any;
  integration_id: any;
  logo: File = null;
  primary_color: any;
  secondary_color: any;
  sections_font: any;
  items_font: any;
  dine_in = false;
  pick_up = false;
  delivery = false;
  facebook: any;
  instagram: any;
  website: any;
  survey: any;

  users: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private menuPro: MenuProService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getRestaurant(this.id);
      }
    });
  }

  ngOnInit(): void {}

  getRestaurant(id) {
    this.spinner.show();
    this.menuPro.getRestaurant(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.restaurant = res;
        this.name = this.restaurant.name;
        this.description = this.restaurant.description;
        this.users = this.restaurant.users;
        this.admin_id = this.restaurant.admin_id;
        this.location = this.restaurant.location;
        this.api_key = this.restaurant.api_key;
        this.merchant_id = this.restaurant.merchant_id;
        this.integration_id = this.restaurant.integration_id;
        this.iframe_id = this.restaurant.iframe_id;
        this.primary_color = this.restaurant.primary_color;
        this.secondary_color = this.restaurant.secondary_color;
        this.sections_font = this.restaurant.sections_font;
        this.items_font = this.restaurant.items_font;
        this.dine_in = this.restaurant.dine_in;
        this.pick_up = this.restaurant.pick_up;
        this.delivery = this.restaurant.delivery;
        this.facebook = this.restaurant.facebook_link;
        this.instagram = this.restaurant.instagram_link;
        this.survey = this.restaurant.survey_link;
        this.website = this.restaurant.webiste_link;
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  addRestaurantData(data) {
    console.log(data)
    if (this.id) {
      data.restaurant_id = this.id;
      this.editRestaurant(data);
    } else {
      this.addRestaurant(data);
    }
  }

  addRestaurant(data) {
    console.log(data);
    const uploadedData = new FormData();
    uploadedData.append('name', data.name);
    uploadedData.append('description', data.description);
    uploadedData.append('location', data.location);
    uploadedData.append('api_key', data.api_key);
    uploadedData.append('merchant_id', data.merchant_id);
    uploadedData.append('integration_id', data.integration_id);
    uploadedData.append('primary_color', data.primary_color);
    uploadedData.append('secondary_color', data.secondary_color);
    uploadedData.append('sections_font', data.sections_font);
    uploadedData.append('items_font', data.items_font);
    uploadedData.append('iframe_id', data.iframe_id);
    uploadedData.append('logo', this.logo);
    uploadedData.append(
      'dine_in',
      JSON.stringify(this.dine_in) || JSON.stringify(data.dine_in)
    );
    uploadedData.append(
      'pick_up',
      JSON.stringify(this.pick_up) || JSON.stringify(data.pick_up)
    );
    uploadedData.append(
      'delivery',
      JSON.stringify(this.delivery) || JSON.stringify(data.delivery)
    );
    uploadedData.append('facebook_link', data.facebook);
    uploadedData.append('instagram_link', data.instagram);
    uploadedData.append('survey_link', data.survey);
    uploadedData.append('webiste_link', data.website);
    this.spinner.show();
    this.menuPro.addRestaurant(uploadedData).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Restaurant added successfully!', '', {
          timeOut: 3000,
        });
        window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  editRestaurant(data) {
    console.log(data);
    this.spinner.show();
    const uploadedData = new FormData();
    uploadedData.append('restaurant_id', data.restaurant_id);
    uploadedData.append('name', data.name);
    uploadedData.append('description', data.description);
    uploadedData.append('location', data.location);
    uploadedData.append('api_key', data.api_key);
    uploadedData.append('merchant_id', data.merchant_id);
    uploadedData.append('integration_id', data.integration_id);
    uploadedData.append('primary_color', data.primary_color);
    uploadedData.append('secondary_color', data.secondary_color);
    uploadedData.append('sections_font', data.sections_font);
    uploadedData.append('items_font', data.items_font);
    uploadedData.append('iframe_id', data.iframe_id);
    uploadedData.append('admin_id', data.admin_id);
    uploadedData.append('logo', this.logo);
    uploadedData.append(
      'dine_in',
      JSON.stringify(this.dine_in) || JSON.stringify(data.dine_in)
    );
    uploadedData.append(
      'pick_up',
      JSON.stringify(this.pick_up) || JSON.stringify(data.pick_up)
    );
    uploadedData.append(
      'delivery',
      JSON.stringify(this.delivery) || JSON.stringify(data.delivery)
    );
    uploadedData.append('facebook_link', data.facebook);
    uploadedData.append('instagram_link', data.instagram);
    uploadedData.append('survey_link', data.survey);
    uploadedData.append('webiste_link', data.website);

    this.menuPro.editRestaurant(uploadedData).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Restaurant updated successfully!', '', {
          timeOut: 3000,
        });
        window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000,
          });
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }

  addFile(files: FileList) {
    this.logo = files.item(0);
  }

  removeItemRestaurantLogo(id) {
    this.spinner.show();
    this.menuPro.removeItemRestaurantLogo(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
}
