import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { QrCodesProService } from '../qr-codes-pro.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-qr-code',
  templateUrl: './add-qr-code.component.html',
  styleUrls: ['./add-qr-code.component.scss'],
})
export class AddQrCodeComponent implements OnInit {
  id: any;
  rand: any = Math.random();
  qr: any;
  qrDetails: any;
  file: any;

  type: any;
  template_attributes: any[] = [];

  tname: any;
  // tlink: any;
  tcolor: any;
  tbackground_color: any;
  tsize: any;
  tstyle: any;
  teye_style: any;
  tgradient_start_color: any;
  tgradient_end_color: any;
  tgradient_type: any;
  tfacebook: any;
  tinstagram: any;
  twebsite: any;
  tsurvey: any;
  tfile_path: any;
  tlogo_path: any;
  teye_color_0_in: any = null;
  teye_color_0_out: any = null;
  teye_color_1_in: any = null;
  teye_color_1_out: any = null;
  teye_color_2_in: any = null;
  teye_color_2_out: any = null;

  lname: any;
  llink: any;
  lcolor: any;
  lbackground_color: any;
  lsize: any;
  lstyle: any;
  leye_style: any;
  lgradient_start_color: any;
  lgradient_end_color: any;
  lgradient_type: any;
  llogo_path: any;
  leye_color_0_in: any;
  leye_color_0_out: any;
  leye_color_1_in: any;
  leye_color_1_out: any;
  leye_color_2_in: any;
  leye_color_2_out: any;

  fname: any;
  fcolor: any;
  fbackground_color: any;
  fsize: any;
  fstyle: any;
  feye_style: any;
  fgradient_start_color: any;
  fgradient_end_color: any;
  fgradient_type: any;
  flogo_path: any;
  ffile_path: any;
  feye_color_0_in: any;
  feye_color_0_out: any;
  feye_color_1_in: any;
  feye_color_1_out: any;
  feye_color_2_in: any;
  feye_color_2_out: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private qrCodesPro: QrCodesProService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getQrCodeDetails(this.id);
      }
    });
  }

  ngOnInit(): void {}

  getQrCodeDetails(id) {
    this.rand = Math.random();
    this.spinner.show();
    this.qrCodesPro.getQrCodeDetails(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.qrDetails = res.qrcode;
        this.qr = this.qrDetails.qrcode_path;
        this.type = this.qrDetails.type;
        if (this.type == 'template') {
          this.tname = this.qrDetails.name;
          // this.tlink = this.qrDetails.link;
          this.tcolor = this.qrDetails.color;
          this.tbackground_color = this.qrDetails.background_color;
          this.tsize = this.qrDetails.size;
          this.tstyle = this.qrDetails.style;
          this.teye_style = this.qrDetails.eye_style;
          this.tgradient_start_color = this.qrDetails.gradient_start_color;
          this.tgradient_end_color = this.qrDetails.gradient_end_color;
          this.tgradient_type = this.qrDetails.gradient_type;
          this.tlogo_path = this.qrDetails.logo_path;
          if (this.qrDetails.eye_color_0) {
            let c = this.qrDetails.eye_color_0.split(',');
            this.teye_color_0_in = c[0];
            this.teye_color_0_out = c[1];
          }
          if (this.qrDetails.eye_color_1) {
            let c = this.qrDetails.eye_color_1.split(',');
            this.teye_color_1_in = c[0];
            this.teye_color_1_out = c[1];
          }
          if (this.qrDetails.eye_color_2) {
            let c = this.qrDetails.eye_color_2.split(',');
            this.teye_color_2_in = c[0];
            this.teye_color_2_out = c[1];
          }

          for (
            let index = 0;
            index < this.qrDetails.attributes.length;
            index++
          ) {
            if (this.qrDetails.attributes[index].key == 'facebook') {
              this.tfacebook = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'instagram') {
              this.tinstagram = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'survey') {
              this.tsurvey = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'website') {
              this.twebsite = this.qrDetails.attributes[index].value;
            } else if (this.qrDetails.attributes[index].key == 'file_path') {
              this.tfile_path = this.qrDetails.attributes[index].value;
              this.file = this.qrDetails.attributes[index];
            }
          }
        } else if (this.type == 'link') {
          this.lname = this.qrDetails.name;
          this.llink = this.qrDetails.link;
          this.lcolor = this.qrDetails.color;
          this.lbackground_color = this.qrDetails.background_color;
          this.lsize = this.qrDetails.size;
          this.lstyle = this.qrDetails.style;
          this.leye_style = this.qrDetails.eye_style;
          this.lgradient_start_color = this.qrDetails.gradient_start_color;
          this.lgradient_end_color = this.qrDetails.gradient_end_color;
          this.lgradient_type = this.qrDetails.gradient_type;
          this.llogo_path = this.qrDetails.logo_path;
          if (this.qrDetails.eye_color_0) {
            let c = this.qrDetails.eye_color_0.split(',');
            this.leye_color_0_in = c[0];
            this.leye_color_0_out = c[1];
          }
          if (this.qrDetails.eye_color_1) {
            let c = this.qrDetails.eye_color_1.split(',');
            this.leye_color_1_in = c[0];
            this.leye_color_1_out = c[1];
          }
          if (this.qrDetails.eye_color_2) {
            let c = this.qrDetails.eye_color_2.split(',');
            this.leye_color_2_in = c[0];
            this.leye_color_2_out = c[1];
          }
        } else if (this.type == 'file') {
          this.fname = this.qrDetails.name;
          this.fcolor = this.qrDetails.color;
          this.fbackground_color = this.qrDetails.background_color;
          this.fsize = this.qrDetails.size;
          this.fstyle = this.qrDetails.style;
          this.feye_style = this.qrDetails.eye_style;
          this.fgradient_start_color = this.qrDetails.gradient_start_color;
          this.fgradient_end_color = this.qrDetails.gradient_end_color;
          this.fgradient_type = this.qrDetails.gradient_type;
          this.flogo_path = this.qrDetails.logo_path;
          this.ffile_path = this.qrDetails.link;
          if (this.qrDetails.eye_color_0) {
            let c = this.qrDetails.eye_color_0.split(',');
            this.feye_color_0_in = c[0];
            this.feye_color_0_out = c[1];
          }
          if (this.qrDetails.eye_color_1) {
            let c = this.qrDetails.eye_color_1.split(',');
            this.feye_color_1_in = c[0];
            this.feye_color_1_out = c[1];
          }
          if (this.qrDetails.eye_color_2) {
            let c = this.qrDetails.eye_color_2.split(',');
            this.feye_color_2_in = c[0];
            this.feye_color_2_out = c[1];
          }
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  chooseType(type) {
    this.type = type;
  }

  addMenu(data, menu, logo) {
    this.template_attributes = [];
    if (this.id) {
      data.qrcode_id = this.id;
    }
    if (menu) {
      data.file = menu;
    } else {
      this.template_attributes.push(this.file);
    }
    if (logo) {
      data.logo = logo;
    }
    data.type = this.type;

    if (this.type == 'template') {
      if (data.facebook && data.facebook != '') {
        let d = { key: 'facebook', value: data.facebook };
        this.template_attributes.push(d);
      }
      if (data.instagram && data.instagram != '') {
        let d = { key: 'instagram', value: data.instagram };
        this.template_attributes.push(d);
      }
      if (data.survey && data.survey != '') {
        let d = { key: 'survey', value: data.survey };
        this.template_attributes.push(d);
      }
      if (data.website && data.website != '') {
        let d = { key: 'website', value: data.website };
        this.template_attributes.push(d);
      }
      delete data.facebook;
      delete data.instagram;
      delete data.survey;
      delete data.website;
      data.template_attributes = JSON.stringify(this.template_attributes);
    }

    if (data.eye_color_0_in && data.eye_color_0_out) {
      data.eye_color_0 = data.eye_color_0_in + ',' + data.eye_color_0_out;
    }
    if (data.eye_color_0_in && data.eye_color_0_out) {
      data.eye_color_1 = data.eye_color_1_in + ',' + data.eye_color_1_out;
    }
    if (data.eye_color_0_in && data.eye_color_0_out) {
      data.eye_color_2 = data.eye_color_2_in + ',' + data.eye_color_2_out;
    }

    delete data.eye_color_0_in;
    delete data.eye_color_0_out;
    delete data.eye_color_1_in;
    delete data.eye_color_1_out;
    delete data.eye_color_2_in;
    delete data.eye_color_2_out;

    Object.keys(data).forEach((key) => {
      // console.log(key, data[key]);
      if (!data[key]) {
        delete data[key];
      }
    });

    console.log(data);

    this.spinner.show();
    this.qrCodesPro.addQrCode(data).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('QR Was Added Successfully!', '', {
          timeOut: 3000,
        });
        if (this.id) {
          this.getQrCodeDetails(this.id);
        } else {
          this.router.navigate(['/base/addqr', res.user_qrcode.id]);
        }
        // window.history.back();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.error.errors.file) {
          this.toastr.error(err.error.errors.file[0], '', {
            timeOut: 3000,
          });
        } else if (err.error.errors.name) {
          this.toastr.error(err.error.errors.name[0], '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000,
          });
        }
      }
    );
  }
}
