<app-navbar [page]="'ORDERS'"></app-navbar>

<div class="orders">
    <div class="container">
        <h1>Order {{order?.table_number}}</h1>
        <div class="all_items">
            <div class="row">
                <div class="col-lg-7 col-md-8">
                    <div class="order_table">
                        <div class="table_head">
                            <div class="row">
                                <div class="col-5 cell borderleft">
                                    <h4>Item</h4>
                                </div>
                                <div class="col-3 cell text-center">
                                    <h4>Qty</h4>
                                </div>
                                <div class="col-4 cell">
                                    <h4>Price</h4>
                                </div>
                            </div>
                        </div>
                        <div class="table_body">
                            <div class="row" *ngFor="let item of order?.fc_order_items">
                                <div class="col-5 cell borderleft">
                                    <h4>{{item.fc_digital_menu_section_item.name}}</h4>
                                    <h6>Extras</h6>
                                    <p *ngFor="let mod of item.fc_order_item_modifiers">{{mod.name}}</p>
                                </div>

                                <div class="col-3 cell text-center">
                                    <h4>{{item.quantity}}</h4>
                                    <h6>Extras</h6>
                                    <p *ngFor="let mod of item.fc_order_item_modifiers">{{mod.quantity}}</p>
                                </div>

                                <div class="col-4 cell price">
                                    <h4>EGP {{item.total}}</h4>
                                    <h6>Extras</h6>
                                    <p *ngFor="let mod of item.fc_order_item_modifiers">EGP {{mod.total}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- <div class="text-right">
                        <button routerLink="/base/foodcourts/restaurants/order-items-edit/{{id}}">Edit Item</button>
                    </div> -->
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="total text-center">
                        <h6>Total Receipt</h6>
                        <h4>EGP <br> {{order?.total}}</h4>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>

    </div>
</div>