<app-navbar [page]="'ADD MENU'"></app-navbar>

<div class="menus">
    <div class="container">
        <div class="text-right">
            <span class="button save" (click)="addMenuData()">Save</span>
        </div>
        <p *ngIf="link" class="mb-0"><b>Link:</b></p>
        <p *ngIf="link"><b>{{link}}</b></p>

        <div class="file" *ngIf="m_id">
            <div class="f_in">+ Add Logo</div>
            <input type="file" #melog (change)="addMenuLogo(melog.files[0])">
        </div>
        <div class="view" *ngIf="logo_path">
            <a href="{{logo_path}}" target="_blank">View Current Logo</a>
        </div>

        <div class="section_head">
            <ng-container *ngFor="let s of sections; let sec_ind = index">
                <span class="button" [class.active]="section == 'section' + sec_ind"
                    (click)="chooseSection('section' + sec_ind)">Section {{sec_ind + 1}}</span>
            </ng-container>
            <span class="button add" (click)="addSection()">+ Add Section</span>
        </div>

        <div class="row">
            <!-- <div class="col-lg-3 col-md-2"></div> -->
            <div class="col-lg-6 col-md-8">
                <div class="menu">
                    <!-- <form #meForm="ngForm" (ngSubmit)="addMenuData(meForm.value)"> -->

                    <!-- Start Sections -->
                    <div class="sections">

                        <!-- <h5>Add Section</h5> -->
                        <ng-container *ngFor="let s of sections; let sec_ind = index">
                            <div class="sec" *ngIf="section == 'section' + sec_ind">
                                <h6>Section {{sec_ind+1}}</h6>
                                <div class="txt">
                                    <label>Name</label>
                                    <input type="text" placeholder="Name" class="txt_in" #sec_name
                                        (keyup)="addSectionData(sec_ind,'name',sec_name.value)" [(ngModel)]="s.name"
                                        [ngModelOptions]="{standalone: true}">
                                </div>
                                <div class="txt">
                                    <label>Description</label>
                                    <input type="text" placeholder="Description" class="txt_in" #sec_desc
                                        (keyup)="addSectionData(sec_ind,'desc',sec_desc.value)"
                                        [(ngModel)]="s.description" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div class="txt" *ngIf="s.id">
                                    <label>Section Image</label>
                                    <input type="file" class="txt_in" #sec_img
                                        (change)="addSectionImage(sec_img.files[0],s.id)">
                                    <a href="{{s.image_path}}" target="_blank" *ngIf="s.image_path">Current Image</a>
                                </div>

                                <!-- Start Sec Items -->

                                <div class="sec_items">
                                    <h5>Add Section Item</h5>
                                    <div class="item"
                                        *ngFor="let sItem of s.fc_digital_menu_section_items; let item_ind = index">
                                        <h6>Item {{item_ind+1}}</h6>
                                        <div class="txt">
                                            <label>Name</label>
                                            <input type="text" placeholder="Name" class="txt_in" #sec_it_name
                                                (keyup)="addSectionItemData(sec_ind,item_ind,'name',sec_it_name.value)"
                                                [(ngModel)]="sItem.name" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="txt">
                                            <label>Description</label>
                                            <input type="text" placeholder="Description" class="txt_in" #sec_it_desc
                                                (keyup)="addSectionItemData(sec_ind,item_ind,'desc',sec_it_desc.value)"
                                                [(ngModel)]="sItem.description" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="txt">
                                            <label>Price</label>
                                            <input type="number" placeholder="Price" class="txt_in" #sec_it_price
                                                (keyup)="addSectionItemData(sec_ind,item_ind,'price',sec_it_price.value)"
                                                [(ngModel)]="sItem.price" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="txt">
                                            <label>On Offer</label>
                                            <input type="checkbox" #sec_it_offer
                                                (keyup)="addSectionItemData(sec_ind,item_ind,'on_offer',sec_it_offer.value)"
                                                [(ngModel)]="sItem.on_offer" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="txt">
                                            <label>Old Price</label>
                                            <input type="number" placeholder="Old Price" class="txt_in" #sec_it_oprice
                                                (keyup)="addSectionItemData(sec_ind,item_ind,'old_price',sec_it_oprice.value)"
                                                [(ngModel)]="sItem.old_price" [ngModelOptions]="{standalone: true}"
                                                [disabled]="!sItem.on_offer">
                                        </div>
                                        <div class="txt">
                                            <label>New Price</label>
                                            <input type="number" placeholder="New Price" class="txt_in" #sec_it_nprice
                                                (keyup)="addSectionItemData(sec_ind,item_ind,'new_price',sec_it_nprice.value)"
                                                [(ngModel)]="sItem.new_price" [ngModelOptions]="{standalone: true}"
                                                [disabled]="!sItem.on_offer">
                                        </div>
                                        <div class="txt" *ngIf="sItem.id">
                                            <label>Item Image</label>
                                            <input type="file" class="txt_in" #sec_it_img
                                                (change)="addSectionItemImage(sec_it_img.files[0],sItem.id)">
                                            <a href="{{sItem.image_path}}" target="_blank" *ngIf="s.image_path">Current
                                                Image</a>
                                        </div>

                                        <!-- Start Mod -->
                                        <div class="mod_items">
                                            <h5>Add Section Item Modifier</h5>
                                            <div class="item"
                                                *ngFor="let mItem of sItem.fc_digital_menu_section_item_modifiers; let mod_item_ind = index">
                                                <h6>Modifier {{mod_item_ind+1}}</h6>
                                                <div class="txt">
                                                    <label>Name</label>
                                                    <input type="text" placeholder="Name" class="txt_in"
                                                        #mod_sec_it_name
                                                        (keyup)="addSectionItemModData(sec_ind,item_ind,mod_item_ind,'name',mod_sec_it_name.value)"
                                                        [(ngModel)]="mItem.name" [ngModelOptions]="{standalone: true}">
                                                </div>
                                                <div class="txt">
                                                    <label>Price</label>
                                                    <input type="number" placeholder="Price" class="txt_in"
                                                        #mod_sec_it_price
                                                        (keyup)="addSectionItemModData(sec_ind,item_ind,mod_item_ind,'price',mod_sec_it_price.value)"
                                                        [(ngModel)]="mItem.price" [ngModelOptions]="{standalone: true}">
                                                </div>

                                                <span class="button delete"
                                                    (click)="removeSectionItemMod(sec_ind,item_ind,mod_item_ind)">
                                                    Remove Ons</span>
                                            </div>

                                            <span class="button addm" (click)="addSectionItemMod(sec_ind,item_ind)">
                                                Add Ons
                                            </span>
                                        </div>
                                        <!-- End Mod -->

                                        <span class="button delete" (click)="removeSectionItem(sec_ind,item_ind)">Remove
                                            Item</span>
                                    </div>

                                    <span class="button addq" (click)="addSectionItem(sec_ind)">+ Item</span>
                                </div>

                                <span class="button delete" (click)="removeSection(sec_ind)">Remove Section</span>
                                <!-- End Sec Items -->
                            </div>
                        </ng-container>

                        <!-- End Section -->
                    </div>

                    <!-- </form> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-2"></div>
        </div>
    </div>
</div>