import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodesProService } from '../qr-codes-pro.service';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-qr-codes',
  templateUrl: './group-qr-codes.component.html',
  styleUrls: ['./group-qr-codes.component.scss']
})
export class GroupQrCodesComponent implements OnInit {

  qrs: any;
  id: any;
  next: any;
  search: any = '';

  rand: any = Math.random();

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _FileSaverService: FileSaverService,
    private _http: HttpClient,
    private toastr: ToastrService,
    private qrCodesPro: QrCodesProService) {
    this.rand = Math.random();
    this.route.params.subscribe(params => {
      this.rand = Math.random();
      this.id = params.id;
      if (this.id) {
        this.getQrCodes(this.id, '');
      }
    })
  }

  ngOnInit(): void {
  }

  getQrCodes(id, val) {
    this.search = val;
    this.spinner.show();
    this.qrCodesPro.getGroupQrs(id, val)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.qrs = res.qrcodes.data;
        this.next = res.qrcodes.next_page_url;
        this.rand = Math.random();
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onScroll(event) {
    if (this.next) {
      this.getNextPage(this.next).subscribe((response: any) => {
        this.qrs = this.qrs.concat(response.qrcodes.data);
        this.next = response.qrcodes.next_page_url;
      })
    }
  }

  getNextPage(url) {
    return this.qrCodesPro.getNextPage(url);
  }

  deleteQrCode(id) {
    this.spinner.show();
    this.qrCodesPro.deleteQrCode(id)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.getQrCodes(this.id, this.search);
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onDown(file) {
    // const fileName = `save.png`;
    this._http.get(`${file}`, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this._FileSaverService.save(res.body);
    });

  }

  addToCompare(id) {
    let qrs: any[] = [];
    let q = JSON.parse(localStorage.getItem('touch_compare'));
    if (q) {
      qrs = q;
    }
    for (let index = 0; index < qrs.length; index++) {
      if (qrs[index] == id) {
        this.toastr.success('Qr already in compare list!', '', {
          timeOut: 3000
        })
        return false;
      }
    }

    qrs.push(id);
    localStorage.setItem('touch_compare', JSON.stringify(qrs));
    this.toastr.success('Qr added to compare list!', '', {
      timeOut: 3000
    })
  }

}
