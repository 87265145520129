import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-order',
  templateUrl: './order-order.component.html',
  styleUrls: ['./order-order.component.scss']
})
export class OrderOrderComponent implements OnInit {

  items: any;
  total: any = 0;
  pass_code: any;
  table_number: any;
  status: any = 'check_table';
  order_id: any;

  id: any;
  timer: any;
  seconds: any = 0;
  minutes: any = 0;
  order_status: any;

  c_order: string = 'cart';
  cur_order: any;
  cur_total: any;
  order_code: any;

  constructor(
    private router: Router,
    private menuPro: MenuProService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
    this.order_id = localStorage.getItem('order_id');
    this.getItems();
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  ngOnInit(): void {
  }

  getMenu(id) {
    this.spinner.show();
    this.menuPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.cur_order = res.active_order[0].order_items;
        this.cur_total = res.active_order[0].total;
        this.order_status = res.active_order[0].status;
        this.timer = res.timer;
        if (this.timer) {
          let ti = this.timer.split(":");
          let min = parseInt(ti[0]) * 60 * 1000;
          let sec = parseInt(ti[1]) * 1000;
          let x = min + sec;
          setInterval(() => {
            if (x > 0) {
              x = x - 1000;
              this.seconds = Math.floor((x % (1000 * 60)) / 1000);
              this.minutes = Math.floor((x % (1000 * 60 * 60)) / (1000 * 60));
            }
          }, 1000);
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  checkTable(table_number) {
    this.table_number = table_number;
    let res_id = localStorage.getItem('res_id');
    this.spinner.show();
    this.menuPro.checkTable(res_id, table_number)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.Message, '', {
          timeOut: 3000
        })
        if (res.value) {
          this.status = 'empty_table';
        } else {
          this.status = 'check_pass';
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  checkPassCode(pass_code) {
    this.pass_code = pass_code;
    let res_id = localStorage.getItem('res_id');
    this.spinner.show();
    this.menuPro.checkPassCode(res_id, this.table_number, pass_code)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        if (!res.value) {
          this.toastr.error(res.Message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.success(res.Message, '', {
            timeOut: 3000
          })
        }

        if (res.value) {
          this.addOrder(pass_code);
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  submitOrder() {

  }

  addOrder(pass_code) {
    let d_id = localStorage.getItem('device_id');
    let res_id = localStorage.getItem('res_id');
    this.items.forEach(element => {
      element.digital_menu_section_item_modifiers = element.digital_menu_section_item.digital_menu_section_item_modifiers;
    });
    let data = {
      restaurant_id: res_id,
      table_number: this.table_number,
      user_hash: d_id,
      pass_code: pass_code,
      order_items: this.items
    }
    this.spinner.show();
    this.menuPro.addOrder(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.order_id = res.Order[0].id;
        localStorage.setItem('order_id', res.Order[0].id);
        localStorage.setItem('t_all_order', JSON.stringify(res.Order[0]));
        this.toastr.success('Order placed successfully!', '', {
          timeOut: 3000
        })
        this.router.navigate(['/order/confirmed']);
        res.Order[0].order_items.forEach(element => {
          element.digital_menu_section_item.digital_menu_section_item_modifiers = element.order_item_modifiers;
          element.digital_menu_section_item.digital_menu_section_item_modifiers.price = element.digital_menu_section_item_modifier.price;
        });
        localStorage.setItem('t_order', JSON.stringify(res.Order[0].order_items));
        this.updateOrder();

      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  editOrder() {
    // let d_id = localStorage.getItem('device_id');
    // let res_id = localStorage.getItem('res_id');
    // let or_id = localStorage.getItem('order_id');
    this.items.forEach(element => {
      element.digital_menu_section_item_modifiers = element.digital_menu_section_item.digital_menu_section_item_modifiers;
    });

    let data = JSON.parse(localStorage.getItem('t_all_order'));
    console.log(data);

    // let data = or_d;
    data.order_items = this.items;
    // let data = {
    //   id: or_id,
    //   restaurant_id: res_id,
    //   user_hash: d_id,
    //   order_items: this.items
    // }
    this.spinner.show();
    this.menuPro.editOrder(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        // localStorage.setItem('order_id', res.Order[0].id);
        // localStorage.setItem('t_order', res.Order[0].order_items);
        this.updateOrder();
        this.toastr.success('Order placed successfully!', '', {
          timeOut: 3000
        })
        this.router.navigate(['/order/confirmed'])
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  updateOrder() {
    let profile = document.querySelector('#foot_or_id') as HTMLElement
    profile.click();
  }

  getItems() {
    let its = JSON.parse(localStorage.getItem('t_order'));
    console.log(its);

    if (its) {
      this.items = its;
      this.getTotal(this.items);
    } else {
      this.items = [];
    }
    console.log(this.items);
  }

  getTotal(items) {
    this.total = 0;
    if (items) {
      items.forEach(element => {
        for (let index = 0; index < element.quantity; index++) {
          if (element.half_portion == '1') {
            if (element.on_offer) {
              this.total = this.total + element.digital_menu_section_item.half_portion_new_price;
            } else {
              this.total = this.total + element.digital_menu_section_item.half_portion_price;
            }
          } else if (element.on_offer) {
            this.total = this.total + element.digital_menu_section_item.new_price;
          } else {
            this.total = this.total + element.digital_menu_section_item.price;
          }
        }
        for (let mod_index = 0; mod_index < element.digital_menu_section_item.digital_menu_section_item_modifiers.length; mod_index++) {
          if (element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index].quantity) {
            for (let mod_q_ind = 0; mod_q_ind < element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index].quantity; mod_q_ind++) {
              this.total = this.total + element.digital_menu_section_item.digital_menu_section_item_modifiers[mod_index].price;
            }
          }
        }
      });
    }
  }

  hasMod(item_ind) {
    for (let index = 0; index < this.items[item_ind].digital_menu_section_item.digital_menu_section_item_modifiers.length; index++) {
      const element = this.items[item_ind].digital_menu_section_item.digital_menu_section_item_modifiers[index];
      if (element.quantity > 0) {
        return true;
      }
    }
    return false;
  }

  removeItem(item_ind) {
    this.items.splice(item_ind, 1);
    localStorage.setItem('t_order', JSON.stringify(this.items));
    this.getTotal(this.items);
    this.updateCart();
  }

  updateCart() {
    let profile = document.querySelector('#foot_or_btn') as HTMLElement
    profile.click();
  }

  showOrder(val) {
    this.c_order = val;
  }

  retrieveOrder(code) {
    this.spinner.show();
    this.menuPro.retrieveOrder(code)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.cur_order = res;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
