<div class="pay text-center">
    <div class="container">
        <h5>Choose payment method</h5>
        <div class="row">
            <div class="col-6">
                <button (click)="choosePayment('visa')" [class.active]="payment == 'visa'">Visa</button>
            </div>
            <div class="col-6">
                <button (click)="choosePayment('cash')" [class.active]="payment == 'cash'">Cash</button>
            </div>
        </div>
        <button class="s" (click)="submitOrder()">Submit</button>
    </div>
</div>