<div class="login">
    <div class="head">
        <h1>Sign Up</h1>
    </div>
    <div class="login_form">
        <input type="text" placeholder="Name">
        <input type="email" placeholder="Email">
        <input type="tel" placeholder="Number">
        <input type="password" placeholder="Password">
        <input type="password" placeholder="Confirm Password">
        <p>Forget Password?</p>
        <button routerLink="/order">Sign Up</button>
        <p>Have account? <span routerLink="/order-login">Login</span></p>
    </div>
</div>