import { Component, OnInit, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bill-sheet',
  templateUrl: './bill-sheet.component.html',
  styleUrls: ['./bill-sheet.component.scss'],
})
export class BillSheetComponent implements OnInit {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private bottomSheetRef: MatBottomSheetRef<BillSheetComponent>
  ) {}

  ngOnInit(): void {}

  closeSheet() {
    this.bottomSheetRef.dismiss();
  }

  addItem(ind) {
    this.data.items[ind].quantity = this.data.items[ind].quantity + 1;
    this.data.getTotal(this.data.items);
    localStorage.setItem('t_order', JSON.stringify(this.data.items));
  }

  removeItem(ind) {
    this.data.items[ind].quantity = this.data.items[ind].quantity - 1;
    if (this.data.items[ind].quantity == 0) {
      this.data.items.splice(ind, 1);
    }
    this.data.getTotal(this.data.items);
    localStorage.setItem('t_order', JSON.stringify(this.data.items));
  }
}
