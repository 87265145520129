import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';

@Component({
  selector: 'app-not-availaple-popup',
  templateUrl: './not-availaple-popup.component.html',
  styleUrls: ['./not-availaple-popup.component.scss']
})
export class NotAvailaplePopupComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<any>,
    private menuPro: MenuProService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  setItemNotAvailable() {
    this.spinner.show();
    this.menuPro.setItemNotAvailable(this.data.id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.Message, '', {
          timeOut: 3000
        })
        this.dialogRef.close('true');
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  closeDialog() {
    this.dialogRef.close('true');
  }

}
