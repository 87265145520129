import { AnimationStyleMetadata } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-add-food-court-restaurant',
  templateUrl: './add-food-court-restaurant.component.html',
  styleUrls: ['./add-food-court-restaurant.component.scss']
})
export class AddFoodCourtRestaurantComponent implements OnInit {

  res_id: any;
  name: any;
  admin_id: any;
  description: any;
  location: any;
  restaurant: any;
  api_key: any;
  merchant_id: any;
  integration_id: any;

  users: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.res_id = params.res_id;
      if (this.res_id) {
        this.getRestaurant(this.res_id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurant(id) {
    this.spinner.show();
    this.foodcourtPro.getFoodCourtRestaurant(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.restaurant = res;
        this.name = this.restaurant.name;
        this.users = this.restaurant.users;
        this.admin_id = this.restaurant.admin_id;
        this.description = this.restaurant.description;
        this.location = this.restaurant.location;
        this.api_key = this.restaurant.api_key;
        this.merchant_id = this.restaurant.merchant_id;
        this.integration_id = this.restaurant.integration_id;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  addRestaurantData(data) {
    data.fc_id = localStorage.getItem('fc_id');
    if (this.res_id) {
      data.fc_restaurant_id = this.res_id;
      this.editRestaurant(data);
    } else {
      this.addRestaurant(data);
    }
  }

  addRestaurant(data) {
    this.spinner.show();
    this.foodcourtPro.addFoodCourtRestaurant(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Restaurant added successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

  editRestaurant(data) {
    this.spinner.show();
    this.foodcourtPro.editFoodCourtRestaurant(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Restaurant updated successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

}
