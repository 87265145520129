<app-navbar [page]="'ORDERS'"></app-navbar>

<div class="orders">
    <div class="container">
        <h1>Order {{order?.table_number}}</h1>
        <div class="all_items">
            <div class="row">
                <div class="col-xl-7 col-md-8">
                    <div class="order_table">
                        <div class="table_head">
                            <div class="row">
                                <div class="col-4 cell borderleft">
                                    <h4>Item</h4>
                                </div>
                                <div class="col-4 cell text-center">
                                    <h4>Qty</h4>
                                </div>
                                <div class="col-4 cell">
                                    <h4>Price</h4>
                                </div>
                                <!-- <div class="col-3 cell">
                                    <h4></h4>
                                </div> -->
                            </div>
                        </div>
                        <div class="table_body">
                            <div class="row" *ngFor="let item of order?.order_items; let item_ind = index">
                                <div class="col-4 cell borderleft">
                                    <h4>{{item.digital_menu_section_item.name}} <span class="rm"
                                            (click)="removeOrderItem(item_ind)">X</span></h4>
                                    <h6>Extras</h6>
                                    <p *ngFor="let mod of item.order_item_modifiers">{{mod.name}}</p>
                                </div>

                                <div class="col-4 cell text-center">
                                    <h4>
                                        <span class="fa fa-minus inc" (click)="removeItem(item_ind)"></span>
                                        {{item.quantity || 0}}
                                        <span class="fa fa-plus inc" (click)="addItem(item_ind)"></span>
                                    </h4>
                                    <h6>Extras</h6>
                                    <p *ngFor="let mod of item.order_item_modifiers; let mod_ind = index">
                                        <span class="fa fa-minus inc" (click)="removeItemMod(item_ind, mod_ind)"></span>
                                        {{mod.quantity || 0}}
                                        <span class="fa fa-plus inc" (click)="addItemMod(item_ind, mod_ind)"></span>
                                    </p>
                                </div>

                                <div class="col-4 cell price">
                                    <h4>EGP {{item.total || item.digital_menu_section_item.price}}</h4>
                                    <h6>Extras</h6>
                                    <p *ngFor="let mod of item.order_item_modifiers">EGP {{mod.total || mod.price}}</p>
                                </div>

                                <!-- <div class="col-3 cell text-center">
                                    <button>Done</button>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="row">
                            <div class="col-6">
                                <button (click)="addNewItem()">+ Add New Item</button>
                            </div>
                            <div class="col-6 text-right">
                                <button (click)="editOrder()">Done</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="total text-center">
                        <h6>Total Receipt</h6>
                        <h4>EGP <br> {{order?.total}}</h4>
                    </div>
                </div>
                <div class="col-xl-2"></div>
            </div>
        </div>

    </div>
</div>