import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-check',
  templateUrl: './order-check.component.html',
  styleUrls: ['./order-check.component.scss']
})
export class OrderCheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
