<div class="home">
    <div class="head">
        <h1>Payment</h1>
    </div>
    <div class="body">
        <h2>Your Payment</h2>
        <div class="item">
            <h5 *ngIf="success == 'true'">Payment Success</h5>
            <h5 *ngIf="success == 'false'">Payment Failed</h5>
        </div>

    </div>
</div>