<div class="home">
  <div class="header">
    <h1>
      <span class="back" (click)="goBack()"
        ><i class="fas fa-chevron-left"></i
      ></span>
      Notifications
    </h1>
  </div>
  <div class="body">
    <ng-container *ngFor="let order of menu?.fc_active_order">
      <!-- <div class="item" *ngIf="order.status == 'Ready For Pickup'">
        <h4>Your Order is ready!</h4>
        <h6>Please pick up your order now</h6>
        <button>Check Order</button>
      </div> -->

      <div class="item">
        <h4>
          Your Order number {{ order.id }} is
          <strong>{{ order.status }}!</strong>
        </h4>
      </div>

      <!-- <div class="item" *ngIf="order.status == 'Queued'">
        <h6>Please pay to start preparing your order!</h6>
        <p> Due to some issues your order has been canceled, feel free to order again </p>
        <button routerLink="/foodcourt/payment/{{ order.id }}">Pay</button>
      </div> -->

      <!-- <div class="item" *ngIf="order.status == 'Pending Payment'">
        <h6>Please pay at the cashier to start preparing your order!</h6>
        <strong>Order#:{{ order.id }}</strong>
        <button routerLink="/foodcourt/payment/{{order.id}}">Pay</button>
      </div> -->

      <!-- <div class="item" *ngIf="order.status == 'Cooking'">
        <h6>Your order is being cooked now!</h6>
        <p>Order#: {{order.id}}</p>
        <button routerLink="/foodcourt/payment/{{order.id}}">Pay</button>
      </div> -->

      <!-- <div class="item">
                <h6>Your Order has been canceled</h6>
                <p> Due to some issues your order has been canceled, feel free to order again </p>
                <button>Order Now</button>
            </div> -->
    </ng-container>

    <div class="item">
      <h6>
        Thank you for Joining Touch-Less
        {{ menu?.fc_menu?.fc_restaurant?.name }}
      </h6>
      <button routerLink="/foodcourt/menu">Order Now</button>
    </div>
  </div>
</div>
