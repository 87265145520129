import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-waiter-tips-popup',
  templateUrl: './waiter-tips-popup.component.html',
  styleUrls: ['./waiter-tips-popup.component.scss']
})
export class WaiterTipsPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  addTips(data) {
    console.log(data);
    this.dialogRef.close(data);
  }

}
