import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { QrCodesProService } from '../qr-codes-pro.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  groups: any;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private qrcodesPro: QrCodesProService) { }

  ngOnInit(): void {
    this.getQrGroups();
  }

  getQrGroups() {
    this.spinner.show();
    this.qrcodesPro.getQrGroups()
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.groups = res.user_qrcode_groups;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  addGroup(data) {
    this.spinner.show();
    this.qrcodesPro.addQrGroup(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.message, '', {
          timeOut: 3000
        })
        this.getQrGroups();
      }, err => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000
        })
      })
  }

  deleteGroup(id) {
    this.spinner.show();
    this.qrcodesPro.deleteQrGroup(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.getQrGroups();
        this.toastr.success(res.message, '', {
          timeOut: 3000
        })
      }, err => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error('Something went wrong!', '', {
          timeOut: 3000
        })
      })
  }

}
