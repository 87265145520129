import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WaiterTipsPopupComponent } from '../waiter-tips-popup/waiter-tips-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  order_id: any;
  iframe: any;
  id: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private menuPro: MenuProService) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.openWaiterTips();
        // this.getIframe(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  openWaiterTips() {
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(WaiterTipsPopupComponent, {
        width: '400px',
        // height: '80vh',
        panelClass: 'sliP',
        data: null
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        this.getIframe(this.id, result.tips, result.name, result.stars);
      });
    } else {
      const dialogRef = this.dialog.open(WaiterTipsPopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: null
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        this.getIframe(this.id, result.tips, result.name, result.stars);
      });
    }

  }

  getIframe(order_id, tips, waiter, review) {
    this.spinner.show();
    this.menuPro.getIframe(order_id, tips, waiter, review)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        let ifr = this.sanitizer.bypassSecurityTrustResourceUrl(res.iframe);
        this.iframe = ifr;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
