import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  id: any;
  iframe: any;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.getIframe(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getIframe(order_id) {
    this.spinner.show();
    this.foodcourtPro.getIframe(order_id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        let ifr = this.sanitizer.bypassSecurityTrustResourceUrl(res.iframe);
        this.iframe = ifr;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
