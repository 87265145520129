<div class="login">
    <div class="container">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="log">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group">
                          <label for="email">Email</label>
                          <input type="email" class="form-control" id="email" #email formControlName="email" placeholder="email address">
                        </div>
                        <div class="form-group">
                          <label for="password">Password</label>
                          <input type="password" class="form-control" id="password" type="password" formControlName="password" placeholder="Password">
                        </div>
                        <div class="btnSubmit">
                        <button type="submit" class="btn btn-primary"  [disabled]="!loginForm.valid">Submit</button>
                        </div>
                      </form>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</div>