import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.scss']
})
export class OrdersHistoryComponent implements OnInit {

  id: any;
  orders: any;
  next: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private menuPro: MenuProService) {
    this.route.params.subscribe(params => {
      this.id = params.r_id;
      if (this.id) {
        this.getRestaurantOrdersHistory(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurantOrdersHistory(id) {
    this.spinner.show();
    this.menuPro.getRestaurantOrdersHistory(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.orders = res.data;
        this.next = res.next_page_url;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onScroll(event) {
    if (this.next) {
      this.getNextPage(this.next).subscribe((response: any) => {
        this.orders = this.orders.concat(response.data);
        this.next = response.next_page_url;
      })
    }
  }

  getNextPage(url) {
    return this.menuPro.getNextPage(url);
  }

  validate(orders) {
    if (orders[0]['table_number']) {
      return true;
    } else {
      return false;
    }
  }

}
