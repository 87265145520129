import { Component, OnInit } from '@angular/core';
import { AuthService } from '../dashboard/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [ Validators.required]
      ]
    });
  }

  login() {
    this.spinner.show();
    this.auth.login(this.loginForm.value)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('User Logged Successfully!', '', {
          timeOut: 3000
        })
        this.router.navigate(['/base']);
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.errors.email) {
          this.toastr.error(err.error.errors.email[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.password) {
          this.toastr.error(err.error.errors.password[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

}
