<app-navbar [page]="'ORDERS'"></app-navbar>

<div class="orders" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="true"
    (scrolled)="onScroll($event)">
    <div class="container" *ngIf="orders?.length">
        <h1>All Orders</h1>
        <div class="order_table text-center">
            <div class="table_head">
                <div class="row">
                    <div class="col cell borderleft" *ngIf="validate(orders)">
                        <h4>Table</h4>
                    </div>
                    <div class="col cell">
                        <h4>Price</h4>
                    </div>
                    <div class="col cell">
                        <h4>Date</h4>
                    </div>
                </div>
            </div>
            <div class="table_body">
                <div class="row" *ngFor="let order of orders; let or_ind = index">
                    <div class="col cell borderleft" *ngIf="order.table_number">
                        <p>{{order.table_number}}</p>
                    </div>
                    <div class="col cell">
                        <p>{{order.total}} EGP</p>
                    </div>
                    <div class="col cell">
                        <p>{{order.updated_at}} EGP</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="!orders?.length">
        <p>no orders yet</p>
      </div>
</div>
