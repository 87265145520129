import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';

@Component({
  selector: 'app-table-orders',
  templateUrl: './table-orders.component.html',
  styleUrls: ['./table-orders.component.scss']
})
export class TableOrdersComponent implements OnInit {
  restaurant_id;
  table_id;
  orders;
  total;

  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService, private toastr: ToastrService,
              private menuPro: MenuProService) {this.route.queryParams.subscribe(params => {
    this.restaurant_id = params['rest_id'];
    this.table_id = params['table_id'];
  });
}

  ngOnInit(): void {
    this.getTableOrders(this.restaurant_id, this.table_id);
  }

  getTableOrders(res_id, table_id) {
    this.spinner.show();
    this.menuPro.getTableOrders(res_id, table_id)
      .subscribe((res: any) => {
        this.orders = res.Orders;
        this.total = res.Total;
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.spinner.hide();
      });
  }

  callWaiterOff(id,index) {
      this.spinner.show();
      this.menuPro.callWaiterOff(id)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.orders[index].call_waiter = 0;
        }, err => {
          console.log(err);
          this.spinner.hide();
        });
  }

  requestReceiptOff(id, index) {
      this.spinner.show();
      this.menuPro.requestReceiptOff(id)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.orders[index].ask_for_check = 0;
        }, err => {
          console.log(err);
          this.spinner.hide();
        });
  }

  changeOrderStatus(status, id) {
    this.spinner.show();
    this.menuPro.editOrderStatus(id, status)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (status == 'Paid') {
          this.getTableOrders(this.restaurant_id, this.table_id);
        } else {
          this.toastr.success(`Status Changed to ${status}`, '', {
            timeOut: 3000
          });
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      });
  }

  cancelOrder(id) {
    this.spinner.show();
    this.menuPro.deleteOrder(id)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.toastr.success('Order Canceled!', '', {
          timeOut: 3000
        })
        this.getTableOrders(this.restaurant_id, this.table_id);
      }, err => {
        console.log(err);
        this.spinner.hide();
      });
  }


}
