<div class="total_body" id="totalB">
  <div class="close-btn" (click)="closeSheet()">
    <i class="fas fa-times"></i>
  </div>

  <div class="item" *ngFor="let item of data.items; let i = index">
    <div
      class="pic"
      [style.background-image]="
        'url(' + item.digital_menu_section_item.image_path + ')'
      "
    ></div>
    <div class="con">
      <h6>{{ item.digital_menu_section_item.name }}</h6>
      <h6>({{ item.quantity }})</h6>
    </div>
    <div class="count">
      <span class="fa fa-minus inc" (click)="removeItem(i)"></span>
      <span class="fa fa-plus inc" (click)="addItem(i)"></span>
    </div>
    <h5>
      {{ item.digital_menu_section_item.price | currency: "EGP":"symbol" }}
    </h5>
  </div>

  <div class="t">
    <h4>
      <span>Bill Total</span>
      {{ data.total | currency: "EGP":"symbol" }}
    </h4>
  </div>
</div>
