<app-navbar [page]="'QR CODES'"></app-navbar>
<div class="qrcodes" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="true"
    (scrolled)="onScroll($event)">
    <div class="container-fluid">
        <div class="buttons text-right">
            <button class="btn btn-primary" routerLink="/base/qrcodes/groups">Groups</button>
        </div>


        <div class="items">

            <div class="in">
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <div class="s">
                            <form #dForm="ngForm" (ngSubmit)="getQrCodes(id,search)">
                                <button (click)="getQrCodes(id,search)"><img src="assets/imgs/search.svg"
                                        width="20"></button>
                                <input type="text" placeholder="Search in Qr Codes" [(ngModel)]="search" name="search">
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="qrs?.length != 0">
                <div class="item" *ngFor="let qr of qrs">
                    <div class="row">

                        <!-- <div class="item text-center">

                            <h4 routerLink="/base/addqr/{{qr.id}}">{{qr.name}}</h4>
                            <button class="btn btn-danger" (click)="deleteQrCode(qr.id)">Delete</button>
                        </div> -->

                        <div class="col-md-2">
                            <img *ngIf="qr" src="{{qr.qrcode_path}}?rand={{rand}}" width="100%" height="auto">
                        </div>
                        <div class="col-md-4">
                            <div class="desc">
                                <h6>{{qr.name}}</h6>
                                <div class="links">
                                    <div class="l">
                                        <p>Link</p>
                                        <p>Org</p>
                                        <p>Created</p>
                                        <p>Type</p>
                                    </div>
                                    <div class="r">
                                        <a>https://touch-less.co/qrcode/{{qr.id}}</a>
                                        <a>{{qr.name}}</a>
                                        <a>{{qr.created_at}}</a>
                                        <a>{{qr.type}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="scans text-center">
                                <p>{{qr.number_of_scans}}</p>
                                <span>Scans</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="buttons">
                                <button (click)="onDown(qr.qrcode_path)">Download</button>
                                <button routerLink="/base/addqr/{{qr.id}}">EDIT</button>
                                <button routerLink="/base/analytics/{{qr.id}}">Statistics</button>
                                <button (click)="addToCompare(qr.id)">Add to compare</button>
                                <button class="d" (click)="deleteQrCode(qr.id)">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>

        <div class="noitems text-center" *ngIf="qrs?.length == 0">
            <p>There are no QR codes!</p>
        </div>
    </div>
</div>