<app-navbar [page]="'EDIT QR CODE'"></app-navbar>
<div class="page">
  <div class="container">
    <div class="head">
      <div class="item">
        <p><span [class.active]="select == 'type'">1</span> Select Type</p>
      </div>
      <div class="item">
        <p><span [class.active]="select == 'setup'">2</span> Setup</p>
      </div>
      <div class="item">
        <p><span [class.active]="select == 'customize'">3</span> Customize</p>
      </div>
      <div class="item">
        <p><span [class.active]="select == 'finish'">4</span> Finish</p>
      </div>
    </div>

    <div class="body">
      <!-- Start Types -->
      <div class="types" *ngIf="select == 'type'">
        <h6 class="c">Choose Type</h6>
        <div class="row">
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('cumulative')">
            <div class="item" (click)="chooseType('cumulative')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup17.svg" width="30" />
              </div>
              <div class="content">
                <h6>Cumulative Template</h6>
                <p>Cumulative Template QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('template')">
            <div class="item" (click)="chooseType('template')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup7.svg" width="30" />
              </div>
              <div class="content">
                <h6>Template</h6>
                <p>Template QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('file')">
            <div class="item" (click)="chooseType('file')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup11.svg" width="30" />
              </div>
              <div class="content">
                <h6>File</h6>
                <p>File QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('link')">
            <div class="item" (click)="chooseType('link')">
              <div class="pic">
                <img src="assets/imgs/link.svg" width="30" />
              </div>
              <div class="content">
                <h6>Link</h6>
                <p>Link QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('email')">
            <div class="item" (click)="chooseType('email')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup9.svg" width="30" />
              </div>
              <div class="content">
                <h6>Email</h6>
                <p>Email QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('wifi')">
            <div class="item" (click)="chooseType('wifi')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup15.svg" width="30" />
              </div>
              <div class="content">
                <h6>Wifi</h6>
                <p>Wifi QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('phone_number')">
            <div class="item" (click)="chooseType('phone_number')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup13.svg" width="30" />
              </div>
              <div class="content">
                <h6>Phone Number</h6>
                <p>Phone Number QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('sms')">
            <div class="item" (click)="chooseType('sms')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup18.svg" width="30" />
              </div>
              <div class="content">
                <h6>SMS</h6>
                <p>SMS QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2">
            <div class="item" (click)="chooseType('whatsapp')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup18.svg" width="30" />
              </div>
              <div class="content">
                <h6>WhatsApp</h6>
                <p>Link to an existing URL on the web</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('location')">
            <div class="item" (click)="chooseType('geo')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup10.svg" width="30" />
              </div>
              <div class="content">
                <h6>Location</h6>
                <p>Location QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('vcard')">
            <div class="item" (click)="chooseType('vcard')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup18.svg" width="30" />
              </div>
              <div class="content">
                <h6>Business Card</h6>
                <p>Business Card QR Code</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-2" *ngIf="checkTypes('survey')">
            <div class="item" (click)="chooseType('survey')">
              <div class="pic">
                <img src="assets/imgs/MaskGroup17.svg" width="30" />
              </div>
              <div class="content">
                <h6>Survey</h6>
                <p>Survey QR Code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Types -->

      <!-- Start Setup -->
      <div class="setup" *ngIf="select == 'setup'">
        <h6>Template Info</h6>
        <div class="row">
          <div class="col-md-5">
            <!-- Start Template Form -->
            <div *ngIf="type == 'template'">
              <form
                #temForm="ngForm"
                (ngSubmit)="
                  addInfo(null, temForm.value, tmen.files[0], tlog.files[0])
                "
              >
                <div class="file">
                  <div class="f_in">+ Add File</div>
                  <input type="file" #tmen (change)="getFileName($event)" />
                </div>
                <!-- <div class="view" *ngIf="file_name">
                                    <span>{{file_name}}</span>
                                </div> -->
                <div class="view" *ngIf="file_path">
                  <a href="{{ file_path }}" target="_blank"
                    >View Current File</a
                  >
                  <span class="button delete" (click)="removeFile()"  style="display:inline-block; margin-left: 20px;">
                    Remove File</span>
                </div>
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #tlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                {{qrcode_user_id}}

                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_user_id"
                    name="qrcode_user_id"
                    class="txt_in"
                  >
                    <option value="">No User</option>
                    <option value="{{ u.id }}" *ngFor="let u of users">
                      {{ u.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Facebook Link"
                    class="txt_in"
                    [(ngModel)]="facebook"
                    name="facebook"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Instagram Link"
                    class="txt_in"
                    [(ngModel)]="instagram"
                    name="instagram"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Survey Link"
                    class="txt_in"
                    [(ngModel)]="survey"
                    name="survey"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Website Link"
                    class="txt_in"
                    [(ngModel)]="website"
                    name="website"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Services Link"
                    class="txt_in"
                    [(ngModel)]="service"
                    name="service"
                  />
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End Template Form -->
            <!-- Start Link Form -->
            <div *ngIf="type == 'link'">
              <form
                #liForm="ngForm"
                (ngSubmit)="addInfo(null, liForm.value, null, llog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #llog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Link"
                    class="txt_in"
                    [(ngModel)]="link"
                    name="link"
                  />
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End Link Form -->
            <!-- Start File Form -->
            <div *ngIf="type == 'file'">
              <form
                #fiForm="ngForm"
                (ngSubmit)="
                  addInfo(null, fiForm.value, fmen.files[0], flog.files[0])
                "
              >
                <div class="file">
                  <div class="f_in">+ Add File</div>
                  <input type="file" #fmen />
                </div>
                <div class="view" *ngIf="file_path">
                  <a href="{{ file_path }}" target="_blank"
                    >View Current File</a
                  >
                  <span class="button delete" (click)="removeFile()"  style="display:inline-block; margin-left: 20px;">
                    Remove File</span>
                </div>
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #flog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End File Form -->
            <!-- Start Email Form -->
            <div *ngIf="type == 'email'">
              <form
                #emForm="ngForm"
                (ngSubmit)="addInfo(null, emForm.value, null, emlog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #emlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="email"
                    placeholder="Email"
                    class="txt_in"
                    [(ngModel)]="email"
                    name="email"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Subject"
                    class="txt_in"
                    [(ngModel)]="subject"
                    name="subject"
                  />
                </div>
                <div class="txt">
                  <textarea
                    rows="5"
                    placeholder="Body"
                    [(ngModel)]="body"
                    name="body"
                  ></textarea>
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End Email Form -->
            <!-- Start Wifi Form -->
            <div *ngIf="type == 'wifi'">
              <form
                #temForm="ngForm"
                (ngSubmit)="addInfo(null, temForm.value, null, wilog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #wilog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <select
                    class="txt_in"
                    [(ngModel)]="encryption"
                    name="encryption"
                  >
                    <option value="">Encryption</option>
                    <option value="null">null</option>
                    <option value="WPA">WPA</option>
                    <option value="WEP">WEP</option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="SSID"
                    class="txt_in"
                    [(ngModel)]="ssid"
                    name="ssid"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Password"
                    class="txt_in"
                    [(ngModel)]="password"
                    name="password"
                  />
                </div>
                <div class="txt">
                  <label><b>Hidden</b></label
                  ><br />
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="hidden"
                    name="hidden"
                    id="hidden-true"
                    value="true"
                  />
                  <label class="form-check-label" for="hidden-true">
                    True
                  </label>
                </div>
                <div class="txt">
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="hidden"
                    name="hidden"
                    id="hidden-false"
                    value="false"
                  />
                  <label class="form-check-label" for="hidden-false">
                    Flase
                  </label>
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End Wifi Form -->
            <!-- Start phone_number Form -->
            <div *ngIf="type == 'phone_number'">
              <form
                #emForm="ngForm"
                (ngSubmit)="addInfo(null, emForm.value, null, emlog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #emlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="tel"
                    placeholder="Phone Numner"
                    class="txt_in"
                    [(ngModel)]="phone_number"
                    name="phone_number"
                  />
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End phone_number Form -->
            <!-- Start SMS Form -->
            <div *ngIf="type == 'sms'">
              <form
                #emForm="ngForm"
                (ngSubmit)="addInfo(null, emForm.value, null, emlog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #emlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    class="txt_in"
                    [(ngModel)]="phone_number"
                    name="phone_number"
                  />
                </div>
                <div class="txt">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    [(ngModel)]="message"
                    name="message"
                  ></textarea>
                </div>
                <button>Next</button>
              </form>
            </div>
            <div *ngIf="type == 'whatsapp'">
              <form
                #emForm="ngForm"
                (ngSubmit)="addInfo(null, emForm.value, null, emlog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #emlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    class="txt_in"
                    [(ngModel)]="phone_number"
                    name="phone_number"
                  />
                </div>
                <div class="txt">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    [(ngModel)]="message"
                    name="message"
                  ></textarea>
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End SMS Form -->
            <!-- Start GEO Form -->
            <div *ngIf="type == 'geo'">
              <form
                #emForm="ngForm"
                (ngSubmit)="addInfo(null, emForm.value, null, emlog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #emlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    ngx-google-places-autocomplete
                    class="txt_in"
                    [options]="options"
                    #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)"
                  />
                </div>
                <div class="txt">
                  <agm-map
                    [latitude]="latitude"
                    [longitude]="longitude"
                    (mapClick)="onChoseLocation($event)"
                  >
                    <agm-marker
                      [latitude]="latitude"
                      [longitude]="longitude"
                      *ngIf="locationChosen"
                    >
                    </agm-marker>
                  </agm-map>
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End GEO Form -->
            <!-- Start VCard Form -->
            <div *ngIf="type == 'vcard'">
              <form
                #emForm="ngForm"
                (ngSubmit)="addInfo(null, emForm.value, null, emlog.files[0])"
              >
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #emlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="First Name"
                    class="txt_in"
                    [(ngModel)]="first_name"
                    name="first_name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="txt_in"
                    [(ngModel)]="last_name"
                    name="last_name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Address"
                    class="txt_in"
                    [(ngModel)]="address"
                    name="address"
                  />
                </div>
                <div class="txt">
                  <input
                    type="email"
                    placeholder="Email"
                    class="txt_in"
                    [(ngModel)]="email"
                    name="email"
                  />
                </div>
                <div class="txt">
                  <input
                    type="tel"
                    placeholder="Mobile"
                    class="txt_in"
                    [(ngModel)]="mobile"
                    name="mobile"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Title"
                    class="txt_in"
                    [(ngModel)]="title"
                    name="title"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Organization"
                    class="txt_in"
                    [(ngModel)]="organization"
                    name="organization"
                  />
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End VCard Form -->
            <!-- Start Survey Form -->
            <div *ngIf="type == 'survey'">
              <!-- <form #suForm="ngForm" (ngSubmit)="addInfo(null,suForm.value, null, emlog.files[0])"> -->
              <div class="file">
                <div class="f_in">+ Add Logo</div>
                <input type="file" #sulog />
              </div>
              <div class="view" *ngIf="logo_path">
                <a href="{{ logo_path }}" target="_blank">View Current Logo</a>
                <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                  Remove Logo</span>
              </div>
              <div class="file">
                <div class="f_in">+ Add Background</div>
                <input type="file" #suBac />
              </div>
              <div class="view" *ngIf="background_path">
                <a href="{{ background_path }}" target="_blank"
                  >View Current Background</a
                >
              </div>
              <div class="txt">
                <input
                  type="text"
                  placeholder="QR Name"
                  class="txt_in"
                  #qr_name
                  [(ngModel)]="name"
                />
              </div>
              <div class="txt">
                <input
                  type="text"
                  placeholder="Facebook Link"
                  class="txt_in"
                  [(ngModel)]="facebook"
                  name="facebook"
                />
              </div>
              <div class="txt">
                <input
                  type="text"
                  placeholder="Instagram Link"
                  class="txt_in"
                  [(ngModel)]="instagram"
                  name="instagram"
                />
              </div>
              <div class="txt">
                <input
                  type="text"
                  placeholder="Website Link"
                  class="txt_in"
                  [(ngModel)]="website"
                  name="website"
                />
              </div>
              <div class="txt">
                <input
                  type="text"
                  placeholder="Services Link"
                  class="txt_in"
                  [(ngModel)]="service"
                  name="service"
                />
              </div>
              <div class="questions">
                <div
                  class="ques_item"
                  *ngFor="let ques of questions; let i = index"
                >
                  <h6>Question {{ i + 1 }}</h6>
                  <div class="txt">
                    <label>Question title</label>
                    <input
                      type="text"
                      placeholder="Question title"
                      class="txt_in"
                      #ques_tit
                      (keyup)="addQuestionsToArray(i, ques_tit.value, 'tit')"
                      [(ngModel)]="ques.title"
                    />
                  </div>
                  <div class="txt">
                    <label>Question Type</label>
                    <select
                      class="txt_in"
                      (change)="addQuestionsToArray(i, ques_type.value, 'type')"
                      #ques_type
                      [(ngModel)]="ques.type"
                    >
                      <option value="" disabled selected>Question Type</option>
                      <option value="text">Text</option>
                      <option value="radio">Radio</option>
                      <option value="stars">Stars</option>
                      <option value="checkboxes">Checkboxes</option>
                    </select>
                  </div>
                  <div class="txt" *ngIf="ques.type == 'radio' || ques.type == 'checkboxes'">
                    <div class="txt ans">
                      <label>Question Answer</label>
                      <input
                        type="text"
                        placeholder="Question Answer"
                        class="txt_in"
                        #ques_ans
                      />
                      <button
                        class="addq"
                        (click)="addQuestionAnswer(i, ques_ans.value)"
                      >
                        Add Answer
                      </button>
                    </div>
                    <div class="txt" *ngIf="ques.answers?.length != 0">
                      <ul>
                        <label>Question Answers</label>
                        <li
                          *ngFor="let answer of ques.answers; let ind = index"
                        >
                          {{ answer }}
                          <span (click)="deleteQuestionAnswer(i, ind)">X</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button class="delete" (click)="deleteQuestion(i)">
                    Delete Question
                  </button>
                </div>
                <button class="add" (click)="addQuestion()">
                  Add Question
                </button>
              </div>

              <button
                class="mt-5"
                (click)="
                  addInfo(
                    suBac.files[0],
                    {
                      name: name,
                      facebook: facebook,
                      instagram: instagram,
                      website: website,
                      service: service
                    },
                    null,
                    sulog.files[0]
                  )
                "
              >
                Next
              </button>
              <!-- </form> -->
            </div>
            <!-- End Survey Form -->
            <!-- Start cumulative Form -->
            <div *ngIf="type == 'cumulative'">
              <form
                #cumForm="ngForm"
                (ngSubmit)="
                  addInfo(
                    cumback.files[0],
                    cumForm.value,
                    cummen.files[0],
                    cumlog.files[0]
                  )
                "
              >
                <div class="file">
                  <div class="f_in">+ Add File</div>
                  <input type="file" #cummen />
                </div>
                <div class="view" *ngIf="file_path">
                  <a href="{{ file_path }}" target="_blank"
                    >View Current File</a
                    >
                    <span class="button delete" (click)="removeFile()"  style="display:inline-block; margin-left: 20px;">
                      Remove File</span>
                </div>
                <div class="file">
                  <div class="f_in">+ Add Logo</div>
                  <input type="file" #cumlog />
                </div>
                <div class="view" *ngIf="logo_path">
                  <a href="{{ logo_path }}" target="_blank"
                    >View Current Logo</a
                  >
                  <span class="button delete" (click)="removeLogo()"  style="display:inline-block; margin-left: 20px;">
                    Remove Logo</span>
                </div>
                <div class="file">
                  <div class="f_in">+ Add Background</div>
                  <input type="file" #cumback />
                </div>
                <div class="view" *ngIf="background_path">
                  <a href="{{ background_path }}" target="_blank"
                    >View Current Background</a
                  >
                  <span class="button delete" (click)="removeBackground()"  style="display:inline-block; margin-left: 20px;">
                    Remove Background</span>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_group_id"
                    name="qrcode_group_id"
                    class="txt_in"
                  >
                    <option value="">No Group</option>
                    <option value="{{ g.id }}" *ngFor="let g of groups">
                      {{ g.name }}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="qrcode_user_id"
                    name="qrcode_user_id"
                    class="txt_in"
                  >
                    <option value="">No User</option>
                    <option value="{{ u.id }}" *ngFor="let u of users">
                      {{ u.name }} &nbsp; - &nbsp; {{u.id}}
                    </option>
                  </select>
                </div>
                <div class="txt">
                  <select
                    [(ngModel)]="design_type"
                    name="design_type"
                    class="txt_in"
                  >
                    <option value="default">Default Design</option>
                    <option value="watertemplate" [selected]="design_type === 'watertemplate'? true: null">
                      Water Design</option>
                  </select>
                </div>
                <div class="txt" *ngIf="design_type === 'default'">
                  <select
                    [(ngModel)]="text_color"
                    name="text_color"
                    class="txt_in"
                  >
                    <option value="black">Black</option>
                    <option value="white" [selected]="text_color === 'white'? true: null">White</option>
                  </select>
                  </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="QR Name"
                    class="txt_in"
                    [(ngModel)]="name"
                    name="name"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Facebook Link"
                    class="txt_in"
                    [(ngModel)]="facebook"
                    name="facebook"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Instagram Link"
                    class="txt_in"
                    [(ngModel)]="instagram"
                    name="instagram"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Survey Link"
                    class="txt_in"
                    [(ngModel)]="survey"
                    name="survey"
                  />
                </div>
                <div class="txt">
                  <input
                    type="text"
                    placeholder="Website Link"
                    class="txt_in"
                    [(ngModel)]="website"
                    name="website"
                  />
                </div>

                <div class="items">
                  <h6>QrCodes</h6>
                  <div>
                    <input
                      type="text"
                      placeholder="Qrcodes Title"
                      class="txt_in"
                      [(ngModel)]="qrcodes_title"
                      name="qrcodes_title"
                    />
                  </div>
                  <div
                    class="item"
                    *ngFor="let q of cumulativeQrs; let c_index = index"
                  >
                    <label>QR {{ c_index + 1 }}</label>
                    <div class="txt">
                      <input
                        type="text"
                        placeholder="Name"
                        class="txt_in"
                        #c_name
                        [(ngModel)]="q.key"
                        [ngModelOptions]="{ standalone: true }"
                        (keyup)="addCumQrData(c_name.value, 'name', c_index)"
                      />
                    </div>
                    <div class="txt">
                      <input
                        type="text"
                        placeholder="QR Link"
                        class="txt_in"
                        #c_link
                        [(ngModel)]="q.value"
                        [ngModelOptions]="{ standalone: true }"
                        (keyup)="addCumQrData(c_link.value, 'val', c_index)"
                      />
                    </div>
                    <div class="txt">
                      <input
                        type="file"
                        class="txt_in"
                        #c_pic
                        (change)="addCumQrData(c_pic.files[0], 'pic', c_index)"
                      />
                      <a
                        href="{{ extra_images[c_index]}}"
                        *ngIf="extra_images[c_index]"
                        target="_blank"
                        >Current Image</a
                      >
                    </div>
                    <span class="button edit" (click)="editTemplateQr(c_link.value)"
                      >Edit QR Code</span
                    >
                    <span class="button delete" (click)="deleteCumQr(c_index)"
                      >Delete QR Code</span
                    >
                  </div>
                  <span class="button add" (click)="addCumQr()"
                    >Add QR Code</span
                  >
                </div>
                <button>Next</button>
              </form>
            </div>
            <!-- End cumulative Form -->
          </div>
        </div>
      </div>
      <!-- End Setup -->

      <!-- Start Customize -->
      <div class="custom" *ngIf="select == 'customize'">
        <form #stFrom="ngForm" (ngSubmit)="confirm(stFrom.value, 'finish')">
          <h6>QR Colors</h6>
          <div class="row">
            <div class="col-lg-8 col-md-6">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Color</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="color"
                        name="color"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div class="left" [style.background-color]="color"></div>
                      <div class="right">
                        <span>{{ color }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Background Color</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="background_color"
                        name="background_color"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="background_color"
                      ></div>
                      <div class="right">
                        <span>{{ background_color }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Eye Color One Inner</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="eye_color_0_in"
                        name="eye_color_0_in"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="eye_color_0_in"
                      ></div>
                      <div class="right">
                        <span>{{ eye_color_0_in }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Eye Color One Outer</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="eye_color_0_out"
                        name="eye_color_0_out"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="eye_color_0_out"
                      ></div>
                      <div class="right">
                        <span>{{ eye_color_0_out }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Eye Color Two Inner</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="eye_color_1_in"
                        name="eye_color_1_in"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="eye_color_1_in"
                      ></div>
                      <div class="right">
                        <span>{{ eye_color_1_in }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Eye Color Two Outer</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="eye_color_1_out"
                        name="eye_color_1_out"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="eye_color_1_out"
                      ></div>
                      <div class="right">
                        <span>{{ eye_color_1_out }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Eye Color Three Inner</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="eye_color_2_in"
                        name="eye_color_2_in"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="eye_color_2_in"
                      ></div>
                      <div class="right">
                        <span>{{ eye_color_2_in }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="color">
                    <label>Eye Color Three Outer</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="eye_color_2_out"
                        name="eye_color_2_out"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="eye_color_2_out"
                      ></div>
                      <div class="right">
                        <span>{{ eye_color_2_out }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="color">
                    <label>Gradient Start Color</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="gradient_start_color"
                        name="gradient_start_color"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="gradient_start_color"
                      ></div>
                      <div class="right">
                        <span>{{ gradient_start_color }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="color">
                    <label>Gradient End Color</label>
                    <div class="box">
                      <input
                        type="color"
                        [(ngModel)]="gradient_end_color"
                        name="gradient_end_color"
                        (change)="confirm(stFrom.value, null)"
                      />
                      <div
                        class="left"
                        [style.background-color]="gradient_end_color"
                      ></div>
                      <div class="right">
                        <span>{{ gradient_end_color }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <label>Gradient Type</label>
                  <div class="form-group">
                    <select
                      class="form-control"
                      [(ngModel)]="gradient_type"
                      name="gradient_type"
                      (change)="confirm(stFrom.value, null)"
                    >
                      <option value="vertical">Vertical</option>
                      <option value="horizontal">Horizontal</option>
                      <option value="diagonal">Diagonal</option>
                      <option value="inverse_diagonal">Inverse Diagonal</option>
                      <option value="radial">Radial</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="qr text-center">
                <h5>Your QR Code</h5>
                <div class="qr_item">
                  <img src="{{ qr }}?rand={{ rand }}" width="100%" />
                </div>
                <br>
                <button type="button" (click)="editQr()">Back to setup</button>
              </div>
            </div>
          </div>

          <hr />
          <h6>Body Shape</h6>
          <div class="toggle">
            <div class="shape">
              <input
                type="radio"
                name="style"
                value="square"
                id="m"
                [(ngModel)]="style"
                (change)="confirm(stFrom.value, null)"
              />
              <label for="m"
                ><img src="assets/imgs/square.png" width="40"
              /></label>
            </div>
            <div class="shape">
              <input
                type="radio"
                name="style"
                value="dot"
                id="m2"
                [(ngModel)]="style"
                (change)="confirm(stFrom.value, null)"
              />
              <label for="m2"
                ><img src="assets/imgs/dot.png" width="40"
              /></label>
            </div>
            <div class="shape">
              <input
                type="radio"
                name="style"
                value="round"
                id="m3"
                [(ngModel)]="style"
                (change)="confirm(stFrom.value, null)"
              />
              <label for="m3"
                ><img src="assets/imgs/round.png" width="40"
              /></label>
            </div>
          </div>
          <hr />
          <h6>Eye Ball Shape</h6>
          <div class="toggle">
            <div class="shape">
              <input
                type="radio"
                name="eye_style"
                value="circle"
                id="s1"
                [(ngModel)]="eye_style"
                (change)="confirm(stFrom.value, null)"
              />
              <label for="s1"
                ><img src="assets/imgs/ball14.png" width="40"
              /></label>
            </div>
            <div class="shape">
              <input
                type="radio"
                name="eye_style"
                value="square"
                id="s2"
                [(ngModel)]="eye_style"
                (change)="confirm(stFrom.value, null)"
              />
              <label for="s2"
                ><img src="assets/imgs/ball0.png" width="40"
              /></label>
            </div>
          </div>
          <hr />
          <h6>
            Size
            <ng-container *ngIf="size">{{ size }} X {{ size }}</ng-container>
          </h6>
          <div class="size">
            <div class="slidecontainer">
              <input
                type="range"
                min="100"
                max="2000"
                [(ngModel)]="size"
                name="size"
                class="slider"
                id="myRange"
              />
            </div>
          </div>
          <button>Confirm</button>
        </form>
      </div>
      <!-- End Customize -->

      <!-- Start Finish -->
      <div class="finish" *ngIf="select == 'finish'">
        <div class="row">
          <div class="col-lg-8 col-md-7">
            <div class="share">
              <h5>Your QR Code is ready!</h5>
              <!-- <h6>Share</h6>
                            <p>Share this QR code on social media</p>
                            <div class="buttons">
                                <div class="sharethis-inline-share-buttons">
                                    <button><img src="assets/imgs/icons/facebook.svg"> Share</button>
                                </div>
                                <button><img src="assets/imgs/icons/twitter.svg"> Tweet</button>
                                <button><img src="assets/imgs/icons/linkedin.svg"> Share</button>
                            </div> -->
              <h6>Name</h6>
              <p>Give it a name for easy identification</p>
              <div class="txt">
                <input
                  type="text"
                  placeholder="Name"
                  class="txt_in"
                  [(ngModel)]="fileName"
                />
              </div>
              <h6>Download</h6>
              <p>Download digital files to embed in marketing materials</p>
              <button class="d" (click)="onDown(fileName)">Download</button>
            </div>
          </div>
          <div class="col-lg-4 col-md-5">
            <div class="qr text-center">
              <h5>Your QR Code</h5>
              <div class="qr_item">
                <img src="{{ qr }}?rand={{ rand }}" width="100%" />
                <button routerLink="/base/addqr">Create another</button>
                <button (click)="editQr()">Edit QR</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Finish-->
    </div>
  </div>
</div>
