<app-navbar [page]="'ANALYTICS'"></app-navbar>
<div class="scans">
    <div class="container-fluid">

        <div class="in">
            <div class="row">
                <div class="col-md-6">
                    <h4>Overall Scan Statistic</h4>
                </div>
                <div class="col-md-6">
                    <!-- <h6>Number Of Days</h6>
                    <div class="s">
                        <form #dForm="ngForm" (ngSubmit)="getScans(id,days)">
                            <button (click)="getScans(id,days)"><img src="assets/imgs/search.svg" width="20"></button>
                            <input type="text" placeholder="Number of Days" [(ngModel)]="days" name="days">
                        </form>
                    </div> -->
                    <div class="row">
                        <div class="col-6">
                            <label>Start Date</label>
                            <input type="date" class="form-control" [(ngModel)]="from_date"
                                (change)="getScans(id,from_date, to_date)">
                        </div>
                        <div class="col-6">
                            <label>End Date</label>
                            <input type="date" class="form-control" [(ngModel)]="to_date"
                                (change)="getScans(id,from_date, to_date)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="all_scans">
            <div class="in">
                <div class="row text-center">
                    <div class="col-md-6">
                        <div class="ch">
                            <h6>Total scans from {{from_date}} to {{to_date}}: {{scans?.count}}</h6>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ch">
                            <h6>Total unique scans from {{from_date}} to {{to_date}}: {{scans?.count_unique}}</h6>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="s">
                            <button (click)="getScans(id,days)"><img src="assets/imgs/search.svg" width="20"></button>
                            <input type="text" placeholder="Number of Days" [(ngModel)]="days">
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="ch">
                <h5>Total Scans</h5>
                <canvas *ngIf="scan_counts" baseChart width="800" height="300" [datasets]="barChartData"
                    [labels]="barChartLabels" [options]="barChartOptions" [colors]="barChartColors"
                    [legend]="barChartLegend" [chartType]="barChartType" [plugins]="barChartPlugins">
                </canvas>
                <div class="text-right">
                    <span (click)="onDown(scans?.excel_path)"><img src="assets/imgs/download.svg" width="10">XLS</span>
                </div>
            </div>
        </div>

        <div class="unique_scans">
            <!-- <div class="in">
                <div class="row">
                    <div class="col-md-6">
                        <h6>Overall Unique Scans Number: {{scans?.count_unique}}</h6>
                    </div>
                    <div class="col-md-6">
                        <div class="s">
                            <button (click)="getScans(id,days)"><img src="assets/imgs/search.svg" width="20"></button>
                            <input type="text" placeholder="Number of Days" [(ngModel)]="days">
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="ch">
                <h5>Unique scans</h5>
                <canvas *ngIf="scan_counts" baseChart width="800" height="300" [datasets]="ubarChartData"
                    [labels]="ubarChartLabels" [options]="ubarChartOptions" [colors]="ubarChartColors"
                    [legend]="ubarChartLegend" [chartType]="ubarChartType" [plugins]="ubarChartPlugins">
                </canvas>
                <div class="text-right">
                    <span (click)="onDown(scans?.excel_path)"><img src="assets/imgs/download.svg" width="10">XLS</span>
                </div>
            </div>
        </div>

        <div class="devices">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="ch">
                        <h5>Devices</h5>
                        <canvas *ngIf="pieChartData?.length != 0" height="300" [labels]="pieChartLabels"
                            [legend]="pieChartLegend" baseChart [data]="pieChartData"
                            [chartType]="pieChartType"></canvas>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ch">
                        <h5>Customer Types</h5>
                        <canvas *ngIf="tpieChartData?.length != 0" height="300" [labels]="tpieChartLabels"
                            [legend]="pieChartLegend" baseChart [data]="tpieChartData"
                            [chartType]="pieChartType"></canvas>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>