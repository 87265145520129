import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuProService } from '../menu-pro.service';
import { DashboardItemsPopupComponent } from '../dashboard-items-popup/dashboard-items-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { NotAvailaplePopupComponent } from '../not-availaple-popup/not-availaple-popup.component';

@Component({
  selector: 'app-order-items-edit',
  templateUrl: './order-items-edit.component.html',
  styleUrls: ['./order-items-edit.component.scss']
})
export class OrderItemsEditComponent implements OnInit {

  id: any;
  order: any;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private menuPro: MenuProService) {
    this.route.params.subscribe(params => {
      this.id = params.or_id;
      if (this.id) {
        this.getRestaurantOrderItems(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurantOrderItems(id) {
    this.spinner.show();
    this.menuPro.getRestaurantOrderItems(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.order = res.Order[0];
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  editOrder() {
    console.log(this.order);

    this.spinner.show();
    this.menuPro.editOrder(this.order)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Order Updated Successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  addItem(item_ind) {
    this.order.order_items[item_ind].quantity = this.order.order_items[item_ind].quantity + 1;
  }

  removeOrderItem(item_ind) {
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(NotAvailaplePopupComponent, {
        width: '500px',
        // height: '80vh',
        panelClass: 'sliP',
        data: { id: this.order.order_items[item_ind].digital_menu_section_item.id }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.order.order_items.splice(item_ind, 1);
        }
      });
    } else {
      const dialogRef = this.dialog.open(NotAvailaplePopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: { id: this.order.order_items[item_ind].digital_menu_section_item.id }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.order.order_items.splice(item_ind, 1);
        }
      });
    }
  }

  removeItem(item_ind) {
    if (this.order.order_items[item_ind].quantity > 0) {
      this.order.order_items[item_ind].quantity = this.order.order_items[item_ind].quantity - 1;
    }
  }

  addItemMod(item_ind, mod_ind) {
    this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity = this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity + 1;
  }

  removeItemMod(item_ind, mod_ind) {
    if (this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity > 0) {
      this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity = this.order.order_items[item_ind].order_item_modifiers[mod_ind].quantity - 1;
    }
  }

  addNewItem() {
    let w = window.innerWidth;
    if (w > 600) {
      const dialogRef = this.dialog.open(DashboardItemsPopupComponent, {
        width: '500px',
        // height: '80vh',
        panelClass: 'sliP',
        data: { id: this.order.restaurant_id }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.order.order_items.push(result);
        }
      });
    } else {
      const dialogRef = this.dialog.open(DashboardItemsPopupComponent, {
        width: '90%',
        // height: '40vh',
        panelClass: 'sliP',
        data: { id: this.order.restaurant_id }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.order.order_items.push(result);
        }
      });
    }
  }

}
