import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-add-fc-menu',
  templateUrl: './add-fc-menu.component.html',
  styleUrls: ['./add-fc-menu.component.scss']
})
export class AddFcMenuComponent implements OnInit {

  r_id: any;
  m_id: any;
  menu: any;

  background_image_path: any;
  logo_path: any;
  primary_color: any;
  secondary_color: any;
  sections: any[] = [];

  restaurant: any;
  link: any;

  section: string = 'section0';

  constructor(
    private route: ActivatedRoute,
    private foodcourtPro: FoodCourtProService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
    this.route.params.subscribe(params => {
      this.r_id = params.r_id;
      if (this.r_id) {
        this.getRestaurant(this.r_id);
      }
    })
  }

  ngOnInit(): void {
  }

  getRestaurant(id) {
    this.spinner.show();
    this.foodcourtPro.getFoodCourtRestaurant(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.restaurant = res;
        if (this.restaurant.fc_digital_menu) {
          this.menu = this.restaurant.fc_digital_menu;
          this.link = this.restaurant.fc_digital_menu.digital_menu_link
          this.m_id = this.menu.id;
          this.background_image_path = this.menu.background_image_path;
          this.logo_path = this.menu.logo_path;
          this.primary_color = this.menu.primary_color;
          this.secondary_color = this.menu.secondary_color;
          this.sections = this.menu.fc_digital_menu_sections;
        }

      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  addMenuData() {
    let data = {
      fc_restaurant_id: this.r_id,
      fc_digital_menu_sections: this.sections,
      fc_digitalmenu_id: null
    }

    if (this.m_id) {
      data.fc_digitalmenu_id = this.m_id;
      this.editMenu(data);
    } else {
      delete data.fc_digitalmenu_id
      this.addMenu(data);
    }
  }

  addMenu(data) {
    this.spinner.show();
    this.foodcourtPro.addDigitalMenu(data)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Menu added successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  editMenu(data) {
    this.spinner.show();
    this.foodcourtPro.editDigitalMenu(data)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Menu updated successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  addSection() {
    let s = { name: null, description: null, fc_digital_menu_section_items: [] };
    this.sections.push(s);
  }

  removeSection(index) {
    this.sections.splice(index, 1);
  }

  addSectionData(index, attr, val) {
    if (attr == 'name') {
      this.sections[index].name = val;
    } else if (attr == 'desc') {
      this.sections[index].description = val;
    }
  }

  addSectionItem(index) {
    let s_item = { name: null, description: null, price: null, on_offer: null, new_price: null, old_price: null, fc_digital_menu_section_item_modifiers: [] };
    this.sections[index].fc_digital_menu_section_items.push(s_item);
  }

  removeSectionItem(index, item_ind) {
    this.sections[index].fc_digital_menu_section_items.splice(item_ind, 1);
  }

  addSectionItemData(index, item_ind, attr, val) {
    if (attr == 'name') {
      this.sections[index].fc_digital_menu_section_items[item_ind].name = val;
    } else if (attr == 'desc') {
      this.sections[index].fc_digital_menu_section_items[item_ind].description = val;
    } else if (attr == 'price') {
      this.sections[index].fc_digital_menu_section_items[item_ind].price = val;
    } else if (attr == 'new_price') {
      this.sections[index].fc_digital_menu_section_items[item_ind].new_price = val;
    } else if (attr == 'old_price') {
      this.sections[index].fc_digital_menu_section_items[item_ind].old_price = val;
    } else if (attr == 'on_offer') {
      this.sections[index].fc_digital_menu_section_items[item_ind].on_offer = val;
    }
  }

  /* Mod */

  addSectionItemMod(index, item_ind) {
    let s_item = { name: null, price: null };
    this.sections[index].fc_digital_menu_section_items[item_ind].fc_digital_menu_section_item_modifiers.push(s_item);
  }

  removeSectionItemMod(index, item_ind, item_mod_ind) {
    this.sections[index].fc_digital_menu_section_items[item_ind].fc_digital_menu_section_item_modifiers.splice(item_mod_ind, 1);
  }

  addSectionItemModData(index, item_ind, item_mod_ind, attr, val) {
    console.log(this.sections);
    if (attr == 'name') {
      this.sections[index].fc_digital_menu_section_items[item_ind].fc_digital_menu_section_item_modifiers[item_mod_ind].name = val;
    } else if (attr == 'price') {
      this.sections[index].fc_digital_menu_section_items[item_ind].fc_digital_menu_section_item_modifiers[item_mod_ind].price = val;
    }
  }

  // Add Images

  addSectionImage(pic, id) {
    let data = { image: pic, fc_section_id: id };
    this.spinner.show();
    this.foodcourtPro.addDigitalMenuSectionImage(data)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Image added successfully!', '', {
          timeOut: 3000
        })
        // window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  addSectionItemImage(pic, id) {
    let data = { image: pic, fc_item_id: id };
    this.spinner.show();
    this.foodcourtPro.addDigitalMenuItemImage(data)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Image added successfully!', '', {
          timeOut: 3000
        })
        // window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  addMenuLogo(pic) {
    let data = { logo: pic, fc_digitalmenu_id: this.m_id };
    this.spinner.show();
    this.foodcourtPro.addDigitalMenuLogo(data)
      .subscribe(res => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('Logo added successfully!', '', {
          timeOut: 3000
        })
        // window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

  chooseSection(val) {
    this.section = val;
  }

}
