import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FoodCourtProService } from 'src/app/dashboard/foodcourt/food-court-pro.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order-menu',
  templateUrl: './order-menu.component.html',
  styleUrls: ['./order-menu.component.scss'],
})
export class OrderMenuComponent implements OnInit {
  id: any;
  menu: any;
  offers: any;
  section: any = 'section0';

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private foodcourtPro: FoodCourtProService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.id = localStorage.getItem('t_menu_id');
    if (this.id) {
      this.getMenu(this.id);
    } else {
      this.router.navigate(['/']);
    }
  }

  goBack() {
    this.location.back();
  }

  getMenu(id) {
    this.spinner.show();
    this.foodcourtPro.getUserDigitalMenu(id).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide();
        this.menu = res.fc_menu.fc_digital_menu_sections;
        this.offers = res.fc_menu.fc_offers;
        if (this.offers && this.offers.length != 0) {
          this.section = 'sectionO';
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  showSection(val) {
    this.section = val;
  }
}
