<form [formGroup]="surveyGroup" class="formContainer" (ngSubmit)="createSurvey()">

    <div class="form-group">
        <label for="title">Name</label>
        <input type="text" class="form-control" id="name" #name formControlName="name" placeholder="name">
    </div>


    <button type="button" class="btn btn-primary addQuestionBtn" (click)="addQuestion()">Add Question</button>

    <div formArrayName="questions" *ngFor="let question of surveyGroup.get('questions')?.controls; let x = index">

        <div class="form-group" [formGroupName]="x">

            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="title" #title formControlName="title" placeholder="title">
            </div>

            <select class="custom-select" formControlName="type">
                <option [ngValue]="null" disabled>Plese select type</option>
                <option *ngFor = "let val of selectValues" [ngValue]="val.value">{{val.display}}</option>
            </select>

            <div formArrayName="answers" *ngFor="let answer of question.get('answers')?.controls; let i=index" class="addAnswers">
                <div [formGroupName]="i" *ngIf="question.get('type').value == 'radio' || question.get('type').value == 'select'">

                    <div class="form-group">
                        <label for="answer">Answer</label>
                        <input type="text" class="form-control" id="answer" #title formControlName="answer"
                            placeholder="answer">
                    </div>

                    <button type="button" class="btn btn-danger addAnswers" (click)="deleteAnswer(x,i)">Delete Answer</button>

                </div>

            </div>

            <div class ="btnContainer">

            <button type="button" class="btn btn-primary addAnswers" (click)="addAnswer(x)"
            *ngIf="question.get('type').value == 'radio' || question.get('type').value == 'select'">Add Answer</button>

            <button type="button" class="btn btn-danger deletQuestion" (click)="deleteQuestion(x)">Delete Question</button>

            </div>

        </div>

    </div>

    <div class="btnSubmit">
        <button type="submit" class="btn btn-primary" [disabled]="!surveyGroup.valid">Submit</button>
    </div>
</form>

<div class="alert alert-danger alertContainer" role="alert"  *ngIf="error">
    <p>Please complete all fields<p>
  </div>