<nav class="navigation-bar">
  <ul class="navigation-bar__list">
    <li class="navigation-bar__item">
      <a
        class="navigation-bar__link"
        routerLink="/foodcourt/home/{{ menuId }}"
        routerLinkActive="navigation-bar__link--active"
      >
        <i class="dining-Home"></i>
        <span>Home</span>
      </a>
    </li>
    <li class="navigation-bar__item item">
      <a
        class="navigation-bar__link"
        routerLink="/foodcourt/notifications"
        routerLinkActive="navigation-bar__link--active"
      >
        <i class="dining-Notification"></i>
        <span>Notifications</span>
      </a>
    </li>
  </ul>
  <div class="navigation-bar__logo">
    <a
      class="navigation-bar__logo__link"
      routerLink="/foodcourt/home/{{ menuId }}"
    >
      <img *ngIf="menu?.logo_path" src="{{ menu?.logo_path }}" alt="logo" />
    </a>
  </div>
  <ul class="navigation-bar__list">
    <li class="navigation-bar__item item">
      <a
        class="navigation-bar__link"
        routerLink="/foodcourt/menu"
        routerLinkActive="navigation-bar__link--active"
      >
        <i class="dining-Menu"></i>
        <span>Menu</span>
      </a>
    </li>
    <li class="navigation-bar__item">
      <a
        class="navigation-bar__link"
        routerLink="/foodcourt/order"
        routerLinkActive="navigation-bar__link--active"
      >
        <i class="dining-Order"></i>
        <span>Order</span>
      </a>
    </li>
  </ul>
</nav>
