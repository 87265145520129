import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuProService } from 'src/app/dashboard/menu-pro.service';

@Component({
  selector: 'app-order-notifications',
  templateUrl: './order-notifications.component.html',
  styleUrls: ['./order-notifications.component.scss']
})
export class OrderNotificationsComponent implements OnInit {

  id: any;
  order_status: any;

  constructor(
    private spinner: NgxSpinnerService,
    private menuPro: MenuProService) {
    this.id = localStorage.getItem('t_menu_id');
    this.getMenu(this.id);
  }

  ngOnInit(): void {
  }

  getMenu(id) {
    this.spinner.show();
    this.menuPro.getUserDigitalMenu(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        if (res.active_order) {
          this.order_status = res.active_order[0].status;
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

}
