import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-add-food-court',
  templateUrl: './add-food-court.component.html',
  styleUrls: ['./add-food-court.component.scss']
})
export class AddFoodCourtComponent implements OnInit {

  id: any;
  name: any;
  location: any;
  foodcourt: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.getFoodCourt(this.id);
      }
    })
  }

  ngOnInit(): void {
  }

  getFoodCourt(id) {
    this.spinner.show();
    this.foodcourtPro.getFoodCourt(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.foodcourt = res;
        this.name = this.foodcourt.name;
        this.location = this.foodcourt.location;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  addFoodCourtData(data) {
    if (this.id) {
      data.fc_id = this.id;
      this.editFoodCourt(data);
    } else {
      this.addFoodCourt(data);
    }
  }

  addFoodCourt(data) {
    this.spinner.show();
    this.foodcourtPro.addFoodCourt(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('FoodCourt added successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

  editFoodCourt(data) {
    this.spinner.show();
    this.foodcourtPro.editFoodCourt(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success('FoodCourt updated successfully!', '', {
          timeOut: 3000
        })
        window.history.back();
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

}
