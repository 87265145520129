import { Component, OnInit } from '@angular/core';
import { AuthService } from '../dashboard/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  verified: boolean = false;
  token: any;
  id: any;
  passToken: any;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) {
    this.route.params.subscribe(params => {
      this.token = params.token;
      if (this.token) {
        this.verify({ token: this.token });
      }
    })
  }

  ngOnInit(): void {
  }

  verify(data) {
    this.spinner.show();
    this.auth.verify(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.id = res.user_id;
        this.passToken = res.token;
        this.verified = true;
        this.toastr.success(res.message, '', {
          timeOut: 3000
        })
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.token) {
          this.toastr.error(err.error.token, '', {
            timeOut: 3000
          })
        } else if (err.error.errors.Message) {
          this.toastr.error(err.error.errors.Message[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.password) {
          this.toastr.error(err.error.errors.password[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.token) {
          this.toastr.error(err.error.errors.token[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

        this.router.navigate(['/']);

      })
  }

  changePassword(data) {
    data.token = this.passToken;
    data.user_id = this.id;
    this.spinner.show();
    this.auth.changePassword(data)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.toastr.success(res.Message, '', {
          timeOut: 3000
        })
        this.router.navigate(['/login']);
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else if (err.error.token) {
          this.toastr.error(err.error.token, '', {
            timeOut: 3000
          })
        } else if (err.error.errors.Message) {
          this.toastr.error(err.error.errors.Message[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.password) {
          this.toastr.error(err.error.errors.password[0], '', {
            timeOut: 3000
          })
        } else if (err.error.errors.token) {
          this.toastr.error(err.error.errors.token[0], '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }

      })
  }

}
