import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FoodCourtProService } from '../food-court-pro.service';

@Component({
  selector: 'app-fc-orders',
  templateUrl: './fc-orders.component.html',
  styleUrls: ['./fc-orders.component.scss']
})
export class FcOrdersComponent implements OnInit, OnDestroy {

  id: any;
  orders: any;
  next: any;
  s_int: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private foodcourtPro: FoodCourtProService) {
    this.route.params.subscribe(params => {
      this.id = params.r_id;
      if (this.id) {
        this.getRestaurantOrders(this.id);          
        this.s_int = setInterval(() => {
          this.getRestaurantOrders(this.id);          
        }, 30000);
      }
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    clearInterval(this.s_int);
  }

  getRestaurantOrders(id) {
    this.spinner.show();
    this.foodcourtPro.getRestaurantOrders(id)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.orders = res.data;
        this.next = res.next_page_url;
      }, err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  onScroll(event) {
    if (this.next) {
      this.getNextPage(this.next).subscribe((response: any) => {
        this.orders = this.orders.concat(response.data);
        this.next = response.next_page_url;
      })
    }
  }

  getNextPage(url) {
    return this.foodcourtPro.getNextPage(url);
  }

  // cancelOrder(id) {
  //   this.spinner.show();
  //   this.foodcourtPro.deleteOrder(id)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.spinner.hide();
  //       this.toastr.success('Order Canceled!', '', {
  //         timeOut: 3000
  //       })
  //       this.getRestaurantOrders(this.id);
  //     }, err => {
  //       console.log(err);
  //       this.spinner.hide();
  //       if (err.error.Message) {
  //         this.toastr.error(err.error.Message, '', {
  //           timeOut: 3000
  //         })
  //       } else if (err.error.message) {
  //         this.toastr.error(err.error.message, '', {
  //           timeOut: 3000
  //         })
  //       } else {
  //         this.toastr.error('Something went wrong!', '', {
  //           timeOut: 3000
  //         })
  //       }
  //     })
  // }

  // callWaiterOff(or_ind, id) {
  //   if (this.orders[or_ind].call_waiter) {
  //     this.spinner.show();
  //     this.foodcourtPro.callWaiterOff(id)
  //       .subscribe((res: any) => {
  //         console.log(res);
  //         this.spinner.hide();
  //         this.orders[or_ind].call_waiter = 0;
  //       }, err => {
  //         console.log(err);
  //         this.spinner.hide();
  //         if (err.error.Message) {
  //           this.toastr.error(err.error.Message, '', {
  //             timeOut: 3000
  //           })
  //         } else if (err.error.message) {
  //           this.toastr.error(err.error.message, '', {
  //             timeOut: 3000
  //           })
  //         } else {
  //           this.toastr.error('Something went wrong!', '', {
  //             timeOut: 3000
  //           })
  //         }
  //       })
  //   }
  // }

  // requestReceiptOff(or_ind, id) {
  //   if (this.orders[or_ind].ask_for_check) {
  //     this.spinner.show();
  //     this.foodcourtPro.requestReceiptOff(id)
  //       .subscribe((res: any) => {
  //         console.log(res);
  //         this.spinner.hide();
  //         this.orders[or_ind].ask_for_check = 0;
  //       }, err => {
  //         console.log(err);
  //         this.spinner.hide();
  //         if (err.error.Message) {
  //           this.toastr.error(err.error.Message, '', {
  //             timeOut: 3000
  //           })
  //         } else if (err.error.message) {
  //           this.toastr.error(err.error.message, '', {
  //             timeOut: 3000
  //           })
  //         } else {
  //           this.toastr.error('Something went wrong!', '', {
  //             timeOut: 3000
  //           })
  //         }
  //       })
  //   }
  // }

  changeOrderStatus(cu_status, id, or_ind) {
    let status;
    if (cu_status == 'Pending Payment') {
      status = 'Paid';
    } else if (cu_status == 'Paid') {
      status = 'Cooking';
    } else if (cu_status == 'Cooking') {
      status = 'Ready For Pickup';
    } else if (cu_status == 'Ready For Pickup') {
      status = 'Picked Up';
    }
    this.spinner.show();
    this.foodcourtPro.editOrderStatus(id, status)
      .subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        this.orders[or_ind].status = status;
        if (status == 'Picked Up') {
          this.getRestaurantOrders(this.id);
        } else {
          this.toastr.success(`Status Changed to ${status}`, '', {
            timeOut: 3000
          })
        }
      }, err => {
        console.log(err);
        this.spinner.hide();
        if (err.error.Message) {
          this.toastr.error(err.error.Message, '', {
            timeOut: 3000
          })
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '', {
            timeOut: 3000
          })
        } else {
          this.toastr.error('Something went wrong!', '', {
            timeOut: 3000
          })
        }
      })
  }

}
